/* You can add global styles to this file, and also import other style files
body{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
    margin: 0
} */

@import '@angular/material/prebuilt-themes/indigo-pink.css';

@media screen and (min-width:991px){

  body {min-height: 750px;}
}
_:-ms-fullscreen, :root .mat-menu-panel { position:absolute; }
.lod {
  background-color: #d0d3d5;
  opacity: 0.4;
  width: 200px;
  height: 15px;
}
.app-menu {
  z-index: 1000;
  right: 0px !important;
}

@media screen and (max-width: 990px) {

  body {min-height: 50px;}
}
.mat-menu-panel {
  /* position:absolute; */
  z-index: 1000;
  right:25px;
}
body {
  margin: 0;
  background: #5b6770;
}
div.mat-menu-content{
  padding-top:0;
  padding-bottom:0;
}
img {overflow:hidden}
body,html{
    height:100%;
    width:100%;
    margin:0;
    padding:0;
    left:0;
    top:0;
    font-size:100%
}
.center,.container{
    margin-left:auto;
    margin-right:auto
}
*    {
    font-family: 'Open Sans', sans-serif ;
     ;
    line-height:1.4;

}
.red-snackbar {
  background: #ee4c38;
}

.save:not(:disabled) * {
  color:#fff;
}
h1{
    font-size:2.5rem
}
h2{
    font-size:2rem
}
h3{
    font-size:1.375rem
}
h4{
    font-size:1.125rem
}
h5{
    font-size:1rem
}
h6{
    font-size:.875rem
}
p{
    font-size:1.125rem;
    font-weight:200;
    line-height:1.8
}
.font-light{
    font-weight:300
}
.font-regular{
    font-weight:400
}
.font-heavy{
    font-weight:700
}
.left{
    text-align:left
}
.right{
    text-align:right
}
.center{
    text-align:center
}
.justify{
    text-align:justify
}
.container{
    width:90%
}
.row{
    position:relative;
    width:100%
}
.row [class^=col]{
    float:left;
    margin:12px;
    min-height:.125rem
}
.col-1,.col-10,.col-11,.col-12,.col-2,.col-3,.col-4,.col-5,.col-6,.col-7,.col-8,.col-9{
    width:96%
}
.col-1-sm{
    width:4.33%
}
.col-2-sm{
    width:12.66%
}
.col-3-sm{
    width:21%
}
.col-4-sm{
    width:29.33%
}
.col-5-sm{
    width:37.66%
}
.col-6-sm{
    width:46%
}
.col-7-sm{
    width:54.33%
}
.col-8-sm{
    width:62.66%
}
.col-9-sm{
    width:71%
}
.col-10-sm{
    width:79.33%
}
.col-11-sm{
    width:87.66%
}
.col-12-sm{
    width:96%
}
.row::after{
    content:"";
    display:table;
    clear:both
}
.hidden-sm{
    display:none
}
@media only screen and (min-width:33.75em){
    .container{
        width:80%
    }
}
@media only screen and (min-width:45em){
    .col-1{
        width:4.33%
    }
    .col-2{
        width:12.66%
    }
    .col-3{
        width:21%
    }
    .col-4{
        width:29.33%
    }
    .col-5{
        width:37.66%
    }
    .col-6{
        width:46%
    }
    .col-7{
        width:54.33%
    }
    .col-8{
        width:62.66%
    }
    .col-9{
        width:73%
    }
    .col-10{
        width:79.33%
    }
    .col-11{
        width:87.66%
    }
    .col-12{
        width:96%
    }
    .hidden-sm{
        display:block
    }
}
@media only screen and (min-width:60em){
    .container{
        width:75%;
        max-width:60rem
    }
}


@media screen and ( min-width:768px) {

}
.body-container{
  min-height:100%;
  position:relative;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

/** {*/
/*  -webkit-font-smoothing: subpixel-antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*}*/

@keyframes mdc-ripple-fg-radius-in {
    from {
      animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transform: translate(var(--mdc-ripple-fg-translate-start, 0)) scale(1); }
    to {
      transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); } }

  @keyframes mdc-ripple-fg-opacity-in {
    from {
      animation-timing-function: linear;
      opacity: 0; }
    to {
      opacity: var(--mdc-ripple-fg-opacity, 0.16); } }

  @keyframes mdc-ripple-fg-opacity-out {
    from {
      animation-timing-function: linear;
      opacity: var(--mdc-ripple-fg-opacity, 0.16); }
    to {
      opacity: 0; } }

  .mdc-ripple-surface--test-edge-var-bug {
    --mdc-ripple-surface-test-edge-var: 1px solid #000;
    visibility: hidden; }
    .mdc-ripple-surface--test-edge-var-bug::before {
      border: var(--mdc-ripple-surface-test-edge-var); }

  /**
   * The css property used for elevation. In most cases this should not be changed. It is exposed
   * as a variable for abstraction / easy use when needing to reference the property directly, for
   * example in a `will-change` rule.
   */
  /**
   * The default duration value for elevation transitions.
   */
  /**
   * The default easing value for elevation transitions.
   */
  /**
   * Applies the correct CSS rules to an element to give it the elevation specified by $z-value.
   * The $z-value must be between 0 and 24.
   * If $color has an alpha channel, it will be ignored and overridden. To increase the opacity of the shadow, use
   * $opacity-boost.
   */
  /**
   * Returns a string that can be used as the value for a `transition` property for elevation.
   * Calling this function directly is useful in situations where a component needs to transition
   * more than one property.
   *
   * ```scss
   * .foo {
   *   transition: mdc-elevation-transition-value(), opacity 100ms ease;
   *   will-change: $mdc-elevation-property, opacity;
   * }
   * ```
   */
  /* TODO(sgomes): Figure out what to do about desktop font sizes. */
  /* TODO(sgomes): Figure out what to do about i18n and i18n font sizes. */
  .mdc-button {
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 0.875rem;
    line-height: 2.25rem;
    font-weight: 500;
    letter-spacing: 0.04em;
    text-decoration: none;
    text-transform: uppercase;
    --mdc-ripple-fg-size: 0;
    --mdc-ripple-left: 0;
    --mdc-ripple-top: 0;
    --mdc-ripple-fg-scale: 1;
    --mdc-ripple-fg-translate-end: 0;
    --mdc-ripple-fg-translate-start: 0;
    -webkit-tap-highlight-color: transparent;
    will-change: transform, opacity;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    min-width: 64px;
    height: 36px;
    padding: 0 16px;
    border: none;
    outline: none;
    text-align: center;
    user-select: none;
    -webkit-appearance: none;
    overflow: hidden;
    vertical-align: middle;
    border-radius: 2px; }
    .mdc-button::before, .mdc-button::after {
      position: absolute;
      border-radius: 50%;
      opacity: 0;
      pointer-events: none;
      content: ""; }
    .mdc-button::before {
      transition: opacity 15ms linear; }
    .mdc-button.mdc-ripple-upgraded::after {
      top: 0;
      left: 0;
      transform: scale(0);
      transform-origin: center center; }
    .mdc-button.mdc-ripple-upgraded--unbounded::after {
      top: var(--mdc-ripple-top, 0);
      left: var(--mdc-ripple-left, 0); }
    .mdc-button.mdc-ripple-upgraded--foreground-activation::after {
      animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards; }
    .mdc-button.mdc-ripple-upgraded--foreground-deactivation::after {
      animation: 150ms mdc-ripple-fg-opacity-out;
      transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
    .mdc-button::before, .mdc-button::after {
      top: calc(50% - 100%);
      left: calc(50% - 100%);
      width: 200%;
      height: 200%; }
    .mdc-button.mdc-ripple-upgraded::before {
      top: calc(50% - 100%);
      left: calc(50% - 100%);
      width: 200%;
      height: 200%;
      transform: scale(var(--mdc-ripple-fg-scale, 0)); }
    .mdc-button.mdc-ripple-upgraded--unbounded::before {
      top: var(--mdc-ripple-top, calc(50% - 50%));
      left: var(--mdc-ripple-left, calc(50% - 50%));
      width: var(--mdc-ripple-fg-size, 100%);
      height: var(--mdc-ripple-fg-size, 100%);
      transform: scale(var(--mdc-ripple-fg-scale, 0)); }
    .mdc-button.mdc-ripple-upgraded::after {
      width: var(--mdc-ripple-fg-size, 100%);
      height: var(--mdc-ripple-fg-size, 100%); }
    .mdc-button:active {
      outline: none; }
    .mdc-button:hover {
      cursor: pointer; }
    .mdc-button::-moz-focus-inner {
      padding: 0;
      border: 0; }
    .mdc-button:disabled {
      background-color: transparent;
      /* @alternate */
      color: rgba(0, 0, 0, 0.38);
      color: var(--mdc-theme-text-disabled-on-light, rgba(0, 0, 0, 0.38));
      cursor: default;
      pointer-events: none; }
      .mdc-button--theme-dark .mdc-button:disabled,
      .mdc-theme--dark .mdc-button:disabled {
        /* @alternate */
        color: rgba(255, 255, 255, 0.5);
        color: var(--mdc-theme-text-disabled-on-dark, rgba(255, 255, 255, 0.5)); }
    .mdc-button:not(:disabled) {
      background-color: transparent; }
    .mdc-button:not(:disabled) {
      /* @alternate */
      color: #6200ee;
      color: var(--mdc-theme-primary, #6200ee); }
    .mdc-button::before, .mdc-button::after {
      /* @alternate */
      background-color: #6200ee; }
      @supports not (-ms-ime-align: auto) {
        .mdc-button::before, .mdc-button::after {
          background-color: var(--mdc-theme-primary, #6200ee); } }
    .mdc-button:hover::before {
      opacity: 0.04; }
    .mdc-button:not(.mdc-ripple-upgraded):focus::before, .mdc-button.mdc-ripple-upgraded--background-focused::before {
      transition-duration: 75ms;
      opacity: 0.12; }
    .mdc-button:not(.mdc-ripple-upgraded)::after {
      transition: opacity 150ms linear; }
    .mdc-button:not(.mdc-ripple-upgraded):active::after {
      transition-duration: 75ms;
      opacity: 0.16; }
    .mdc-button.mdc-ripple-upgraded {
      --mdc-ripple-fg-opacity: 0.16; }

  .mdc-button--raised:disabled,
  .mdc-button--unelevated:disabled {
    background-color: rgba(0, 0, 0, 0.12);
    /* @alternate */
    color: rgba(0, 0, 0, 0.38);
    color: var(--mdc-theme-text-disabled-on-light, rgba(0, 0, 0, 0.38)); }
    .mdc-button--theme-dark .mdc-button--raised:disabled,
    .mdc-theme--dark .mdc-button--raised:disabled, .mdc-button--theme-dark
    .mdc-button--unelevated:disabled,
    .mdc-theme--dark
    .mdc-button--unelevated:disabled {
      background-color: rgba(255, 255, 255, 0.12);
      /* @alternate */
      color: rgba(0, 0, 0, 0.38);
      color: var(--mdc-theme-text-disabled-on-light, rgba(0, 0, 0, 0.38)); }

  .mdc-button--raised:not(:disabled),
  .mdc-button--unelevated:not(:disabled) {
    /* @alternate */
    background-color: #6200ee; }
    @supports not (-ms-ime-align: auto) {
      .mdc-button--raised:not(:disabled),
      .mdc-button--unelevated:not(:disabled) {
        background-color: var(--mdc-theme-primary, #6200ee); } }

  .mdc-button--raised:not(:disabled),
  .mdc-button--unelevated:not(:disabled) {
    /* @alternate */
    color: white;
    color: var(--mdc-theme-text-primary-on-primary, white); }

  .mdc-button--raised::before, .mdc-button--raised::after,
  .mdc-button--unelevated::before,
  .mdc-button--unelevated::after {
    /* @alternate */
    background-color: white; }
    @supports not (-ms-ime-align: auto) {
      .mdc-button--raised::before, .mdc-button--raised::after,
      .mdc-button--unelevated::before,
      .mdc-button--unelevated::after {
        background-color: var(--mdc-theme-text-primary-on-primary, white); } }

  .mdc-button--raised:hover::before,
  .mdc-button--unelevated:hover::before {
    opacity: 0.08; }

  .mdc-button--raised:not(.mdc-ripple-upgraded):focus::before, .mdc-button--raised.mdc-ripple-upgraded--background-focused::before,
  .mdc-button--unelevated:not(.mdc-ripple-upgraded):focus::before,
  .mdc-button--unelevated.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.24; }

  .mdc-button--raised:not(.mdc-ripple-upgraded)::after,
  .mdc-button--unelevated:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }

  .mdc-button--raised:not(.mdc-ripple-upgraded):active::after,
  .mdc-button--unelevated:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.32; }

  .mdc-button--raised.mdc-ripple-upgraded,
  .mdc-button--unelevated.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.32; }

  .mdc-button--raised {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1); }
    .mdc-button--raised:hover, .mdc-button--raised:focus {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .mdc-button--raised:active {
      box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12); }
    .mdc-button--raised:disabled {
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12); }

  .mdc-button--stroked {
    border-style: solid;
    padding-right: 14px;
    padding-left: 14px;
    border-width: 2px;
    line-height: 32px; }
    .mdc-button--stroked:disabled {
      /* @alternate */
      border-color: rgba(0, 0, 0, 0.38);
      border-color: var(--mdc-theme-text-disabled-on-light, rgba(0, 0, 0, 0.38)); }
      .mdc-button--theme-dark .mdc-button--stroked:disabled,
      .mdc-theme--dark .mdc-button--stroked:disabled {
        /* @alternate */
        border-color: rgba(255, 255, 255, 0.5);
        border-color: var(--mdc-theme-text-disabled-on-dark, rgba(255, 255, 255, 0.5)); }
    .mdc-button--stroked.mdc-button--dense {
      line-height: 27px; }
    .mdc-button--stroked.mdc-button--compact {
      padding-right: 6px;
      padding-left: 6px; }
    .mdc-button--stroked:not(:disabled) {
      /* @alternate */
      border-color: #6200ee;
      border-color: var(--mdc-theme-primary, #6200ee); }

  .mdc-button--compact {
    padding: 0 8px; }

  .mdc-button--dense {
    height: 32px;
    font-size: .8125rem;
    line-height: 32px; }

  .mdc-button__icon {
    display: inline-block;
    width: 18px;
    height: 18px;
    margin-right: 8px;
    font-size: 18px;
    line-height: inherit;
    vertical-align: top; }

  /**
   * The css property used for elevation. In most cases this should not be changed. It is exposed
   * as a variable for abstraction / easy use when needing to reference the property directly, for
   * example in a `will-change` rule.
   */
  /**
   * The default duration value for elevation transitions.
   */
  /**
   * The default easing value for elevation transitions.
   */
  /**
   * Applies the correct CSS rules to an element to give it the elevation specified by $z-value.
   * The $z-value must be between 0 and 24.
   * If $color has an alpha channel, it will be ignored and overridden. To increase the opacity of the shadow, use
   * $opacity-boost.
   */
  /**
   * Returns a string that can be used as the value for a `transition` property for elevation.
   * Calling this function directly is useful in situations where a component needs to transition
   * more than one property.
   *
   * ```scss
   * .foo {
   *   transition: mdc-elevation-transition-value(), opacity 100ms ease;
   *   will-change: $mdc-elevation-property, opacity;
   * }
   * ```
   */
  /* TODO(sgomes): Figure out what to do about desktop font sizes. */
  /* TODO(sgomes): Figure out what to do about i18n and i18n font sizes. */
  /**
   * Creates a rule that will be applied when an MDC-Web component is within the context of an RTL layout.
   *
   * Usage Example:
   * ```scss
   * .mdc-foo {
   *   position: absolute;
   *   left: 0;
   *
   *   @include mdc-rtl {
   *     left: auto;
   *     right: 0;
   *   }
   *
   *   &__bar {
   *     margin-left: 4px;
   *     @include mdc-rtl(".mdc-foo") {
   *       margin-left: auto;
   *       margin-right: 4px;
   *     }
   *   }
   * }
   *
   * .mdc-foo--mod {
   *   padding-left: 4px;
   *
   *   @include mdc-rtl {
   *     padding-left: auto;
   *     padding-right: 4px;
   *   }
   * }
   * ```
   *
   * Note that this works by checking for [dir="rtl"] on an ancestor element. While this will work
   * in most cases, it will in some cases lead to false negatives, e.g.
   *
   * ```html
   * <html dir="rtl">
   *   <!-- ... -->
   *   <div dir="ltr">
   *     <div class="mdc-foo">Styled incorrectly as RTL!</div>
   *   </div>
   * </html>
   * ```
   *
   * In the future, selectors such as :dir (http://mdn.io/:dir) will help us mitigate this.
   */
  /**
   * Takes a base box-model property - e.g. margin / border / padding - along with a default
   * direction and value, and emits rules which apply the value to the
   * "<base-property>-<default-direction>" property by default, but flips the direction
   * when within an RTL context.
   *
   * For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-box(margin, left, 8px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-left: 8px;
   *
   *   @include mdc-rtl {
   *     margin-right: 8px;
   *     margin-left: 0;
   *   }
   * }
   * ```
   * whereas:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-box(margin, right, 8px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-right: 8px;
   *
   *   @include mdc-rtl {
   *     margin-right: 0;
   *     margin-left: 8px;
   *   }
   * }
   * ```
   *
   * You can also pass a 4th optional $root-selector argument which will be forwarded to `mdc-rtl`,
   * e.g. `@include mdc-rtl-reflexive-box(margin, left, 8px, ".mdc-component")`.
   *
   * Note that this function will always zero out the original value in an RTL context. If you're
   * trying to flip the values, use mdc-rtl-reflexive-property().
   */
  /**
   * Takes a base property and emits rules that assign <base-property>-left to <left-value> and
   * <base-property>-right to <right-value> in a LTR context, and vice versa in a RTL context.
   * For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-property(margin, auto, 12px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-left: auto;
   *   margin-right: 12px;
   *
   *   @include mdc-rtl {
   *     margin-left: 12px;
   *     margin-right: auto;
   *   }
   * }
   * ```
   *
   * A 4th optional $root-selector argument can be given, which will be passed to `mdc-rtl`.
   */
  /**
   * Takes an argument specifying a horizontal position property (either "left" or "right") as well
   * as a value, and applies that value to the specified position in a LTR context, and flips it in a
   * RTL context. For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-position(left, 0);
   *   position: absolute;
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   *  .mdc-foo {
   *    position: absolute;
   *    left: 0;
   *    right: initial;
   *
   *    @include mdc-rtl {
   *      right: 0;
   *      left: initial;
   *    }
   *  }
   * ```
   * An optional third $root-selector argument may also be given, which is passed to `mdc-rtl`.
   */
  .mdc-card {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    box-sizing: border-box;
    padding: 0;
    border-radius: 2px;
    overflow: hidden; }
    .mdc-card__primary {
      padding: 16px; }
      .mdc-card__primary .mdc-card__title--large {
        padding-top: 8px; }
      .mdc-card__primary:last-child {
        padding-bottom: 24px; }
    .mdc-card__supporting-text {
      font-family: Roboto, sans-serif;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-weight: 400;
      letter-spacing: 0.04em;
      text-decoration: inherit;
      text-transform: inherit;
      /* @alternate */
      color: rgba(0, 0, 0, 0.87);
      color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87));
      box-sizing: border-box;
      padding: 8px 16px; }
      .mdc-card--theme-dark .mdc-card__supporting-text,
      .mdc-theme--dark .mdc-card__supporting-text {
        /* @alternate */
        color: white;
        color: var(--mdc-theme-text-primary-on-dark, white); }
      .mdc-card__primary + .mdc-card__supporting-text {
        margin-top: -8px;
        padding-top: 0; }
      .mdc-card__supporting-text:last-child {
        padding-bottom: 24px; }
    .mdc-card__actions {
      display: flex;
      box-sizing: border-box;
      padding: 8px; }
      .mdc-card--theme-dark .mdc-card__actions,
      .mdc-theme--dark .mdc-card__actions {
        /* @alternate */
        color: white;
        color: var(--mdc-theme-text-primary-on-dark, white); }
      .mdc-card__actions .mdc-card__action {
        margin: 0 8px 0 0; }
        [dir="rtl"] .mdc-card__actions .mdc-card__action, .mdc-card__actions .mdc-card__action[dir="rtl"] {
          margin: 0 0 0 8px; }
      .mdc-card__actions .mdc-card__action:last-child {
        margin-left: 0;
        margin-right: 0; }
        [dir="rtl"] .mdc-card__actions .mdc-card__action:last-child, .mdc-card__actions .mdc-card__action:last-child[dir="rtl"] {
          margin-left: 0;
          margin-right: 0; }
      .mdc-card__actions--vertical {
        flex-flow: column;
        align-items: flex-start; }
        .mdc-card__actions--vertical .mdc-card__action {
          margin: 0 0 4px; }
        .mdc-card__actions--vertical .mdc-card__action:last-child {
          margin-bottom: 0; }
    .mdc-card__media {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      box-sizing: border-box;
      padding: 16px; }
    .mdc-card__media-item {
      display: inline-block;
      width: auto;
      height: 80px;
      margin: 16px 0 0;
      padding: 0; }
      .mdc-card__media-item--1dot5x {
        width: auto;
        height: 120px; }
      .mdc-card__media-item--2x {
        width: auto;
        height: 160px; }
      .mdc-card__media-item--3x {
        width: auto;
        height: 240px; }
    .mdc-card__title {
      font-family: Roboto, sans-serif;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-size: 0.875rem;
      line-height: 1.5rem;
      font-weight: 500;
      letter-spacing: 0.04em;
      text-decoration: inherit;
      text-transform: inherit;
      /* @alternate */
      color: rgba(0, 0, 0, 0.87);
      color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87));
      margin: -.063rem 0; }
      .mdc-card--theme-dark .mdc-card__title,
      .mdc-theme--dark .mdc-card__title {
        /* @alternate */
        color: white;
        color: var(--mdc-theme-text-primary-on-dark, white); }
    .mdc-card__title--large {
      font-family: Roboto, sans-serif;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-size: 1.5rem;
      line-height: 2rem;
      font-weight: 400;
      letter-spacing: normal;
      text-decoration: inherit;
      text-transform: inherit;
      margin: 0; }
    .mdc-card__subtitle {
      font-family: Roboto, sans-serif;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-weight: 400;
      letter-spacing: 0.04em;
      text-decoration: inherit;
      text-transform: inherit;
      /* @alternate */
      color: rgba(0, 0, 0, 0.87);
      color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87));
      margin: -.063rem 0; }
      .mdc-card--theme-dark .mdc-card__subtitle,
      .mdc-theme--dark .mdc-card__subtitle {
        /* @alternate */
        color: white;
        color: var(--mdc-theme-text-primary-on-dark, white); }
    .mdc-card__horizontal-block {
      padding-left: 0;
      padding-right: 16px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      box-sizing: border-box; }
      [dir="rtl"] .mdc-card__horizontal-block, .mdc-card__horizontal-block[dir="rtl"] {
        padding-left: 16px;
        padding-right: 0; }
      .mdc-card__horizontal-block .mdc-card__actions--vertical {
        margin: 16px; }
      .mdc-card__horizontal-block .mdc-card__media-item {
        margin-left: 16px;
        margin-right: 0; }
        [dir="rtl"] .mdc-card__horizontal-block .mdc-card__media-item, .mdc-card__horizontal-block .mdc-card__media-item[dir="rtl"] {
          margin-left: 0;
          margin-right: 16px; }
      .mdc-card__horizontal-block .mdc-card__media-item--3x {
        margin-bottom: 16px; }

  /**
   * Creates a rule that will be applied when an MDC-Web component is within the context of an RTL layout.
   *
   * Usage Example:
   * ```scss
   * .mdc-foo {
   *   position: absolute;
   *   left: 0;
   *
   *   @include mdc-rtl {
   *     left: auto;
   *     right: 0;
   *   }
   *
   *   &__bar {
   *     margin-left: 4px;
   *     @include mdc-rtl(".mdc-foo") {
   *       margin-left: auto;
   *       margin-right: 4px;
   *     }
   *   }
   * }
   *
   * .mdc-foo--mod {
   *   padding-left: 4px;
   *
   *   @include mdc-rtl {
   *     padding-left: auto;
   *     padding-right: 4px;
   *   }
   * }
   * ```
   *
   * Note that this works by checking for [dir="rtl"] on an ancestor element. While this will work
   * in most cases, it will in some cases lead to false negatives, e.g.
   *
   * ```html
   * <html dir="rtl">
   *   <!-- ... -->
   *   <div dir="ltr">
   *     <div class="mdc-foo">Styled incorrectly as RTL!</div>
   *   </div>
   * </html>
   * ```
   *
   * In the future, selectors such as :dir (http://mdn.io/:dir) will help us mitigate this.
   */
  /**
   * Takes a base box-model property - e.g. margin / border / padding - along with a default
   * direction and value, and emits rules which apply the value to the
   * "<base-property>-<default-direction>" property by default, but flips the direction
   * when within an RTL context.
   *
   * For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-box(margin, left, 8px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-left: 8px;
   *
   *   @include mdc-rtl {
   *     margin-right: 8px;
   *     margin-left: 0;
   *   }
   * }
   * ```
   * whereas:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-box(margin, right, 8px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-right: 8px;
   *
   *   @include mdc-rtl {
   *     margin-right: 0;
   *     margin-left: 8px;
   *   }
   * }
   * ```
   *
   * You can also pass a 4th optional $root-selector argument which will be forwarded to `mdc-rtl`,
   * e.g. `@include mdc-rtl-reflexive-box(margin, left, 8px, ".mdc-component")`.
   *
   * Note that this function will always zero out the original value in an RTL context. If you're
   * trying to flip the values, use mdc-rtl-reflexive-property().
   */
  /**
   * Takes a base property and emits rules that assign <base-property>-left to <left-value> and
   * <base-property>-right to <right-value> in a LTR context, and vice versa in a RTL context.
   * For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-property(margin, auto, 12px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-left: auto;
   *   margin-right: 12px;
   *
   *   @include mdc-rtl {
   *     margin-left: 12px;
   *     margin-right: auto;
   *   }
   * }
   * ```
   *
   * A 4th optional $root-selector argument can be given, which will be passed to `mdc-rtl`.
   */
  /**
   * Takes an argument specifying a horizontal position property (either "left" or "right") as well
   * as a value, and applies that value to the specified position in a LTR context, and flips it in a
   * RTL context. For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-position(left, 0);
   *   position: absolute;
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   *  .mdc-foo {
   *    position: absolute;
   *    left: 0;
   *    right: initial;
   *
   *    @include mdc-rtl {
   *      right: 0;
   *      left: initial;
   *    }
   *  }
   * ```
   * An optional third $root-selector argument may also be given, which is passed to `mdc-rtl`.
   */
  @keyframes mdc-checkbox-unchecked-checked-checkmark-path {
    0%,
    50% {
      stroke-dashoffset: 29.78334; }
    50% {
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1); }
    100% {
      stroke-dashoffset: 0; } }

  @keyframes mdc-checkbox-unchecked-indeterminate-mixedmark {
    0%,
    68.2% {
      transform: scaleX(0); }
    68.2% {
      animation-timing-function: cubic-bezier(0, 0, 0, 1); }
    100% {
      transform: scaleX(1); } }

  @keyframes mdc-checkbox-checked-unchecked-checkmark-path {
    from {
      animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
      opacity: 1;
      stroke-dashoffset: 0; }
    to {
      opacity: 0;
      stroke-dashoffset: -29.78334; } }

  @keyframes mdc-checkbox-checked-indeterminate-checkmark {
    from {
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
      transform: rotate(0deg);
      opacity: 1; }
    to {
      transform: rotate(45deg);
      opacity: 0; } }

  @keyframes mdc-checkbox-indeterminate-checked-checkmark {
    from {
      animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
      transform: rotate(45deg);
      opacity: 0; }
    to {
      transform: rotate(360deg);
      opacity: 1; } }

  @keyframes mdc-checkbox-checked-indeterminate-mixedmark {
    from {
      animation-timing-function: mdc-animation-deceleration-curve-timing-function;
      transform: rotate(-45deg);
      opacity: 0; }
    to {
      transform: rotate(0deg);
      opacity: 1; } }

  @keyframes mdc-checkbox-indeterminate-checked-mixedmark {
    from {
      animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
      transform: rotate(0deg);
      opacity: 1; }
    to {
      transform: rotate(315deg);
      opacity: 0; } }

  @keyframes mdc-checkbox-indeterminate-unchecked-mixedmark {
    0% {
      animation-timing-function: linear;
      transform: scaleX(1);
      opacity: 1; }
    32.8%,
    100% {
      transform: scaleX(0);
      opacity: 0; } }

  .mdc-checkbox {
    display: inline-block;
    position: relative;
    flex: 0 0 18px;
    box-sizing: content-box;
    width: 18px;
    height: 18px;
    padding: 11px;
    line-height: 0;
    white-space: nowrap;
    cursor: pointer;
    vertical-align: bottom;
    --mdc-ripple-fg-size: 0;
    --mdc-ripple-left: 0;
    --mdc-ripple-top: 0;
    --mdc-ripple-fg-scale: 1;
    --mdc-ripple-fg-translate-end: 0;
    --mdc-ripple-fg-translate-start: 0;
    -webkit-tap-highlight-color: transparent;
    will-change: transform, opacity; }
    .mdc-checkbox .mdc-checkbox__native-control:disabled:not(:checked):not(:indeterminate) ~ .mdc-checkbox__background {
      border-color: rgba(0, 0, 0, 0.26); }
    .mdc-checkbox .mdc-checkbox__native-control:disabled:checked ~ .mdc-checkbox__background,
    .mdc-checkbox .mdc-checkbox__native-control:disabled:indeterminate ~ .mdc-checkbox__background {
      border-color: transparent;
      background-color: rgba(0, 0, 0, 0.26); }
    .mdc-checkbox--theme-dark .mdc-checkbox__native-control:disabled:not(:checked):not(:indeterminate) ~ .mdc-checkbox__background,
    .mdc-theme--dark .mdc-checkbox .mdc-checkbox__native-control:disabled:not(:checked):not(:indeterminate) ~ .mdc-checkbox__background {
      border-color: rgba(255, 255, 255, 0.3); }
    .mdc-checkbox--theme-dark .mdc-checkbox__native-control:disabled:checked ~ .mdc-checkbox__background,
    .mdc-checkbox--theme-dark .mdc-checkbox__native-control:disabled:indeterminate ~ .mdc-checkbox__background,
    .mdc-theme--dark .mdc-checkbox .mdc-checkbox__native-control:disabled:checked ~ .mdc-checkbox__background,
    .mdc-theme--dark .mdc-checkbox .mdc-checkbox__native-control:disabled:indeterminate ~ .mdc-checkbox__background {
      background-color: rgba(255, 255, 255, 0.3); }
    .mdc-checkbox::before, .mdc-checkbox::after {
      position: absolute;
      border-radius: 50%;
      opacity: 0;
      pointer-events: none;
      content: ""; }
    .mdc-checkbox::before {
      transition: opacity 15ms linear; }
    .mdc-checkbox.mdc-ripple-upgraded::after {
      top: 0;
      left: 0;
      transform: scale(0);
      transform-origin: center center; }
    .mdc-checkbox.mdc-ripple-upgraded--unbounded::after {
      top: var(--mdc-ripple-top, 0);
      left: var(--mdc-ripple-left, 0); }
    .mdc-checkbox.mdc-ripple-upgraded--foreground-activation::after {
      animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards; }
    .mdc-checkbox.mdc-ripple-upgraded--foreground-deactivation::after {
      animation: 150ms mdc-ripple-fg-opacity-out;
      transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
    .mdc-checkbox::before, .mdc-checkbox::after {
      /* @alternate */
      background-color: #018786; }
      @supports not (-ms-ime-align: auto) {
        .mdc-checkbox::before, .mdc-checkbox::after {
          background-color: var(--mdc-theme-secondary, #018786); } }
    .mdc-checkbox:hover::before {
      opacity: 0.04; }
    .mdc-checkbox:not(.mdc-ripple-upgraded):focus::before, .mdc-checkbox.mdc-ripple-upgraded--background-focused::before {
      transition-duration: 75ms;
      opacity: 0.12; }
    .mdc-checkbox:not(.mdc-ripple-upgraded)::after {
      transition: opacity 150ms linear; }
    .mdc-checkbox:not(.mdc-ripple-upgraded):active::after {
      transition-duration: 75ms;
      opacity: 0.16; }
    .mdc-checkbox.mdc-ripple-upgraded {
      --mdc-ripple-fg-opacity: 0.16; }
    .mdc-checkbox::before, .mdc-checkbox::after {
      top: calc(50% - 50%);
      left: calc(50% - 50%);
      width: 100%;
      height: 100%; }
    .mdc-checkbox.mdc-ripple-upgraded::before {
      top: calc(50% - 50%);
      left: calc(50% - 50%);
      width: 100%;
      height: 100%;
      transform: scale(var(--mdc-ripple-fg-scale, 0)); }
    .mdc-checkbox.mdc-ripple-upgraded--unbounded::before {
      top: var(--mdc-ripple-top, calc(50% - 25%));
      left: var(--mdc-ripple-left, calc(50% - 25%));
      width: var(--mdc-ripple-fg-size, 50%);
      height: var(--mdc-ripple-fg-size, 50%);
      transform: scale(var(--mdc-ripple-fg-scale, 0)); }
    .mdc-checkbox.mdc-ripple-upgraded::after {
      width: var(--mdc-ripple-fg-size, 50%);
      height: var(--mdc-ripple-fg-size, 50%); }
    .mdc-checkbox--theme-dark .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate) ~ .mdc-checkbox__background,
    .mdc-theme--dark .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate) ~ .mdc-checkbox__background {
      border-color: white;
      background-color: transparent; }
    .mdc-checkbox--theme-dark .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
    .mdc-checkbox--theme-dark .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
    .mdc-theme--dark .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
    .mdc-theme--dark .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background {
      /* @alternate */
      border-color: #018786;
      border-color: var(--mdc-theme-secondary, #018786);
      /* @alternate */
      background-color: #018786;
      background-color: var(--mdc-theme-secondary, #018786); }

  @keyframes mdc-checkbox-fade-in-background-0 {
    0% {
      border-color: white;
      background-color: transparent; }
    50% {
      /* @alternate */
      border-color: #018786;
      border-color: var(--mdc-theme-secondary, #018786);
      /* @alternate */
      background-color: #018786;
      background-color: var(--mdc-theme-secondary, #018786); } }

  @keyframes mdc-checkbox-fade-out-background-0 {
    0%,
    80% {
      /* @alternate */
      border-color: #018786;
      border-color: var(--mdc-theme-secondary, #018786);
      /* @alternate */
      background-color: #018786;
      background-color: var(--mdc-theme-secondary, #018786); }
    100% {
      border-color: white;
      background-color: transparent; } }
    .mdc-checkbox--theme-dark.mdc-checkbox--anim-unchecked-checked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background, .mdc-checkbox--theme-dark.mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background,
    .mdc-theme--dark .mdc-checkbox.mdc-checkbox--anim-unchecked-checked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background,
    .mdc-theme--dark .mdc-checkbox.mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background {
      animation-name: mdc-checkbox-fade-in-background-0; }
    .mdc-checkbox--theme-dark.mdc-checkbox--anim-checked-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background, .mdc-checkbox--theme-dark.mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background,
    .mdc-theme--dark .mdc-checkbox.mdc-checkbox--anim-checked-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background,
    .mdc-theme--dark .mdc-checkbox.mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background {
      animation-name: mdc-checkbox-fade-out-background-0; }

  .mdc-checkbox__checkmark__path {
    stroke: white !important; }

  .mdc-checkbox__mixedmark {
    background-color: white; }

  .mdc-checkbox__background::before {
    /* @alternate */
    background-color: #018786; }
    @supports not (-ms-ime-align: auto) {
      .mdc-checkbox__background::before {
        background-color: var(--mdc-theme-secondary, #018786); } }

  .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate) ~ .mdc-checkbox__background {
    border-color: rgba(0, 0, 0, 0.54);
    background-color: transparent; }

  .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
  .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background {
    /* @alternate */
    border-color: #018786;
    border-color: var(--mdc-theme-secondary, #018786);
    /* @alternate */
    background-color: #018786;
    background-color: var(--mdc-theme-secondary, #018786); }

  @keyframes mdc-checkbox-fade-in-background-1 {
    0% {
      border-color: rgba(0, 0, 0, 0.54);
      background-color: transparent; }
    50% {
      /* @alternate */
      border-color: #018786;
      border-color: var(--mdc-theme-secondary, #018786);
      /* @alternate */
      background-color: #018786;
      background-color: var(--mdc-theme-secondary, #018786); } }

  @keyframes mdc-checkbox-fade-out-background-1 {
    0%,
    80% {
      /* @alternate */
      border-color: #018786;
      border-color: var(--mdc-theme-secondary, #018786);
      /* @alternate */
      background-color: #018786;
      background-color: var(--mdc-theme-secondary, #018786); }
    100% {
      border-color: rgba(0, 0, 0, 0.54);
      background-color: transparent; } }

  .mdc-checkbox--anim-unchecked-checked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background, .mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background {
    animation-name: mdc-checkbox-fade-in-background-1; }

  .mdc-checkbox--anim-checked-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background, .mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background {
    animation-name: mdc-checkbox-fade-out-background-1; }

  .mdc-checkbox--disabled {
    cursor: default;
    pointer-events: none; }

  .mdc-checkbox--upgraded .mdc-checkbox__background,
  .mdc-checkbox--upgraded .mdc-checkbox__checkmark,
  .mdc-checkbox--upgraded .mdc-checkbox__checkmark__path,
  .mdc-checkbox--upgraded .mdc-checkbox__mixedmark {
    transition: none !important; }

  .mdc-checkbox--anim-unchecked-checked .mdc-checkbox__background, .mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__background, .mdc-checkbox--anim-checked-unchecked .mdc-checkbox__background, .mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__background {
    animation-duration: 180ms;
    animation-timing-function: linear; }

  .mdc-checkbox--anim-unchecked-checked .mdc-checkbox__checkmark__path {
    animation: 180ms linear 0s mdc-checkbox-unchecked-checked-checkmark-path;
    transition: none; }

  .mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__mixedmark {
    animation: 90ms linear 0s mdc-checkbox-unchecked-indeterminate-mixedmark;
    transition: none; }

  .mdc-checkbox--anim-checked-unchecked .mdc-checkbox__checkmark__path {
    animation: 90ms linear 0s mdc-checkbox-checked-unchecked-checkmark-path;
    transition: none; }

  .mdc-checkbox--anim-checked-indeterminate .mdc-checkbox__checkmark {
    animation: 90ms linear 0s mdc-checkbox-checked-indeterminate-checkmark;
    transition: none; }

  .mdc-checkbox--anim-checked-indeterminate .mdc-checkbox__mixedmark {
    animation: 90ms linear 0s mdc-checkbox-checked-indeterminate-mixedmark;
    transition: none; }

  .mdc-checkbox--anim-indeterminate-checked .mdc-checkbox__checkmark {
    animation: 500ms linear 0s mdc-checkbox-indeterminate-checked-checkmark;
    transition: none; }

  .mdc-checkbox--anim-indeterminate-checked .mdc-checkbox__mixedmark {
    animation: 500ms linear 0s mdc-checkbox-indeterminate-checked-mixedmark;
    transition: none; }

  .mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__mixedmark {
    animation: 300ms linear 0s mdc-checkbox-indeterminate-unchecked-mixedmark;
    transition: none; }

  .mdc-checkbox__background {
    left: 11px;
    right: initial;
    display: inline-flex;
    position: absolute;
    top: 11px;
    bottom: 0;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 45%;
    height: 45%;
    transition: background-color 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), border-color 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
    border: 2px solid currentColor;
    border-radius: 2px;
    background-color: transparent;
    pointer-events: none;
    will-change: background-color, border-color; }
    [dir="rtl"] .mdc-checkbox .mdc-checkbox__background,
    .mdc-checkbox[dir="rtl"] .mdc-checkbox__background {
      left: initial;
      right: 11px; }
    .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
    .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background {
      transition: border-color 90ms 0ms cubic-bezier(0, 0, 0.2, 1), background-color 90ms 0ms cubic-bezier(0, 0, 0.2, 1); }

  .mdc-checkbox__background::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scale(0, 0);
    transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: "";
    will-change: opacity, transform; }
    .mdc-checkbox__native-control:focus ~ .mdc-checkbox__background::before {
      transform: scale(2.75, 2.75);
      transition: opacity 80ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 80ms 0ms cubic-bezier(0, 0, 0.2, 1);
      opacity: 0.26; }
    .mdc-ripple-upgraded--unbounded .mdc-checkbox__background::before {
      content: none; }

  .mdc-checkbox__native-control {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    opacity: 0;
    cursor: inherit; }
    .mdc-checkbox__native-control:disabled {
      cursor: default;
      pointer-events: none; }

  .mdc-checkbox__checkmark {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    transition: opacity 180ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
    opacity: 0; }
    .mdc-checkbox--upgraded .mdc-checkbox__checkmark {
      opacity: 1; }
    .mdc-checkbox__native-control:checked ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
      transition: opacity 180ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 180ms 0ms cubic-bezier(0, 0, 0.2, 1);
      opacity: 1; }
    .mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
      transform: rotate(45deg);
      transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
      opacity: 0; }

  .mdc-checkbox__checkmark__path {
    transition: stroke-dashoffset 180ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
    stroke-width: 3.12px;
    stroke-dashoffset: 29.78334;
    stroke-dasharray: 29.78334; }
    .mdc-checkbox__native-control:checked ~ .mdc-checkbox__background .mdc-checkbox__checkmark__path,
    .mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__checkmark__path {
      stroke-dashoffset: 0; }

  .mdc-checkbox__mixedmark {
    width: 100%;
    height: 2px;
    transform: scaleX(0) rotate(0deg);
    transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
    opacity: 0; }
    .mdc-checkbox__native-control:checked ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
      transform: scaleX(1) rotate(-45deg); }
    .mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
      transform: scaleX(1) rotate(0deg);
      opacity: 1; }

  /**
   * The css property used for elevation. In most cases this should not be changed. It is exposed
   * as a variable for abstraction / easy use when needing to reference the property directly, for
   * example in a `will-change` rule.
   */
  /**
   * The default duration value for elevation transitions.
   */
  /**
   * The default easing value for elevation transitions.
   */
  /**
   * Applies the correct CSS rules to an element to give it the elevation specified by $z-value.
   * The $z-value must be between 0 and 24.
   * If $color has an alpha channel, it will be ignored and overridden. To increase the opacity of the shadow, use
   * $opacity-boost.
   */
  /**
   * Returns a string that can be used as the value for a `transition` property for elevation.
   * Calling this function directly is useful in situations where a component needs to transition
   * more than one property.
   *
   * ```scss
   * .foo {
   *   transition: mdc-elevation-transition-value(), opacity 100ms ease;
   *   will-change: $mdc-elevation-property, opacity;
   * }
   * ```
   */
  /**
   * Creates a rule that will be applied when an MDC-Web component is within the context of an RTL layout.
   *
   * Usage Example:
   * ```scss
   * .mdc-foo {
   *   position: absolute;
   *   left: 0;
   *
   *   @include mdc-rtl {
   *     left: auto;
   *     right: 0;
   *   }
   *
   *   &__bar {
   *     margin-left: 4px;
   *     @include mdc-rtl(".mdc-foo") {
   *       margin-left: auto;
   *       margin-right: 4px;
   *     }
   *   }
   * }
   *
   * .mdc-foo--mod {
   *   padding-left: 4px;
   *
   *   @include mdc-rtl {
   *     padding-left: auto;
   *     padding-right: 4px;
   *   }
   * }
   * ```
   *
   * Note that this works by checking for [dir="rtl"] on an ancestor element. While this will work
   * in most cases, it will in some cases lead to false negatives, e.g.
   *
   * ```html
   * <html dir="rtl">
   *   <!-- ... -->
   *   <div dir="ltr">
   *     <div class="mdc-foo">Styled incorrectly as RTL!</div>
   *   </div>
   * </html>
   * ```
   *
   * In the future, selectors such as :dir (http://mdn.io/:dir) will help us mitigate this.
   */
  /**
   * Takes a base box-model property - e.g. margin / border / padding - along with a default
   * direction and value, and emits rules which apply the value to the
   * "<base-property>-<default-direction>" property by default, but flips the direction
   * when within an RTL context.
   *
   * For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-box(margin, left, 8px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-left: 8px;
   *
   *   @include mdc-rtl {
   *     margin-right: 8px;
   *     margin-left: 0;
   *   }
   * }
   * ```
   * whereas:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-box(margin, right, 8px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-right: 8px;
   *
   *   @include mdc-rtl {
   *     margin-right: 0;
   *     margin-left: 8px;
   *   }
   * }
   * ```
   *
   * You can also pass a 4th optional $root-selector argument which will be forwarded to `mdc-rtl`,
   * e.g. `@include mdc-rtl-reflexive-box(margin, left, 8px, ".mdc-component")`.
   *
   * Note that this function will always zero out the original value in an RTL context. If you're
   * trying to flip the values, use mdc-rtl-reflexive-property().
   */
  /**
   * Takes a base property and emits rules that assign <base-property>-left to <left-value> and
   * <base-property>-right to <right-value> in a LTR context, and vice versa in a RTL context.
   * For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-property(margin, auto, 12px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-left: auto;
   *   margin-right: 12px;
   *
   *   @include mdc-rtl {
   *     margin-left: 12px;
   *     margin-right: auto;
   *   }
   * }
   * ```
   *
   * A 4th optional $root-selector argument can be given, which will be passed to `mdc-rtl`.
   */
  /**
   * Takes an argument specifying a horizontal position property (either "left" or "right") as well
   * as a value, and applies that value to the specified position in a LTR context, and flips it in a
   * RTL context. For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-position(left, 0);
   *   position: absolute;
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   *  .mdc-foo {
   *    position: absolute;
   *    left: 0;
   *    right: initial;
   *
   *    @include mdc-rtl {
   *      right: 0;
   *      left: initial;
   *    }
   *  }
   * ```
   * An optional third $root-selector argument may also be given, which is passed to `mdc-rtl`.
   */
  /* TODO(sgomes): Figure out what to do about desktop font sizes. */
  /* TODO(sgomes): Figure out what to do about i18n and i18n font sizes. */
  /* TODO(sgomes): Figure out what to do about desktop font sizes. */
  /* TODO(sgomes): Figure out what to do about i18n and i18n font sizes. */
  :root {
    --mdc-dialog-dark-theme-bg-color: #303030; }

  .mdc-dialog {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    visibility: hidden;
    z-index: 5; }
    .mdc-dialog__backdrop {
      /* @alternate */
      background-color: rgba(0, 0, 0, 0.87);
      background-color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87));
      position: fixed;
      top: 0;
      left: 0;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: -1; }
    .mdc-dialog__surface {
      box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
      /* @alternate */
      background-color: #fff;
      background-color: var(--mdc-theme-background, #fff);
      display: inline-flex;
      flex-direction: column;
      width: calc(100% - 30px);
      min-width: 640px;
      max-width: 865px;
      transform: translateY(150px) scale(0.8);
      border-radius: 2px;
      opacity: 0; }
      .mdc-dialog--theme-dark .mdc-dialog__surface,
      .mdc-theme--dark .mdc-dialog__surface {
        /* @alternate */
        color: white;
        color: var(--mdc-theme-text-primary-on-dark, white);
        background-color: #303030;
        background-color: var(--mdc-dialog-dark-theme-bg-color, #303030); }
      [dir="rtl"] .mdc-dialog .mdc-dialog__surface,
      .mdc-dialog[dir="rtl"] .mdc-dialog__surface {
        text-align: right; }
    .mdc-dialog__header {
      display: flex;
      align-items: center;
      padding: 24px 24px 0; }
      .mdc-dialog__header__empty {
        padding: 0; }
      [dir="rtl"] .mdc-dialog .mdc-dialog__header,
      .mdc-dialog[dir="rtl"] .mdc-dialog__header {
        text-align: right; }
      .mdc-dialog__header__title {
        font-family: Roboto, sans-serif;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-size: 1.25rem;
        line-height: 2rem;
        font-weight: 500;
        letter-spacing: 0.02em;
        text-decoration: inherit;
        text-transform: inherit;
        flex: 1;
        margin: 0; }
    .mdc-dialog__body {
      /* @alternate */
      color: rgba(0, 0, 0, 0.54);
      color: var(--mdc-theme-text-secondary-on-light, rgba(0, 0, 0, 0.54));
      font-family: Roboto, sans-serif;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-weight: 400;
      letter-spacing: 0.04em;
      text-decoration: inherit;
      text-transform: inherit;
      margin-top: 20px;
      padding: 0 24px 24px; }
      .mdc-dialog--theme-dark .mdc-dialog__body,
      .mdc-theme--dark .mdc-dialog__body {
        /* @alternate */
        color: rgba(255, 255, 255, 0.7);
        color: var(--mdc-theme-text-secondary-on-dark, rgba(255, 255, 255, 0.7)); }
      .mdc-dialog__body--scrollable {
        max-height: 195px;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        overflow-x: auto;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch; }
    .mdc-dialog__footer {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-end;
      padding: 8px; }
      .mdc-dialog__footer__button {
        margin-left: 0;
        margin-right: 8px; }
        [dir="rtl"] .mdc-dialog__footer__button, .mdc-dialog__footer__button[dir="rtl"] {
          margin-left: 8px;
          margin-right: 0; }
        .mdc-dialog__footer__button:last-child {
          margin-left: 0;
          margin-right: 0; }
          [dir="rtl"] .mdc-dialog__footer__button:last-child, .mdc-dialog__footer__button:last-child[dir="rtl"] {
            margin-left: 0;
            margin-right: 0; }
    .mdc-dialog__action {
      /* @alternate */
      color: #018786;
      color: var(--mdc-theme-secondary, #018786); }
    @media (max-width: 640px) {
      .mdc-dialog {
        min-width: 280px; }
        .mdc-dialog__surface {
          min-width: 280px; }
        .mdc-dialog__body {
          line-height: 24px; } }
    .mdc-dialog--animating {
      visibility: visible; }
      .mdc-dialog--animating .mdc-dialog__backdrop {
        transition: opacity 120ms 0ms cubic-bezier(0, 0, 0.2, 1); }
      .mdc-dialog--animating .mdc-dialog--open .mdc-dialog__surface {
        transition: opacity 120ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 120ms 0ms cubic-bezier(0, 0, 0.2, 1); }
      .mdc-dialog--animating .mdc-dialog__surface {
        transition: opacity 120ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 120ms 0ms cubic-bezier(0, 0, 0.2, 1); }
    .mdc-dialog--open {
      visibility: visible; }
      .mdc-dialog--open .mdc-dialog__backdrop {
        opacity: .3; }
      .mdc-dialog--open .mdc-dialog__surface {
        transform: translateY(0) scale(1);
        opacity: 1; }

  .mdc-dialog-scroll-lock {
    overflow: hidden; }

  /**
   * Creates a rule that will be applied when an MDC-Web component is within the context of an RTL layout.
   *
   * Usage Example:
   * ```scss
   * .mdc-foo {
   *   position: absolute;
   *   left: 0;
   *
   *   @include mdc-rtl {
   *     left: auto;
   *     right: 0;
   *   }
   *
   *   &__bar {
   *     margin-left: 4px;
   *     @include mdc-rtl(".mdc-foo") {
   *       margin-left: auto;
   *       margin-right: 4px;
   *     }
   *   }
   * }
   *
   * .mdc-foo--mod {
   *   padding-left: 4px;
   *
   *   @include mdc-rtl {
   *     padding-left: auto;
   *     padding-right: 4px;
   *   }
   * }
   * ```
   *
   * Note that this works by checking for [dir="rtl"] on an ancestor element. While this will work
   * in most cases, it will in some cases lead to false negatives, e.g.
   *
   * ```html
   * <html dir="rtl">
   *   <!-- ... -->
   *   <div dir="ltr">
   *     <div class="mdc-foo">Styled incorrectly as RTL!</div>
   *   </div>
   * </html>
   * ```
   *
   * In the future, selectors such as :dir (http://mdn.io/:dir) will help us mitigate this.
   */
  /**
   * Takes a base box-model property - e.g. margin / border / padding - along with a default
   * direction and value, and emits rules which apply the value to the
   * "<base-property>-<default-direction>" property by default, but flips the direction
   * when within an RTL context.
   *
   * For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-box(margin, left, 8px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-left: 8px;
   *
   *   @include mdc-rtl {
   *     margin-right: 8px;
   *     margin-left: 0;
   *   }
   * }
   * ```
   * whereas:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-box(margin, right, 8px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-right: 8px;
   *
   *   @include mdc-rtl {
   *     margin-right: 0;
   *     margin-left: 8px;
   *   }
   * }
   * ```
   *
   * You can also pass a 4th optional $root-selector argument which will be forwarded to `mdc-rtl`,
   * e.g. `@include mdc-rtl-reflexive-box(margin, left, 8px, ".mdc-component")`.
   *
   * Note that this function will always zero out the original value in an RTL context. If you're
   * trying to flip the values, use mdc-rtl-reflexive-property().
   */
  /**
   * Takes a base property and emits rules that assign <base-property>-left to <left-value> and
   * <base-property>-right to <right-value> in a LTR context, and vice versa in a RTL context.
   * For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-property(margin, auto, 12px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-left: auto;
   *   margin-right: 12px;
   *
   *   @include mdc-rtl {
   *     margin-left: 12px;
   *     margin-right: auto;
   *   }
   * }
   * ```
   *
   * A 4th optional $root-selector argument can be given, which will be passed to `mdc-rtl`.
   */
  /**
   * Takes an argument specifying a horizontal position property (either "left" or "right") as well
   * as a value, and applies that value to the specified position in a LTR context, and flips it in a
   * RTL context. For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-position(left, 0);
   *   position: absolute;
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   *  .mdc-foo {
   *    position: absolute;
   *    left: 0;
   *    right: initial;
   *
   *    @include mdc-rtl {
   *      right: 0;
   *      left: initial;
   *    }
   *  }
   * ```
   * An optional third $root-selector argument may also be given, which is passed to `mdc-rtl`.
   */
  /* TODO(sgomes): Figure out what to do about desktop font sizes. */
  /* TODO(sgomes): Figure out what to do about i18n and i18n font sizes. */
  /**
   * Creates a rule that will be applied when an MDC-Web component is within the context of an RTL layout.
   *
   * Usage Example:
   * ```scss
   * .mdc-foo {
   *   position: absolute;
   *   left: 0;
   *
   *   @include mdc-rtl {
   *     left: auto;
   *     right: 0;
   *   }
   *
   *   &__bar {
   *     margin-left: 4px;
   *     @include mdc-rtl(".mdc-foo") {
   *       margin-left: auto;
   *       margin-right: 4px;
   *     }
   *   }
   * }
   *
   * .mdc-foo--mod {
   *   padding-left: 4px;
   *
   *   @include mdc-rtl {
   *     padding-left: auto;
   *     padding-right: 4px;
   *   }
   * }
   * ```
   *
   * Note that this works by checking for [dir="rtl"] on an ancestor element. While this will work
   * in most cases, it will in some cases lead to false negatives, e.g.
   *
   * ```html
   * <html dir="rtl">
   *   <!-- ... -->
   *   <div dir="ltr">
   *     <div class="mdc-foo">Styled incorrectly as RTL!</div>
   *   </div>
   * </html>
   * ```
   *
   * In the future, selectors such as :dir (http://mdn.io/:dir) will help us mitigate this.
   */
  /**
   * Takes a base box-model property - e.g. margin / border / padding - along with a default
   * direction and value, and emits rules which apply the value to the
   * "<base-property>-<default-direction>" property by default, but flips the direction
   * when within an RTL context.
   *
   * For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-box(margin, left, 8px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-left: 8px;
   *
   *   @include mdc-rtl {
   *     margin-right: 8px;
   *     margin-left: 0;
   *   }
   * }
   * ```
   * whereas:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-box(margin, right, 8px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-right: 8px;
   *
   *   @include mdc-rtl {
   *     margin-right: 0;
   *     margin-left: 8px;
   *   }
   * }
   * ```
   *
   * You can also pass a 4th optional $root-selector argument which will be forwarded to `mdc-rtl`,
   * e.g. `@include mdc-rtl-reflexive-box(margin, left, 8px, ".mdc-component")`.
   *
   * Note that this function will always zero out the original value in an RTL context. If you're
   * trying to flip the values, use mdc-rtl-reflexive-property().
   */
  /**
   * Takes a base property and emits rules that assign <base-property>-left to <left-value> and
   * <base-property>-right to <right-value> in a LTR context, and vice versa in a RTL context.
   * For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-property(margin, auto, 12px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-left: auto;
   *   margin-right: 12px;
   *
   *   @include mdc-rtl {
   *     margin-left: 12px;
   *     margin-right: auto;
   *   }
   * }
   * ```
   *
   * A 4th optional $root-selector argument can be given, which will be passed to `mdc-rtl`.
   */
  /**
   * Takes an argument specifying a horizontal position property (either "left" or "right") as well
   * as a value, and applies that value to the specified position in a LTR context, and flips it in a
   * RTL context. For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-position(left, 0);
   *   position: absolute;
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   *  .mdc-foo {
   *    position: absolute;
   *    left: 0;
   *    right: initial;
   *
   *    @include mdc-rtl {
   *      right: 0;
   *      left: initial;
   *    }
   *  }
   * ```
   * An optional third $root-selector argument may also be given, which is passed to `mdc-rtl`.
   */
  :root {
    --mdc-persistent-drawer-dark-theme-bg-color: #212121; }

  .mdc-drawer--persistent {
    /* Use aspect ratio trick to maintain 16:9 aspect ratio on the header */
    color: rgba(0, 0, 0, 0.87);
    width: 0; }
    .mdc-drawer--persistent .mdc-drawer__toolbar-spacer {
      display: flex;
      position: relative;
      flex-direction: row;
      flex-shrink: 0;
      align-items: center;
      box-sizing: border-box;
      height: 56px;
      padding: 16px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      /* TODO(sgomes): replace with global breakpoints when we have them */ }
      .mdc-drawer--persistent .mdc-drawer__toolbar-spacer--theme-dark .mdc-drawer--persistent .mdc-drawer__toolbar-spacer,
      .mdc-theme--dark .mdc-drawer--persistent .mdc-drawer__toolbar-spacer {
        border-bottom: 1px solid rgba(255, 255, 255, 0.12); }
      @media (min-width: 600px) {
        .mdc-drawer--persistent .mdc-drawer__toolbar-spacer {
          height: 64px; } }
    .mdc-drawer--persistent .mdc-drawer__header {
      position: relative; }
    .mdc-drawer--persistent .mdc-drawer__header::before {
      display: block;
      padding-top: 56.25%;
      content: ""; }
    .mdc-drawer--persistent .mdc-drawer__header-content {
      display: flex;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      align-items: flex-end;
      box-sizing: border-box;
      padding: 16px; }
    .mdc-drawer--persistent .mdc-list-item {
      font-family: Roboto, sans-serif;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-size: 0.875rem;
      line-height: 1.5rem;
      font-weight: 500;
      letter-spacing: 0.04em;
      text-decoration: inherit;
      text-transform: inherit;
      position: relative;
      outline: none;
      color: inherit;
      text-decoration: none; }
    .mdc-drawer--persistent .mdc-list-item__graphic {
      color: rgba(0, 0, 0, 0.54); }
      .mdc-drawer--persistent .mdc-list-item__graphic--theme-dark .mdc-drawer--persistent .mdc-list-item__graphic,
      .mdc-theme--dark .mdc-drawer--persistent .mdc-list-item__graphic {
        color: rgba(255, 255, 255, 0.54); }
    .mdc-drawer--persistent.mdc-drawer--permanent,
    .mdc-drawer--persistent .mdc-drawer__drawer {
      background-color: #fff; }
    .mdc-drawer--persistent .mdc-drawer__drawer {
      border-left: 0;
      border-right: 1px solid #e4e4e4;
      left: 0;
      right: initial;
      height: 100%;
      transform: translateX(-107%);
      transform: translateX(calc(-100% - 20px));
      will-change: transform;
      display: inline-flex;
      flex-direction: column;
      box-sizing: border-box;
      width: 240px;
      overflow: hidden;
      touch-action: none; }
      [dir="rtl"] .mdc-drawer--persistent .mdc-drawer__drawer, .mdc-drawer--persistent .mdc-drawer__drawer[dir="rtl"] {
        border-left: 1px solid #e4e4e4;
        border-right: 0; }
      [dir="rtl"] .mdc-drawer--persistent .mdc-drawer__drawer, .mdc-drawer--persistent .mdc-drawer__drawer[dir="rtl"] {
        left: initial;
        right: 0; }
      .mdc-drawer--persistent .mdc-drawer__drawer--theme-dark,
      .mdc-theme--dark .mdc-drawer--persistent .mdc-drawer__drawer {
        /* @alternate */
        color: white;
        color: var(--mdc-theme-text-primary-on-dark, white);
        border-left: 0;
        border-right: 1px solid rgba(255, 255, 255, 0.12);
        background-color: #212121;
        background-color: var(--mdc-persistent-drawer-dark-theme-bg-color, #212121); }
        [dir="rtl"] .mdc-drawer--persistent .mdc-drawer__drawer--theme-dark, .mdc-drawer--persistent .mdc-drawer__drawer--theme-dark[dir="rtl"], [dir="rtl"]
        .mdc-theme--dark .mdc-drawer--persistent .mdc-drawer__drawer,
        .mdc-theme--dark .mdc-drawer--persistent .mdc-drawer__drawer[dir="rtl"] {
          border-left: 1px solid rgba(255, 255, 255, 0.12);
          border-right: 0; }
      [dir="rtl"] .mdc-drawer--persistent .mdc-drawer--persistent .mdc-drawer__drawer,
      .mdc-drawer--persistent[dir="rtl"] .mdc-drawer--persistent .mdc-drawer__drawer {
        transform: translateX(107%);
        transform: translateX(calc(100% + 20px)); }
    .mdc-drawer--persistent.mdc-drawer--animating .mdc-drawer__drawer {
      transition: transform 0.195s 0ms cubic-bezier(0.4, 0, 0.6, 1); }
    .mdc-drawer--persistent.mdc-drawer--animating.mdc-drawer--open .mdc-drawer__drawer {
      transition: transform 0.225s 0ms cubic-bezier(0, 0, 0.2, 1); }
    .mdc-drawer--persistent.mdc-drawer--open {
      width: 240px;
      pointer-events: auto; }
      .mdc-drawer--persistent.mdc-drawer--open .mdc-drawer__drawer {
        transform: none; }
      [dir="rtl"] .mdc-drawer--persistent.mdc-drawer--open .mdc-drawer__drawer, .mdc-drawer--persistent.mdc-drawer--open[dir="rtl"] .mdc-drawer__drawer {
        transform: none; }

  /**
   * Creates a rule that will be applied when an MDC-Web component is within the context of an RTL layout.
   *
   * Usage Example:
   * ```scss
   * .mdc-foo {
   *   position: absolute;
   *   left: 0;
   *
   *   @include mdc-rtl {
   *     left: auto;
   *     right: 0;
   *   }
   *
   *   &__bar {
   *     margin-left: 4px;
   *     @include mdc-rtl(".mdc-foo") {
   *       margin-left: auto;
   *       margin-right: 4px;
   *     }
   *   }
   * }
   *
   * .mdc-foo--mod {
   *   padding-left: 4px;
   *
   *   @include mdc-rtl {
   *     padding-left: auto;
   *     padding-right: 4px;
   *   }
   * }
   * ```
   *
   * Note that this works by checking for [dir="rtl"] on an ancestor element. While this will work
   * in most cases, it will in some cases lead to false negatives, e.g.
   *
   * ```html
   * <html dir="rtl">
   *   <!-- ... -->
   *   <div dir="ltr">
   *     <div class="mdc-foo">Styled incorrectly as RTL!</div>
   *   </div>
   * </html>
   * ```
   *
   * In the future, selectors such as :dir (http://mdn.io/:dir) will help us mitigate this.
   */
  /**
   * Takes a base box-model property - e.g. margin / border / padding - along with a default
   * direction and value, and emits rules which apply the value to the
   * "<base-property>-<default-direction>" property by default, but flips the direction
   * when within an RTL context.
   *
   * For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-box(margin, left, 8px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-left: 8px;
   *
   *   @include mdc-rtl {
   *     margin-right: 8px;
   *     margin-left: 0;
   *   }
   * }
   * ```
   * whereas:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-box(margin, right, 8px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-right: 8px;
   *
   *   @include mdc-rtl {
   *     margin-right: 0;
   *     margin-left: 8px;
   *   }
   * }
   * ```
   *
   * You can also pass a 4th optional $root-selector argument which will be forwarded to `mdc-rtl`,
   * e.g. `@include mdc-rtl-reflexive-box(margin, left, 8px, ".mdc-component")`.
   *
   * Note that this function will always zero out the original value in an RTL context. If you're
   * trying to flip the values, use mdc-rtl-reflexive-property().
   */
  /**
   * Takes a base property and emits rules that assign <base-property>-left to <left-value> and
   * <base-property>-right to <right-value> in a LTR context, and vice versa in a RTL context.
   * For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-property(margin, auto, 12px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-left: auto;
   *   margin-right: 12px;
   *
   *   @include mdc-rtl {
   *     margin-left: 12px;
   *     margin-right: auto;
   *   }
   * }
   * ```
   *
   * A 4th optional $root-selector argument can be given, which will be passed to `mdc-rtl`.
   */
  /**
   * Takes an argument specifying a horizontal position property (either "left" or "right") as well
   * as a value, and applies that value to the specified position in a LTR context, and flips it in a
   * RTL context. For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-position(left, 0);
   *   position: absolute;
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   *  .mdc-foo {
   *    position: absolute;
   *    left: 0;
   *    right: initial;
   *
   *    @include mdc-rtl {
   *      right: 0;
   *      left: initial;
   *    }
   *  }
   * ```
   * An optional third $root-selector argument may also be given, which is passed to `mdc-rtl`.
   */
  /* TODO(sgomes): Figure out what to do about desktop font sizes. */
  /* TODO(sgomes): Figure out what to do about i18n and i18n font sizes. */
  :root {
    --mdc-permanent-drawer-dark-theme-bg-color: #212121; }

  .mdc-drawer--permanent {
    /* Use aspect ratio trick to maintain 16:9 aspect ratio on the header */
    color: rgba(0, 0, 0, 0.87);
    border-left: 0;
    border-right: 1px solid #e4e4e4;
    left: 0;
    right: initial;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    box-sizing: border-box;
    width: 240px;
    overflow: hidden; }
    .mdc-drawer--permanent .mdc-drawer__toolbar-spacer {
      display: flex;
      position: relative;
      flex-direction: row;
      flex-shrink: 0;
      align-items: center;
      box-sizing: border-box;
      height: 56px;
      padding: 16px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      /* TODO(sgomes): replace with global breakpoints when we have them */ }
      .mdc-drawer--permanent .mdc-drawer__toolbar-spacer--theme-dark .mdc-drawer--permanent .mdc-drawer__toolbar-spacer,
      .mdc-theme--dark .mdc-drawer--permanent .mdc-drawer__toolbar-spacer {
        border-bottom: 1px solid rgba(255, 255, 255, 0.12); }
      @media (min-width: 600px) {
        .mdc-drawer--permanent .mdc-drawer__toolbar-spacer {
          height: 64px; } }
    .mdc-drawer--permanent .mdc-drawer__header {
      position: relative; }
    .mdc-drawer--permanent .mdc-drawer__header::before {
      display: block;
      padding-top: 56.25%;
      content: ""; }
    .mdc-drawer--permanent .mdc-drawer__header-content {
      display: flex;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      align-items: flex-end;
      box-sizing: border-box;
      padding: 16px; }
    .mdc-drawer--permanent .mdc-list-item {
      font-family: Roboto, sans-serif;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-size: 0.875rem;
      line-height: 1.5rem;
      font-weight: 500;
      letter-spacing: 0.04em;
      text-decoration: inherit;
      text-transform: inherit;
      position: relative;
      outline: none;
      color: inherit;
      text-decoration: none; }
    .mdc-drawer--permanent .mdc-list-item__graphic {
      color: rgba(0, 0, 0, 0.54); }
      .mdc-drawer--permanent .mdc-list-item__graphic--theme-dark .mdc-drawer--permanent .mdc-list-item__graphic,
      .mdc-theme--dark .mdc-drawer--permanent .mdc-list-item__graphic {
        color: rgba(255, 255, 255, 0.54); }
    .mdc-drawer--permanent.mdc-drawer--permanent,
    .mdc-drawer--permanent .mdc-drawer__drawer {
      background-color: #fff; }
    [dir="rtl"] .mdc-drawer--permanent, .mdc-drawer--permanent[dir="rtl"] {
      border-left: 1px solid #e4e4e4;
      border-right: 0; }
    [dir="rtl"] .mdc-drawer--permanent, .mdc-drawer--permanent[dir="rtl"] {
      left: initial;
      right: 0; }
    .mdc-drawer--permanent--theme-dark,
    .mdc-theme--dark .mdc-drawer--permanent {
      /* @alternate */
      color: white;
      color: var(--mdc-theme-text-primary-on-dark, white);
      border-left: 0;
      border-right: 1px solid rgba(255, 255, 255, 0.12);
      background-color: #212121;
      background-color: var(--mdc-permanent-drawer-dark-theme-bg-color, #212121); }
      [dir="rtl"] .mdc-drawer--permanent--theme-dark, .mdc-drawer--permanent--theme-dark[dir="rtl"], [dir="rtl"]
      .mdc-theme--dark .mdc-drawer--permanent,
      .mdc-theme--dark .mdc-drawer--permanent[dir="rtl"] {
        border-left: 1px solid rgba(255, 255, 255, 0.12);
        border-right: 0; }
    .mdc-drawer--permanent--floating {
      border-left: 0;
      border-right: none;
      background: none; }
      [dir="rtl"] .mdc-drawer--permanent--floating, .mdc-drawer--permanent--floating[dir="rtl"] {
        border-left: none;
        border-right: 0; }
      .mdc-drawer--permanent--floating--theme-dark,
      .mdc-theme--dark .mdc-drawer--permanent--floating {
        border-left: 0;
        border-right: none;
        background: none; }
        [dir="rtl"] .mdc-drawer--permanent--floating--theme-dark, .mdc-drawer--permanent--floating--theme-dark[dir="rtl"], [dir="rtl"]
        .mdc-theme--dark .mdc-drawer--permanent--floating,
        .mdc-theme--dark .mdc-drawer--permanent--floating[dir="rtl"] {
          border-left: none;
          border-right: 0; }

  /**
   * The css property used for elevation. In most cases this should not be changed. It is exposed
   * as a variable for abstraction / easy use when needing to reference the property directly, for
   * example in a `will-change` rule.
   */
  /**
   * The default duration value for elevation transitions.
   */
  /**
   * The default easing value for elevation transitions.
   */
  /**
   * Applies the correct CSS rules to an element to give it the elevation specified by $z-value.
   * The $z-value must be between 0 and 24.
   * If $color has an alpha channel, it will be ignored and overridden. To increase the opacity of the shadow, use
   * $opacity-boost.
   */
  /**
   * Returns a string that can be used as the value for a `transition` property for elevation.
   * Calling this function directly is useful in situations where a component needs to transition
   * more than one property.
   *
   * ```scss
   * .foo {
   *   transition: mdc-elevation-transition-value(), opacity 100ms ease;
   *   will-change: $mdc-elevation-property, opacity;
   * }
   * ```
   */
  /**
   * Creates a rule that will be applied when an MDC-Web component is within the context of an RTL layout.
   *
   * Usage Example:
   * ```scss
   * .mdc-foo {
   *   position: absolute;
   *   left: 0;
   *
   *   @include mdc-rtl {
   *     left: auto;
   *     right: 0;
   *   }
   *
   *   &__bar {
   *     margin-left: 4px;
   *     @include mdc-rtl(".mdc-foo") {
   *       margin-left: auto;
   *       margin-right: 4px;
   *     }
   *   }
   * }
   *
   * .mdc-foo--mod {
   *   padding-left: 4px;
   *
   *   @include mdc-rtl {
   *     padding-left: auto;
   *     padding-right: 4px;
   *   }
   * }
   * ```
   *
   * Note that this works by checking for [dir="rtl"] on an ancestor element. While this will work
   * in most cases, it will in some cases lead to false negatives, e.g.
   *
   * ```html
   * <html dir="rtl">
   *   <!-- ... -->
   *   <div dir="ltr">
   *     <div class="mdc-foo">Styled incorrectly as RTL!</div>
   *   </div>
   * </html>
   * ```
   *
   * In the future, selectors such as :dir (http://mdn.io/:dir) will help us mitigate this.
   */
  /**
   * Takes a base box-model property - e.g. margin / border / padding - along with a default
   * direction and value, and emits rules which apply the value to the
   * "<base-property>-<default-direction>" property by default, but flips the direction
   * when within an RTL context.
   *
   * For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-box(margin, left, 8px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-left: 8px;
   *
   *   @include mdc-rtl {
   *     margin-right: 8px;
   *     margin-left: 0;
   *   }
   * }
   * ```
   * whereas:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-box(margin, right, 8px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-right: 8px;
   *
   *   @include mdc-rtl {
   *     margin-right: 0;
   *     margin-left: 8px;
   *   }
   * }
   * ```
   *
   * You can also pass a 4th optional $root-selector argument which will be forwarded to `mdc-rtl`,
   * e.g. `@include mdc-rtl-reflexive-box(margin, left, 8px, ".mdc-component")`.
   *
   * Note that this function will always zero out the original value in an RTL context. If you're
   * trying to flip the values, use mdc-rtl-reflexive-property().
   */
  /**
   * Takes a base property and emits rules that assign <base-property>-left to <left-value> and
   * <base-property>-right to <right-value> in a LTR context, and vice versa in a RTL context.
   * For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-property(margin, auto, 12px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-left: auto;
   *   margin-right: 12px;
   *
   *   @include mdc-rtl {
   *     margin-left: 12px;
   *     margin-right: auto;
   *   }
   * }
   * ```
   *
   * A 4th optional $root-selector argument can be given, which will be passed to `mdc-rtl`.
   */
  /**
   * Takes an argument specifying a horizontal position property (either "left" or "right") as well
   * as a value, and applies that value to the specified position in a LTR context, and flips it in a
   * RTL context. For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-position(left, 0);
   *   position: absolute;
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   *  .mdc-foo {
   *    position: absolute;
   *    left: 0;
   *    right: initial;
   *
   *    @include mdc-rtl {
   *      right: 0;
   *      left: initial;
   *    }
   *  }
   * ```
   * An optional third $root-selector argument may also be given, which is passed to `mdc-rtl`.
   */
  /* TODO(sgomes): Figure out what to do about desktop font sizes. */
  /* TODO(sgomes): Figure out what to do about i18n and i18n font sizes. */
  /**
   * Creates a rule that will be applied when an MDC-Web component is within the context of an RTL layout.
   *
   * Usage Example:
   * ```scss
   * .mdc-foo {
   *   position: absolute;
   *   left: 0;
   *
   *   @include mdc-rtl {
   *     left: auto;
   *     right: 0;
   *   }
   *
   *   &__bar {
   *     margin-left: 4px;
   *     @include mdc-rtl(".mdc-foo") {
   *       margin-left: auto;
   *       margin-right: 4px;
   *     }
   *   }
   * }
   *
   * .mdc-foo--mod {
   *   padding-left: 4px;
   *
   *   @include mdc-rtl {
   *     padding-left: auto;
   *     padding-right: 4px;
   *   }
   * }
   * ```
   *
   * Note that this works by checking for [dir="rtl"] on an ancestor element. While this will work
   * in most cases, it will in some cases lead to false negatives, e.g.
   *
   * ```html
   * <html dir="rtl">
   *   <!-- ... -->
   *   <div dir="ltr">
   *     <div class="mdc-foo">Styled incorrectly as RTL!</div>
   *   </div>
   * </html>
   * ```
   *
   * In the future, selectors such as :dir (http://mdn.io/:dir) will help us mitigate this.
   */
  /**
   * Takes a base box-model property - e.g. margin / border / padding - along with a default
   * direction and value, and emits rules which apply the value to the
   * "<base-property>-<default-direction>" property by default, but flips the direction
   * when within an RTL context.
   *
   * For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-box(margin, left, 8px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-left: 8px;
   *
   *   @include mdc-rtl {
   *     margin-right: 8px;
   *     margin-left: 0;
   *   }
   * }
   * ```
   * whereas:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-box(margin, right, 8px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-right: 8px;
   *
   *   @include mdc-rtl {
   *     margin-right: 0;
   *     margin-left: 8px;
   *   }
   * }
   * ```
   *
   * You can also pass a 4th optional $root-selector argument which will be forwarded to `mdc-rtl`,
   * e.g. `@include mdc-rtl-reflexive-box(margin, left, 8px, ".mdc-component")`.
   *
   * Note that this function will always zero out the original value in an RTL context. If you're
   * trying to flip the values, use mdc-rtl-reflexive-property().
   */
  /**
   * Takes a base property and emits rules that assign <base-property>-left to <left-value> and
   * <base-property>-right to <right-value> in a LTR context, and vice versa in a RTL context.
   * For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-property(margin, auto, 12px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-left: auto;
   *   margin-right: 12px;
   *
   *   @include mdc-rtl {
   *     margin-left: 12px;
   *     margin-right: auto;
   *   }
   * }
   * ```
   *
   * A 4th optional $root-selector argument can be given, which will be passed to `mdc-rtl`.
   */
  /**
   * Takes an argument specifying a horizontal position property (either "left" or "right") as well
   * as a value, and applies that value to the specified position in a LTR context, and flips it in a
   * RTL context. For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-position(left, 0);
   *   position: absolute;
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   *  .mdc-foo {
   *    position: absolute;
   *    left: 0;
   *    right: initial;
   *
   *    @include mdc-rtl {
   *      right: 0;
   *      left: initial;
   *    }
   *  }
   * ```
   * An optional third $root-selector argument may also be given, which is passed to `mdc-rtl`.
   */
  .mdc-drawer--temporary {
    /* Use aspect ratio trick to maintain 16:9 aspect ratio on the header */
    color: rgba(0, 0, 0, 0.87);
    position: fixed;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    pointer-events: none;
    overflow: hidden;
    contain: strict;
    z-index: 5;
    /* Shaded background */ }
    .mdc-drawer--temporary .mdc-drawer__toolbar-spacer {
      display: flex;
      position: relative;
      flex-direction: row;
      flex-shrink: 0;
      align-items: center;
      box-sizing: border-box;
      height: 56px;
      padding: 16px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      /* TODO(sgomes): replace with global breakpoints when we have them */ }
      .mdc-drawer--temporary .mdc-drawer__toolbar-spacer--theme-dark .mdc-drawer--temporary .mdc-drawer__toolbar-spacer,
      .mdc-theme--dark .mdc-drawer--temporary .mdc-drawer__toolbar-spacer {
        border-bottom: 1px solid rgba(255, 255, 255, 0.12); }
      @media (min-width: 600px) {
        .mdc-drawer--temporary .mdc-drawer__toolbar-spacer {
          height: 64px; } }
    .mdc-drawer--temporary .mdc-drawer__header {
      position: relative; }
    .mdc-drawer--temporary .mdc-drawer__header::before {
      display: block;
      padding-top: 56.25%;
      content: ""; }
    .mdc-drawer--temporary .mdc-drawer__header-content {
      display: flex;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      align-items: flex-end;
      box-sizing: border-box;
      padding: 16px; }
    .mdc-drawer--temporary .mdc-list-item {
      font-family: Roboto, sans-serif;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-size: 0.875rem;
      line-height: 1.5rem;
      font-weight: 500;
      letter-spacing: 0.04em;
      text-decoration: inherit;
      text-transform: inherit;
      position: relative;
      outline: none;
      color: inherit;
      text-decoration: none; }
    .mdc-drawer--temporary .mdc-list-item__graphic {
      color: rgba(0, 0, 0, 0.54); }
      .mdc-drawer--temporary .mdc-list-item__graphic--theme-dark .mdc-drawer--temporary .mdc-list-item__graphic,
      .mdc-theme--dark .mdc-drawer--temporary .mdc-list-item__graphic {
        color: rgba(255, 255, 255, 0.54); }
    .mdc-drawer--temporary.mdc-drawer--permanent,
    .mdc-drawer--temporary .mdc-drawer__drawer {
      background-color: #fff; }
    .mdc-drawer--temporary::before {
      background-color: rgba(0, 0, 0, 0.6); }
    .mdc-drawer--temporary::before {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      opacity: 0;
      opacity: var(--mdc-temporary-drawer-opacity, 0);
      content: "";
      will-change: opacity; }
    .mdc-drawer--temporary .mdc-drawer__drawer {
      box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
      left: 0;
      right: initial;
      height: 100%;
      transform: translateX(-107%);
      transform: translateX(calc(-100% - 20px));
      will-change: transform;
      display: flex;
      position: absolute;
      flex-direction: column;
      box-sizing: border-box;
      width: calc(100% - 56px);
      max-width: 280px;
      overflow: hidden;
      touch-action: none;
      /* TODO(sgomes): replace with global breakpoints when we have them */ }
      [dir="rtl"] .mdc-drawer--temporary .mdc-drawer__drawer, .mdc-drawer--temporary .mdc-drawer__drawer[dir="rtl"] {
        left: initial;
        right: 0; }
      .mdc-drawer--temporary--theme-dark .mdc-drawer--temporary .mdc-drawer__drawer,
      .mdc-theme--dark .mdc-drawer--temporary .mdc-drawer__drawer {
        /* @alternate */
        color: white;
        color: var(--mdc-theme-text-primary-on-dark, white);
        background: #303030; }
      [dir="rtl"] .mdc-drawer--temporary .mdc-drawer--temporary .mdc-drawer__drawer,
      .mdc-drawer--temporary[dir="rtl"] .mdc-drawer--temporary .mdc-drawer__drawer {
        transform: translateX(107%);
        transform: translateX(calc(100% + 20px)); }
      @media (min-width: 600px) {
        .mdc-drawer--temporary .mdc-drawer__drawer {
          width: calc(100% - 64px);
          max-width: 320px; } }
    .mdc-drawer--temporary .mdc-drawer__content {
      flex-grow: 1;
      box-sizing: border-box;
      margin: 0;
      overflow-x: hidden;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      touch-action: pan-y; }
    .mdc-drawer--temporary .mdc-drawer__footer {
      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
      flex-shrink: 0; }
    .mdc-drawer--temporary.mdc-drawer--animating::before {
      transition: opacity 0.3s 0ms cubic-bezier(0, 0, 0.2, 1); }
    .mdc-drawer--temporary.mdc-drawer--animating.mdc-drawer--open .mdc-drawer__drawer {
      transition: transform 0.225s 0ms cubic-bezier(0, 0, 0.2, 1); }
    .mdc-drawer--temporary.mdc-drawer--animating .mdc-drawer__drawer {
      transition: transform 0.195s 0ms cubic-bezier(0.4, 0, 0.6, 1); }
    .mdc-drawer--temporary.mdc-drawer--open {
      pointer-events: auto; }
      .mdc-drawer--temporary.mdc-drawer--open::before {
        opacity: 1;
        opacity: var(--mdc-temporary-drawer-opacity, 1); }
      .mdc-drawer--temporary.mdc-drawer--open .mdc-drawer__drawer {
        transform: none; }
      [dir="rtl"] .mdc-drawer--temporary.mdc-drawer--open .mdc-drawer__drawer, .mdc-drawer--temporary.mdc-drawer--open[dir="rtl"] .mdc-drawer__drawer {
        transform: none; }

  .mdc-drawer-scroll-lock {
    overflow: hidden; }

  /**
   * The css property used for elevation. In most cases this should not be changed. It is exposed
   * as a variable for abstraction / easy use when needing to reference the property directly, for
   * example in a `will-change` rule.
   */
  /**
   * The default duration value for elevation transitions.
   */
  /**
   * The default easing value for elevation transitions.
   */
  /**
   * Applies the correct CSS rules to an element to give it the elevation specified by $z-value.
   * The $z-value must be between 0 and 24.
   * If $color has an alpha channel, it will be ignored and overridden. To increase the opacity of the shadow, use
   * $opacity-boost.
   */
  /**
   * Returns a string that can be used as the value for a `transition` property for elevation.
   * Calling this function directly is useful in situations where a component needs to transition
   * more than one property.
   *
   * ```scss
   * .foo {
   *   transition: mdc-elevation-transition-value(), opacity 100ms ease;
   *   will-change: $mdc-elevation-property, opacity;
   * }
   * ```
   */
  .mdc-elevation--z0 {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12); }

  .mdc-elevation--z1 {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12); }

  .mdc-elevation--z2 {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); }

  .mdc-elevation--z3 {
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12); }

  .mdc-elevation--z4 {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }

  .mdc-elevation--z5 {
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12); }

  .mdc-elevation--z6 {
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12); }

  .mdc-elevation--z7 {
    box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12); }

  .mdc-elevation--z8 {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12); }

  .mdc-elevation--z9 {
    box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12); }

  .mdc-elevation--z10 {
    box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12); }

  .mdc-elevation--z11 {
    box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12); }

  .mdc-elevation--z12 {
    box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12); }

  .mdc-elevation--z13 {
    box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12); }

  .mdc-elevation--z14 {
    box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12); }

  .mdc-elevation--z15 {
    box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12); }

  .mdc-elevation--z16 {
    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12); }

  .mdc-elevation--z17 {
    box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12); }

  .mdc-elevation--z18 {
    box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12); }

  .mdc-elevation--z19 {
    box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12); }

  .mdc-elevation--z20 {
    box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12); }

  .mdc-elevation--z21 {
    box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12); }

  .mdc-elevation--z22 {
    box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12); }

  .mdc-elevation--z23 {
    box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12); }

  .mdc-elevation--z24 {
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12); }

  .mdc-elevation-transition {
    transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
    will-change: box-shadow; }

  /**
   * The css property used for elevation. In most cases this should not be changed. It is exposed
   * as a variable for abstraction / easy use when needing to reference the property directly, for
   * example in a `will-change` rule.
   */
  /**
   * The default duration value for elevation transitions.
   */
  /**
   * The default easing value for elevation transitions.
   */
  /**
   * Applies the correct CSS rules to an element to give it the elevation specified by $z-value.
   * The $z-value must be between 0 and 24.
   * If $color has an alpha channel, it will be ignored and overridden. To increase the opacity of the shadow, use
   * $opacity-boost.
   */
  /**
   * Returns a string that can be used as the value for a `transition` property for elevation.
   * Calling this function directly is useful in situations where a component needs to transition
   * more than one property.
   *
   * ```scss
   * .foo {
   *   transition: mdc-elevation-transition-value(), opacity 100ms ease;
   *   will-change: $mdc-elevation-property, opacity;
   * }
   * ```
   */
  /**
   * The css property used for elevation. In most cases this should not be changed. It is exposed
   * as a variable for abstraction / easy use when needing to reference the property directly, for
   * example in a `will-change` rule.
   */
  /**
   * The default duration value for elevation transitions.
   */
  /**
   * The default easing value for elevation transitions.
   */
  .mdc-fab {
    --mdc-ripple-fg-size: 0;
    --mdc-ripple-left: 0;
    --mdc-ripple-top: 0;
    --mdc-ripple-fg-scale: 1;
    --mdc-ripple-fg-translate-end: 0;
    --mdc-ripple-fg-translate-start: 0;
    -webkit-tap-highlight-color: transparent;
    will-change: transform, opacity;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    display: inline-flex;
    position: relative;
    justify-content: center;
    box-sizing: border-box;
    width: 56px;
    height: 56px;
    padding: 0;
    transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), opacity 15ms linear 30ms, transform 270ms 0ms cubic-bezier(0, 0, 0.2, 1);
    border: none;
    border-radius: 50%;
    fill: currentColor;
    cursor: pointer;
    user-select: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    overflow: hidden;
    /* @alternate */
    background-color: #018786;
    /* @alternate */
    color: white;
    color: var(--mdc-theme-text-primary-on-secondary, white); }
    .mdc-fab::before, .mdc-fab::after {
      position: absolute;
      border-radius: 50%;
      opacity: 0;
      pointer-events: none;
      content: ""; }
    .mdc-fab::before {
      transition: opacity 15ms linear; }
    .mdc-fab.mdc-ripple-upgraded::after {
      top: 0;
      left: 0;
      transform: scale(0);
      transform-origin: center center; }
    .mdc-fab.mdc-ripple-upgraded--unbounded::after {
      top: var(--mdc-ripple-top, 0);
      left: var(--mdc-ripple-left, 0); }
    .mdc-fab.mdc-ripple-upgraded--foreground-activation::after {
      animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards; }
    .mdc-fab.mdc-ripple-upgraded--foreground-deactivation::after {
      animation: 150ms mdc-ripple-fg-opacity-out;
      transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
    .mdc-fab::before, .mdc-fab::after {
      top: calc(50% - 100%);
      left: calc(50% - 100%);
      width: 200%;
      height: 200%; }
    .mdc-fab.mdc-ripple-upgraded::before {
      top: calc(50% - 100%);
      left: calc(50% - 100%);
      width: 200%;
      height: 200%;
      transform: scale(var(--mdc-ripple-fg-scale, 0)); }
    .mdc-fab.mdc-ripple-upgraded--unbounded::before {
      top: var(--mdc-ripple-top, calc(50% - 50%));
      left: var(--mdc-ripple-left, calc(50% - 50%));
      width: var(--mdc-ripple-fg-size, 100%);
      height: var(--mdc-ripple-fg-size, 100%);
      transform: scale(var(--mdc-ripple-fg-scale, 0)); }
    .mdc-fab.mdc-ripple-upgraded::after {
      width: var(--mdc-ripple-fg-size, 100%);
      height: var(--mdc-ripple-fg-size, 100%); }
    .mdc-fab:hover, .mdc-fab:focus {
      box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12); }
    .mdc-fab:active {
      box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12); }
    .mdc-fab:active, .mdc-fab:focus {
      outline: none; }
    .mdc-fab:hover {
      cursor: pointer; }
    .mdc-fab::-moz-focus-inner {
      padding: 0;
      border: 0; }
    .mdc-fab > svg {
      width: 100%; }
    @supports not (-ms-ime-align: auto) {
      .mdc-fab {
        background-color: var(--mdc-theme-secondary, #018786); } }
    .mdc-fab::before, .mdc-fab::after {
      /* @alternate */
      background-color: white; }
      @supports not (-ms-ime-align: auto) {
        .mdc-fab::before, .mdc-fab::after {
          background-color: var(--mdc-theme-text-primary-on-secondary, white); } }
    .mdc-fab:hover::before {
      opacity: 0.08; }
    .mdc-fab:not(.mdc-ripple-upgraded):focus::before, .mdc-fab.mdc-ripple-upgraded--background-focused::before {
      transition-duration: 75ms;
      opacity: 0.24; }
    .mdc-fab:not(.mdc-ripple-upgraded)::after {
      transition: opacity 150ms linear; }
    .mdc-fab:not(.mdc-ripple-upgraded):active::after {
      transition-duration: 75ms;
      opacity: 0.32; }
    .mdc-fab.mdc-ripple-upgraded {
      --mdc-ripple-fg-opacity: 0.32; }

  .mdc-fab--mini {
    width: 40px;
    height: 40px; }

  .mdc-fab__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    transition: transform 180ms 90ms cubic-bezier(0, 0, 0.2, 1);
    will-change: transform; }

  .mdc-fab--exited {
    transform: scale(0);
    transition: opacity 15ms linear 150ms, transform 180ms 0ms cubic-bezier(0.4, 0, 1, 1);
    opacity: 0; }
    .mdc-fab--exited .mdc-fab__icon {
      transform: scale(0);
      transition: transform 135ms 0ms cubic-bezier(0.4, 0, 1, 1); }

  /**
   * Creates a rule that will be applied when an MDC-Web component is within the context of an RTL layout.
   *
   * Usage Example:
   * ```scss
   * .mdc-foo {
   *   position: absolute;
   *   left: 0;
   *
   *   @include mdc-rtl {
   *     left: auto;
   *     right: 0;
   *   }
   *
   *   &__bar {
   *     margin-left: 4px;
   *     @include mdc-rtl(".mdc-foo") {
   *       margin-left: auto;
   *       margin-right: 4px;
   *     }
   *   }
   * }
   *
   * .mdc-foo--mod {
   *   padding-left: 4px;
   *
   *   @include mdc-rtl {
   *     padding-left: auto;
   *     padding-right: 4px;
   *   }
   * }
   * ```
   *
   * Note that this works by checking for [dir="rtl"] on an ancestor element. While this will work
   * in most cases, it will in some cases lead to false negatives, e.g.
   *
   * ```html
   * <html dir="rtl">
   *   <!-- ... -->
   *   <div dir="ltr">
   *     <div class="mdc-foo">Styled incorrectly as RTL!</div>
   *   </div>
   * </html>
   * ```
   *
   * In the future, selectors such as :dir (http://mdn.io/:dir) will help us mitigate this.
   */
  /**
   * Takes a base box-model property - e.g. margin / border / padding - along with a default
   * direction and value, and emits rules which apply the value to the
   * "<base-property>-<default-direction>" property by default, but flips the direction
   * when within an RTL context.
   *
   * For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-box(margin, left, 8px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-left: 8px;
   *
   *   @include mdc-rtl {
   *     margin-right: 8px;
   *     margin-left: 0;
   *   }
   * }
   * ```
   * whereas:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-box(margin, right, 8px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-right: 8px;
   *
   *   @include mdc-rtl {
   *     margin-right: 0;
   *     margin-left: 8px;
   *   }
   * }
   * ```
   *
   * You can also pass a 4th optional $root-selector argument which will be forwarded to `mdc-rtl`,
   * e.g. `@include mdc-rtl-reflexive-box(margin, left, 8px, ".mdc-component")`.
   *
   * Note that this function will always zero out the original value in an RTL context. If you're
   * trying to flip the values, use mdc-rtl-reflexive-property().
   */
  /**
   * Takes a base property and emits rules that assign <base-property>-left to <left-value> and
   * <base-property>-right to <right-value> in a LTR context, and vice versa in a RTL context.
   * For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-property(margin, auto, 12px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-left: auto;
   *   margin-right: 12px;
   *
   *   @include mdc-rtl {
   *     margin-left: 12px;
   *     margin-right: auto;
   *   }
   * }
   * ```
   *
   * A 4th optional $root-selector argument can be given, which will be passed to `mdc-rtl`.
   */
  /**
   * Takes an argument specifying a horizontal position property (either "left" or "right") as well
   * as a value, and applies that value to the specified position in a LTR context, and flips it in a
   * RTL context. For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-position(left, 0);
   *   position: absolute;
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   *  .mdc-foo {
   *    position: absolute;
   *    left: 0;
   *    right: initial;
   *
   *    @include mdc-rtl {
   *      right: 0;
   *      left: initial;
   *    }
   *  }
   * ```
   * An optional third $root-selector argument may also be given, which is passed to `mdc-rtl`.
   */
  /* TODO(sgomes): Figure out what to do about desktop font sizes. */
  /* TODO(sgomes): Figure out what to do about i18n and i18n font sizes. */
  /* stylelint-disable selector-max-type */
  .mdc-form-field {
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 400;
    letter-spacing: 0.04em;
    text-decoration: inherit;
    text-transform: inherit;
    /* @alternate */
    color: rgba(0, 0, 0, 0.87);
    color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87));
    display: inline-flex;
    align-items: center;
    vertical-align: middle; }
    .mdc-form-field--theme-dark,
    .mdc-theme--dark .mdc-form-field {
      /* @alternate */
      color: white;
      color: var(--mdc-theme-text-primary-on-dark, white); }
    .mdc-form-field > label {
      order: 0;
      margin-right: auto;
      padding-left: 4px; }
    [dir="rtl"] .mdc-form-field > label, .mdc-form-field[dir="rtl"] > label {
      margin-left: auto;
      padding-right: 4px; }

  .mdc-form-field--align-end > label {
    order: -1;
    margin-left: auto;
    padding-right: 4px; }

  [dir="rtl"] .mdc-form-field--align-end > label, .mdc-form-field--align-end[dir="rtl"] > label {
    margin-right: auto;
    padding-left: 4px; }

  /* stylelint-enable selector-max-type */
  /**
   * Creates a rule that will be applied when an MDC-Web component is within the context of an RTL layout.
   *
   * Usage Example:
   * ```scss
   * .mdc-foo {
   *   position: absolute;
   *   left: 0;
   *
   *   @include mdc-rtl {
   *     left: auto;
   *     right: 0;
   *   }
   *
   *   &__bar {
   *     margin-left: 4px;
   *     @include mdc-rtl(".mdc-foo") {
   *       margin-left: auto;
   *       margin-right: 4px;
   *     }
   *   }
   * }
   *
   * .mdc-foo--mod {
   *   padding-left: 4px;
   *
   *   @include mdc-rtl {
   *     padding-left: auto;
   *     padding-right: 4px;
   *   }
   * }
   * ```
   *
   * Note that this works by checking for [dir="rtl"] on an ancestor element. While this will work
   * in most cases, it will in some cases lead to false negatives, e.g.
   *
   * ```html
   * <html dir="rtl">
   *   <!-- ... -->
   *   <div dir="ltr">
   *     <div class="mdc-foo">Styled incorrectly as RTL!</div>
   *   </div>
   * </html>
   * ```
   *
   * In the future, selectors such as :dir (http://mdn.io/:dir) will help us mitigate this.
   */
  /**
   * Takes a base box-model property - e.g. margin / border / padding - along with a default
   * direction and value, and emits rules which apply the value to the
   * "<base-property>-<default-direction>" property by default, but flips the direction
   * when within an RTL context.
   *
   * For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-box(margin, left, 8px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-left: 8px;
   *
   *   @include mdc-rtl {
   *     margin-right: 8px;
   *     margin-left: 0;
   *   }
   * }
   * ```
   * whereas:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-box(margin, right, 8px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-right: 8px;
   *
   *   @include mdc-rtl {
   *     margin-right: 0;
   *     margin-left: 8px;
   *   }
   * }
   * ```
   *
   * You can also pass a 4th optional $root-selector argument which will be forwarded to `mdc-rtl`,
   * e.g. `@include mdc-rtl-reflexive-box(margin, left, 8px, ".mdc-component")`.
   *
   * Note that this function will always zero out the original value in an RTL context. If you're
   * trying to flip the values, use mdc-rtl-reflexive-property().
   */
  /**
   * Takes a base property and emits rules that assign <base-property>-left to <left-value> and
   * <base-property>-right to <right-value> in a LTR context, and vice versa in a RTL context.
   * For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-property(margin, auto, 12px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-left: auto;
   *   margin-right: 12px;
   *
   *   @include mdc-rtl {
   *     margin-left: 12px;
   *     margin-right: auto;
   *   }
   * }
   * ```
   *
   * A 4th optional $root-selector argument can be given, which will be passed to `mdc-rtl`.
   */
  /**
   * Takes an argument specifying a horizontal position property (either "left" or "right") as well
   * as a value, and applies that value to the specified position in a LTR context, and flips it in a
   * RTL context. For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-position(left, 0);
   *   position: absolute;
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   *  .mdc-foo {
   *    position: absolute;
   *    left: 0;
   *    right: initial;
   *
   *    @include mdc-rtl {
   *      right: 0;
   *      left: initial;
   *    }
   *  }
   * ```
   * An optional third $root-selector argument may also be given, which is passed to `mdc-rtl`.
   */
  /* TODO(sgomes): Figure out what to do about desktop font sizes. */
  /* TODO(sgomes): Figure out what to do about i18n and i18n font sizes. */
  .mdc-grid-list .mdc-grid-tile__primary {
    padding-bottom: calc(100% / 1); }

  .mdc-grid-list .mdc-grid-tile {
    margin: 2px 0;
    padding: 0 2px; }
    .mdc-grid-list .mdc-grid-tile__secondary {
      left: 2px;
      width: calc(100% - 4px); }

  .mdc-grid-list .mdc-grid-list__tiles {
    margin: 2px auto; }

  .mdc-grid-list__tiles {
    display: flex;
    flex-flow: row wrap;
    margin: 0;
    padding: 0; }

  .mdc-grid-list--tile-gutter-1 .mdc-grid-tile {
    margin: 0.5px 0;
    padding: 0 0.5px; }
    .mdc-grid-list--tile-gutter-1 .mdc-grid-tile__secondary {
      left: 0.5px;
      width: calc(100% - 1px); }

  .mdc-grid-list--tile-gutter-1 .mdc-grid-list__tiles {
    margin: 0.5px auto; }

  .mdc-grid-list--tile-aspect-16x9 .mdc-grid-tile__primary {
    padding-bottom: calc(100% / 1.77778); }

  .mdc-grid-list--tile-aspect-3x2 .mdc-grid-tile__primary {
    padding-bottom: calc(100% / 1.5); }

  .mdc-grid-list--tile-aspect-2x3 .mdc-grid-tile__primary {
    padding-bottom: calc(100% / 0.66667); }

  .mdc-grid-list--tile-aspect-4x3 .mdc-grid-tile__primary {
    padding-bottom: calc(100% / 1.33333); }

  .mdc-grid-list--tile-aspect-3x4 .mdc-grid-tile__primary {
    padding-bottom: calc(100% / 0.75); }

  .mdc-grid-list--twoline-caption .mdc-grid-tile__secondary {
    height: 68px; }

  .mdc-grid-list--header-caption .mdc-grid-tile__secondary {
    top: 0;
    bottom: auto; }

  .mdc-grid-list--with-icon-align-start .mdc-grid-tile__secondary {
    padding-left: 56px;
    padding-right: 8px; }
    [dir="rtl"] .mdc-grid-list .mdc-grid-list--with-icon-align-start .mdc-grid-tile__secondary,
    .mdc-grid-list[dir="rtl"] .mdc-grid-list--with-icon-align-start .mdc-grid-tile__secondary {
      padding-left: 8px;
      padding-right: 56px; }

  .mdc-grid-list--with-icon-align-start .mdc-grid-tile__icon {
    left: 16px;
    right: initial;
    font-size: 24px; }
    [dir="rtl"] .mdc-grid-list .mdc-grid-list--with-icon-align-start .mdc-grid-tile__icon,
    .mdc-grid-list[dir="rtl"] .mdc-grid-list--with-icon-align-start .mdc-grid-tile__icon {
      left: initial;
      right: 16px; }

  .mdc-grid-list--with-icon-align-end .mdc-grid-tile__secondary {
    padding-left: 16px;
    padding-right: 56px; }
    [dir="rtl"] .mdc-grid-list .mdc-grid-list--with-icon-align-end .mdc-grid-tile__secondary,
    .mdc-grid-list[dir="rtl"] .mdc-grid-list--with-icon-align-end .mdc-grid-tile__secondary {
      padding-left: 56px;
      padding-right: 16px; }

  .mdc-grid-list--with-icon-align-end .mdc-grid-tile__icon {
    left: initial;
    right: 16px;
    font-size: 24px; }
    [dir="rtl"] .mdc-grid-list .mdc-grid-list--with-icon-align-end .mdc-grid-tile__icon,
    .mdc-grid-list[dir="rtl"] .mdc-grid-list--with-icon-align-end .mdc-grid-tile__icon {
      left: 16px;
      right: initial; }

  .mdc-grid-tile {
    display: block;
    position: relative;
    /* @alternate */
    width: 200px;
    width: var(--mdc-grid-list-tile-width, 200px); }
    .mdc-grid-tile__primary {
      /* @alternate */
      background-color: #fff;
      background-color: var(--mdc-theme-background, #fff);
      /* @alternate */
      color: rgba(0, 0, 0, 0.87);
      color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
      position: relative;
      height: 0; }
      .mdc-grid-tile__primary-content {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover; }
    .mdc-grid-tile__secondary {
      /* @alternate */
      background-color: #6200ee;
      background-color: var(--mdc-theme-primary, #6200ee);
      /* @alternate */
      color: white;
      color: var(--mdc-theme-text-primary-on-primary, white);
      position: absolute;
      bottom: 0;
      box-sizing: border-box;
      height: 48px;
      padding: 16px; }
    .mdc-grid-tile__title {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: block;
      margin: 0;
      padding: 0;
      font-size: 1rem;
      font-weight: 500;
      line-height: 1rem; }
    .mdc-grid-tile__support-text {
      font-family: Roboto, sans-serif;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-weight: 400;
      letter-spacing: 0.04em;
      text-decoration: inherit;
      text-transform: inherit;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: block;
      margin: 0;
      margin-top: 4px;
      padding: 0; }
    .mdc-grid-tile__icon {
      position: absolute;
      top: calc(50% - 24px / 2);
      font-size: 0; }

  /** postcss-bem-linter: define icon-toggle */
  .mdc-icon-toggle {
    --mdc-ripple-fg-size: 0;
    --mdc-ripple-left: 0;
    --mdc-ripple-top: 0;
    --mdc-ripple-fg-scale: 1;
    --mdc-ripple-fg-translate-end: 0;
    --mdc-ripple-fg-translate-start: 0;
    -webkit-tap-highlight-color: transparent;
    will-change: transform, opacity;
    /* @alternate */
    color: rgba(0, 0, 0, 0.87);
    color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87));
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 48px;
    height: 48px;
    padding: 12px;
    outline: none;
    font-size: 1.5rem;
    cursor: pointer;
    user-select: none;
    will-change: initial; }
    .mdc-icon-toggle::before, .mdc-icon-toggle::after {
      position: absolute;
      border-radius: 50%;
      opacity: 0;
      pointer-events: none;
      content: ""; }
    .mdc-icon-toggle::before {
      transition: opacity 15ms linear; }
    .mdc-icon-toggle.mdc-ripple-upgraded::after {
      top: 0;
      left: 0;
      transform: scale(0);
      transform-origin: center center; }
    .mdc-icon-toggle.mdc-ripple-upgraded--unbounded::after {
      top: var(--mdc-ripple-top, 0);
      left: var(--mdc-ripple-left, 0); }
    .mdc-icon-toggle.mdc-ripple-upgraded--foreground-activation::after {
      animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards; }
    .mdc-icon-toggle.mdc-ripple-upgraded--foreground-deactivation::after {
      animation: 150ms mdc-ripple-fg-opacity-out;
      transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
    .mdc-icon-toggle::before, .mdc-icon-toggle::after {
      top: calc(50% - 100%);
      left: calc(50% - 100%);
      width: 200%;
      height: 200%; }
    .mdc-icon-toggle.mdc-ripple-upgraded::before {
      top: calc(50% - 100%);
      left: calc(50% - 100%);
      width: 200%;
      height: 200%;
      transform: scale(var(--mdc-ripple-fg-scale, 0)); }
    .mdc-icon-toggle.mdc-ripple-upgraded--unbounded::before {
      top: var(--mdc-ripple-top, calc(50% - 50%));
      left: var(--mdc-ripple-left, calc(50% - 50%));
      width: var(--mdc-ripple-fg-size, 100%);
      height: var(--mdc-ripple-fg-size, 100%);
      transform: scale(var(--mdc-ripple-fg-scale, 0)); }
    .mdc-icon-toggle.mdc-ripple-upgraded::after {
      width: var(--mdc-ripple-fg-size, 100%);
      height: var(--mdc-ripple-fg-size, 100%); }
    .mdc-icon-toggle::before, .mdc-icon-toggle::after {
      background-color: black; }
    .mdc-icon-toggle:hover::before {
      opacity: 0.04; }
    .mdc-icon-toggle:not(.mdc-ripple-upgraded):focus::before, .mdc-icon-toggle.mdc-ripple-upgraded--background-focused::before {
      transition-duration: 75ms;
      opacity: 0.12; }
    .mdc-icon-toggle:not(.mdc-ripple-upgraded)::after {
      transition: opacity 150ms linear; }
    .mdc-icon-toggle:not(.mdc-ripple-upgraded):active::after {
      transition-duration: 75ms;
      opacity: 0.16; }
    .mdc-icon-toggle.mdc-ripple-upgraded {
      --mdc-ripple-fg-opacity: 0.16; }
    .mdc-icon-toggle--theme-dark.mdc-icon-toggle,
    .mdc-theme--dark .mdc-icon-toggle {
      /* @alternate */
      color: white;
      color: var(--mdc-theme-text-primary-on-dark, white); }
      .mdc-icon-toggle--theme-dark.mdc-icon-toggle::before, .mdc-icon-toggle--theme-dark.mdc-icon-toggle::after,
      .mdc-theme--dark .mdc-icon-toggle::before,
      .mdc-theme--dark .mdc-icon-toggle::after {
        background-color: white; }
      .mdc-icon-toggle--theme-dark.mdc-icon-toggle:hover::before,
      .mdc-theme--dark .mdc-icon-toggle:hover::before {
        opacity: 0.08; }
      .mdc-icon-toggle--theme-dark.mdc-icon-toggle:not(.mdc-ripple-upgraded):focus::before, .mdc-icon-toggle--theme-dark.mdc-icon-toggle.mdc-ripple-upgraded--background-focused::before,
      .mdc-theme--dark .mdc-icon-toggle:not(.mdc-ripple-upgraded):focus::before,
      .mdc-theme--dark .mdc-icon-toggle.mdc-ripple-upgraded--background-focused::before {
        transition-duration: 75ms;
        opacity: 0.24; }
      .mdc-icon-toggle--theme-dark.mdc-icon-toggle:not(.mdc-ripple-upgraded)::after,
      .mdc-theme--dark .mdc-icon-toggle:not(.mdc-ripple-upgraded)::after {
        transition: opacity 150ms linear; }
      .mdc-icon-toggle--theme-dark.mdc-icon-toggle:not(.mdc-ripple-upgraded):active::after,
      .mdc-theme--dark .mdc-icon-toggle:not(.mdc-ripple-upgraded):active::after {
        transition-duration: 75ms;
        opacity: 0.32; }
      .mdc-icon-toggle--theme-dark.mdc-icon-toggle.mdc-ripple-upgraded,
      .mdc-theme--dark .mdc-icon-toggle.mdc-ripple-upgraded {
        --mdc-ripple-fg-opacity: 0.32; }
    .mdc-icon-toggle::after {
      position: absolute;
      border-radius: 50%;
      opacity: 0;
      pointer-events: none;
      content: ""; }

  .mdc-icon-toggle--disabled {
    /* @alternate */
    color: rgba(0, 0, 0, 0.38);
    color: var(--mdc-theme-text-disabled-on-light, rgba(0, 0, 0, 0.38));
    pointer-events: none; }
    .mdc-icon-toggle--theme-dark.mdc-icon-toggle--disabled,
    .mdc-theme--dark .mdc-icon-toggle--disabled {
      /* @alternate */
      color: rgba(255, 255, 255, 0.5);
      color: var(--mdc-theme-text-disabled-on-dark, rgba(255, 255, 255, 0.5)); }

  /** postcss-bem-linter: end */
  :root {
    --mdc-layout-grid-margin-desktop: 24px;
    --mdc-layout-grid-gutter-desktop: 24px;
    --mdc-layout-grid-column-width-desktop: 72px;
    --mdc-layout-grid-margin-tablet: 16px;
    --mdc-layout-grid-gutter-tablet: 16px;
    --mdc-layout-grid-column-width-tablet: 72px;
    --mdc-layout-grid-margin-phone: 16px;
    --mdc-layout-grid-gutter-phone: 16px;
    --mdc-layout-grid-column-width-phone: 72px; }

  @media (min-width: 840px) {
    .mdc-layout-grid {
      box-sizing: border-box;
      margin: 0 auto;
      padding: 24px;
      padding: var(--mdc-layout-grid-margin-desktop, 24px); } }

  @media (min-width: 480px) and (max-width: 839px) {
    .mdc-layout-grid {
      box-sizing: border-box;
      margin: 0 auto;
      padding: 16px;
      padding: var(--mdc-layout-grid-margin-tablet, 16px); } }

  @media (max-width: 479px) {
    .mdc-layout-grid {
      box-sizing: border-box;
      margin: 0 auto;
      padding: 16px;
      padding: var(--mdc-layout-grid-margin-phone, 16px); } }

  @media (min-width: 840px) {
    .mdc-layout-grid__inner {
      display: flex;
      flex-flow: row wrap;
      align-items: stretch;
      margin: -12px;
      margin: calc(var(--mdc-layout-grid-gutter-desktop, 24px) / 2 * -1); }
      @supports (display: grid) {
        .mdc-layout-grid__inner {
          display: grid;
          margin: 0;
          grid-gap: 24px;
          grid-gap: var(--mdc-layout-grid-gutter-desktop, 24px);
          grid-template-columns: repeat(12, minmax(0, 1fr)); } } }

  @media (min-width: 480px) and (max-width: 839px) {
    .mdc-layout-grid__inner {
      display: flex;
      flex-flow: row wrap;
      align-items: stretch;
      margin: -8px;
      margin: calc(var(--mdc-layout-grid-gutter-tablet, 16px) / 2 * -1); }
      @supports (display: grid) {
        .mdc-layout-grid__inner {
          display: grid;
          margin: 0;
          grid-gap: 16px;
          grid-gap: var(--mdc-layout-grid-gutter-tablet, 16px);
          grid-template-columns: repeat(8, minmax(0, 1fr)); } } }

  @media (max-width: 479px) {
    .mdc-layout-grid__inner {
      display: flex;
      flex-flow: row wrap;
      align-items: stretch;
      margin: -8px;
      margin: calc(var(--mdc-layout-grid-gutter-phone, 16px) / 2 * -1); }
      @supports (display: grid) {
        .mdc-layout-grid__inner {
          display: grid;
          margin: 0;
          grid-gap: 16px;
          grid-gap: var(--mdc-layout-grid-gutter-phone, 16px);
          grid-template-columns: repeat(4, minmax(0, 1fr)); } } }

  @media (min-width: 840px) {
    .mdc-layout-grid__cell {
      width: calc(33.33333% - 24px);
      width: calc(33.33333% - var(--mdc-layout-grid-gutter-desktop, 24px));
      box-sizing: border-box;
      margin: 12px;
      margin: calc(var(--mdc-layout-grid-gutter-desktop, 24px) / 2); }
      @supports (display: grid) {
        .mdc-layout-grid__cell {
          width: auto;
          grid-column-end: span 4; } }
      @supports (display: grid) {
        .mdc-layout-grid__cell {
          margin: 0; } }
      .mdc-layout-grid__cell--span-1,
      .mdc-layout-grid__cell--span-1-desktop {
        width: calc(8.33333% - 24px);
        width: calc(8.33333% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
        @supports (display: grid) {
          .mdc-layout-grid__cell--span-1,
          .mdc-layout-grid__cell--span-1-desktop {
            width: auto;
            grid-column-end: span 1; } }
      .mdc-layout-grid__cell--span-2,
      .mdc-layout-grid__cell--span-2-desktop {
        width: calc(16.66667% - 24px);
        width: calc(16.66667% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
        @supports (display: grid) {
          .mdc-layout-grid__cell--span-2,
          .mdc-layout-grid__cell--span-2-desktop {
            width: auto;
            grid-column-end: span 2; } }
      .mdc-layout-grid__cell--span-3,
      .mdc-layout-grid__cell--span-3-desktop {
        width: calc(25% - 24px);
        width: calc(25% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
        @supports (display: grid) {
          .mdc-layout-grid__cell--span-3,
          .mdc-layout-grid__cell--span-3-desktop {
            width: auto;
            grid-column-end: span 3; } }
      .mdc-layout-grid__cell--span-4,
      .mdc-layout-grid__cell--span-4-desktop {
        width: calc(33.33333% - 24px);
        width: calc(33.33333% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
        @supports (display: grid) {
          .mdc-layout-grid__cell--span-4,
          .mdc-layout-grid__cell--span-4-desktop {
            width: auto;
            grid-column-end: span 4; } }
      .mdc-layout-grid__cell--span-5,
      .mdc-layout-grid__cell--span-5-desktop {
        width: calc(41.66667% - 24px);
        width: calc(41.66667% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
        @supports (display: grid) {
          .mdc-layout-grid__cell--span-5,
          .mdc-layout-grid__cell--span-5-desktop {
            width: auto;
            grid-column-end: span 5; } }
      .mdc-layout-grid__cell--span-6,
      .mdc-layout-grid__cell--span-6-desktop {
        width: calc(50% - 24px);
        width: calc(50% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
        @supports (display: grid) {
          .mdc-layout-grid__cell--span-6,
          .mdc-layout-grid__cell--span-6-desktop {
            width: auto;
            grid-column-end: span 6; } }
      .mdc-layout-grid__cell--span-7,
      .mdc-layout-grid__cell--span-7-desktop {
        width: calc(58.33333% - 24px);
        width: calc(58.33333% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
        @supports (display: grid) {
          .mdc-layout-grid__cell--span-7,
          .mdc-layout-grid__cell--span-7-desktop {
            width: auto;
            grid-column-end: span 7; } }
      .mdc-layout-grid__cell--span-8,
      .mdc-layout-grid__cell--span-8-desktop {
        width: calc(66.66667% - 24px);
        width: calc(66.66667% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
        @supports (display: grid) {
          .mdc-layout-grid__cell--span-8,
          .mdc-layout-grid__cell--span-8-desktop {
            width: auto;
            grid-column-end: span 8; } }
      .mdc-layout-grid__cell--span-9,
      .mdc-layout-grid__cell--span-9-desktop {
        width: calc(75% - 24px);
        width: calc(75% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
        @supports (display: grid) {
          .mdc-layout-grid__cell--span-9,
          .mdc-layout-grid__cell--span-9-desktop {
            width: auto;
            grid-column-end: span 9; } }
      .mdc-layout-grid__cell--span-10,
      .mdc-layout-grid__cell--span-10-desktop {
        width: calc(83.33333% - 24px);
        width: calc(83.33333% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
        @supports (display: grid) {
          .mdc-layout-grid__cell--span-10,
          .mdc-layout-grid__cell--span-10-desktop {
            width: auto;
            grid-column-end: span 10; } }
      .mdc-layout-grid__cell--span-11,
      .mdc-layout-grid__cell--span-11-desktop {
        width: calc(91.66667% - 24px);
        width: calc(91.66667% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
        @supports (display: grid) {
          .mdc-layout-grid__cell--span-11,
          .mdc-layout-grid__cell--span-11-desktop {
            width: auto;
            grid-column-end: span 11; } }
      .mdc-layout-grid__cell--span-12,
      .mdc-layout-grid__cell--span-12-desktop {
        width: calc(100% - 24px);
        width: calc(100% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
        @supports (display: grid) {
          .mdc-layout-grid__cell--span-12,
          .mdc-layout-grid__cell--span-12-desktop {
            width: auto;
            grid-column-end: span 12; } } }

  @media (min-width: 480px) and (max-width: 839px) {
    .mdc-layout-grid__cell {
      width: calc(50% - 16px);
      width: calc(50% - var(--mdc-layout-grid-gutter-tablet, 16px));
      box-sizing: border-box;
      margin: 8px;
      margin: calc(var(--mdc-layout-grid-gutter-tablet, 16px) / 2); }
      @supports (display: grid) {
        .mdc-layout-grid__cell {
          width: auto;
          grid-column-end: span 4; } }
      @supports (display: grid) {
        .mdc-layout-grid__cell {
          margin: 0; } }
      .mdc-layout-grid__cell--span-1,
      .mdc-layout-grid__cell--span-1-tablet {
        width: calc(12.5% - 16px);
        width: calc(12.5% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
        @supports (display: grid) {
          .mdc-layout-grid__cell--span-1,
          .mdc-layout-grid__cell--span-1-tablet {
            width: auto;
            grid-column-end: span 1; } }
      .mdc-layout-grid__cell--span-2,
      .mdc-layout-grid__cell--span-2-tablet {
        width: calc(25% - 16px);
        width: calc(25% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
        @supports (display: grid) {
          .mdc-layout-grid__cell--span-2,
          .mdc-layout-grid__cell--span-2-tablet {
            width: auto;
            grid-column-end: span 2; } }
      .mdc-layout-grid__cell--span-3,
      .mdc-layout-grid__cell--span-3-tablet {
        width: calc(37.5% - 16px);
        width: calc(37.5% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
        @supports (display: grid) {
          .mdc-layout-grid__cell--span-3,
          .mdc-layout-grid__cell--span-3-tablet {
            width: auto;
            grid-column-end: span 3; } }
      .mdc-layout-grid__cell--span-4,
      .mdc-layout-grid__cell--span-4-tablet {
        width: calc(50% - 16px);
        width: calc(50% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
        @supports (display: grid) {
          .mdc-layout-grid__cell--span-4,
          .mdc-layout-grid__cell--span-4-tablet {
            width: auto;
            grid-column-end: span 4; } }
      .mdc-layout-grid__cell--span-5,
      .mdc-layout-grid__cell--span-5-tablet {
        width: calc(62.5% - 16px);
        width: calc(62.5% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
        @supports (display: grid) {
          .mdc-layout-grid__cell--span-5,
          .mdc-layout-grid__cell--span-5-tablet {
            width: auto;
            grid-column-end: span 5; } }
      .mdc-layout-grid__cell--span-6,
      .mdc-layout-grid__cell--span-6-tablet {
        width: calc(75% - 16px);
        width: calc(75% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
        @supports (display: grid) {
          .mdc-layout-grid__cell--span-6,
          .mdc-layout-grid__cell--span-6-tablet {
            width: auto;
            grid-column-end: span 6; } }
      .mdc-layout-grid__cell--span-7,
      .mdc-layout-grid__cell--span-7-tablet {
        width: calc(87.5% - 16px);
        width: calc(87.5% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
        @supports (display: grid) {
          .mdc-layout-grid__cell--span-7,
          .mdc-layout-grid__cell--span-7-tablet {
            width: auto;
            grid-column-end: span 7; } }
      .mdc-layout-grid__cell--span-8,
      .mdc-layout-grid__cell--span-8-tablet {
        width: calc(100% - 16px);
        width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
        @supports (display: grid) {
          .mdc-layout-grid__cell--span-8,
          .mdc-layout-grid__cell--span-8-tablet {
            width: auto;
            grid-column-end: span 8; } }
      .mdc-layout-grid__cell--span-9,
      .mdc-layout-grid__cell--span-9-tablet {
        width: calc(100% - 16px);
        width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
        @supports (display: grid) {
          .mdc-layout-grid__cell--span-9,
          .mdc-layout-grid__cell--span-9-tablet {
            width: auto;
            grid-column-end: span 8; } }
      .mdc-layout-grid__cell--span-10,
      .mdc-layout-grid__cell--span-10-tablet {
        width: calc(100% - 16px);
        width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
        @supports (display: grid) {
          .mdc-layout-grid__cell--span-10,
          .mdc-layout-grid__cell--span-10-tablet {
            width: auto;
            grid-column-end: span 8; } }
      .mdc-layout-grid__cell--span-11,
      .mdc-layout-grid__cell--span-11-tablet {
        width: calc(100% - 16px);
        width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
        @supports (display: grid) {
          .mdc-layout-grid__cell--span-11,
          .mdc-layout-grid__cell--span-11-tablet {
            width: auto;
            grid-column-end: span 8; } }
      .mdc-layout-grid__cell--span-12,
      .mdc-layout-grid__cell--span-12-tablet {
        width: calc(100% - 16px);
        width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
        @supports (display: grid) {
          .mdc-layout-grid__cell--span-12,
          .mdc-layout-grid__cell--span-12-tablet {
            width: auto;
            grid-column-end: span 8; } } }

  @media (max-width: 479px) {
    .mdc-layout-grid__cell {
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
      box-sizing: border-box;
      margin: 8px;
      margin: calc(var(--mdc-layout-grid-gutter-phone, 16px) / 2); }
      @supports (display: grid) {
        .mdc-layout-grid__cell {
          width: auto;
          grid-column-end: span 4; } }
      @supports (display: grid) {
        .mdc-layout-grid__cell {
          margin: 0; } }
      .mdc-layout-grid__cell--span-1,
      .mdc-layout-grid__cell--span-1-phone {
        width: calc(25% - 16px);
        width: calc(25% - var(--mdc-layout-grid-gutter-phone, 16px)); }
        @supports (display: grid) {
          .mdc-layout-grid__cell--span-1,
          .mdc-layout-grid__cell--span-1-phone {
            width: auto;
            grid-column-end: span 1; } }
      .mdc-layout-grid__cell--span-2,
      .mdc-layout-grid__cell--span-2-phone {
        width: calc(50% - 16px);
        width: calc(50% - var(--mdc-layout-grid-gutter-phone, 16px)); }
        @supports (display: grid) {
          .mdc-layout-grid__cell--span-2,
          .mdc-layout-grid__cell--span-2-phone {
            width: auto;
            grid-column-end: span 2; } }
      .mdc-layout-grid__cell--span-3,
      .mdc-layout-grid__cell--span-3-phone {
        width: calc(75% - 16px);
        width: calc(75% - var(--mdc-layout-grid-gutter-phone, 16px)); }
        @supports (display: grid) {
          .mdc-layout-grid__cell--span-3,
          .mdc-layout-grid__cell--span-3-phone {
            width: auto;
            grid-column-end: span 3; } }
      .mdc-layout-grid__cell--span-4,
      .mdc-layout-grid__cell--span-4-phone {
        width: calc(100% - 16px);
        width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px)); }
        @supports (display: grid) {
          .mdc-layout-grid__cell--span-4,
          .mdc-layout-grid__cell--span-4-phone {
            width: auto;
            grid-column-end: span 4; } }
      .mdc-layout-grid__cell--span-5,
      .mdc-layout-grid__cell--span-5-phone {
        width: calc(100% - 16px);
        width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px)); }
        @supports (display: grid) {
          .mdc-layout-grid__cell--span-5,
          .mdc-layout-grid__cell--span-5-phone {
            width: auto;
            grid-column-end: span 4; } }
      .mdc-layout-grid__cell--span-6,
      .mdc-layout-grid__cell--span-6-phone {
        width: calc(100% - 16px);
        width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px)); }
        @supports (display: grid) {
          .mdc-layout-grid__cell--span-6,
          .mdc-layout-grid__cell--span-6-phone {
            width: auto;
            grid-column-end: span 4; } }
      .mdc-layout-grid__cell--span-7,
      .mdc-layout-grid__cell--span-7-phone {
        width: calc(100% - 16px);
        width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px)); }
        @supports (display: grid) {
          .mdc-layout-grid__cell--span-7,
          .mdc-layout-grid__cell--span-7-phone {
            width: auto;
            grid-column-end: span 4; } }
      .mdc-layout-grid__cell--span-8,
      .mdc-layout-grid__cell--span-8-phone {
        width: calc(100% - 16px);
        width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px)); }
        @supports (display: grid) {
          .mdc-layout-grid__cell--span-8,
          .mdc-layout-grid__cell--span-8-phone {
            width: auto;
            grid-column-end: span 4; } }
      .mdc-layout-grid__cell--span-9,
      .mdc-layout-grid__cell--span-9-phone {
        width: calc(100% - 16px);
        width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px)); }
        @supports (display: grid) {
          .mdc-layout-grid__cell--span-9,
          .mdc-layout-grid__cell--span-9-phone {
            width: auto;
            grid-column-end: span 4; } }
      .mdc-layout-grid__cell--span-10,
      .mdc-layout-grid__cell--span-10-phone {
        width: calc(100% - 16px);
        width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px)); }
        @supports (display: grid) {
          .mdc-layout-grid__cell--span-10,
          .mdc-layout-grid__cell--span-10-phone {
            width: auto;
            grid-column-end: span 4; } }
      .mdc-layout-grid__cell--span-11,
      .mdc-layout-grid__cell--span-11-phone {
        width: calc(100% - 16px);
        width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px)); }
        @supports (display: grid) {
          .mdc-layout-grid__cell--span-11,
          .mdc-layout-grid__cell--span-11-phone {
            width: auto;
            grid-column-end: span 4; } }
      .mdc-layout-grid__cell--span-12,
      .mdc-layout-grid__cell--span-12-phone {
        width: calc(100% - 16px);
        width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px)); }
        @supports (display: grid) {
          .mdc-layout-grid__cell--span-12,
          .mdc-layout-grid__cell--span-12-phone {
            width: auto;
            grid-column-end: span 4; } } }

  .mdc-layout-grid__cell--order-1 {
    order: 1; }

  .mdc-layout-grid__cell--order-2 {
    order: 2; }

  .mdc-layout-grid__cell--order-3 {
    order: 3; }

  .mdc-layout-grid__cell--order-4 {
    order: 4; }

  .mdc-layout-grid__cell--order-5 {
    order: 5; }

  .mdc-layout-grid__cell--order-6 {
    order: 6; }

  .mdc-layout-grid__cell--order-7 {
    order: 7; }

  .mdc-layout-grid__cell--order-8 {
    order: 8; }

  .mdc-layout-grid__cell--order-9 {
    order: 9; }

  .mdc-layout-grid__cell--order-10 {
    order: 10; }

  .mdc-layout-grid__cell--order-11 {
    order: 11; }

  .mdc-layout-grid__cell--order-12 {
    order: 12; }

  .mdc-layout-grid__cell--align-top {
    align-self: flex-start; }
    @supports (display: grid) {
      .mdc-layout-grid__cell--align-top {
        align-self: start; } }

  .mdc-layout-grid__cell--align-middle {
    align-self: center; }

  .mdc-layout-grid__cell--align-bottom {
    align-self: flex-end; }
    @supports (display: grid) {
      .mdc-layout-grid__cell--align-bottom {
        align-self: end; } }

  @media (min-width: 840px) {
    .mdc-layout-grid--fixed-column-width {
      width: 1176px;
      width: calc( var(--mdc-layout-grid-column-width-desktop, 72px) * 12 + var(--mdc-layout-grid-gutter-desktop, 24px) * 11 + var(--mdc-layout-grid-margin-desktop, 24px) * 2); } }

  @media (min-width: 480px) and (max-width: 839px) {
    .mdc-layout-grid--fixed-column-width {
      width: 720px;
      width: calc( var(--mdc-layout-grid-column-width-tablet, 72px) * 8 + var(--mdc-layout-grid-gutter-tablet, 16px) * 7 + var(--mdc-layout-grid-margin-tablet, 16px) * 2); } }

  @media (max-width: 479px) {
    .mdc-layout-grid--fixed-column-width {
      width: 368px;
      width: calc( var(--mdc-layout-grid-column-width-phone, 72px) * 4 + var(--mdc-layout-grid-gutter-phone, 16px) * 3 + var(--mdc-layout-grid-margin-phone, 16px) * 2); } }

  .mdc-layout-grid--align-left {
    margin-right: auto;
    margin-left: 0; }

  .mdc-layout-grid--align-right {
    margin-right: 0;
    margin-left: auto; }

  @keyframes primary-indeterminate-translate {
    0% {
      transform: translateX(0); }
    20% {
      animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
      transform: translateX(0); }
    59.15% {
      animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
      transform: translateX(83.67142%); }
    100% {
      transform: translateX(200.61106%); } }

  @keyframes primary-indeterminate-scale {
    0% {
      transform: scaleX(0.08); }
    36.65% {
      animation-timing-function: cubic-bezier(0.33473, 0.12482, 0.78584, 1);
      transform: scaleX(0.08); }
    69.15% {
      animation-timing-function: cubic-bezier(0.06, 0.11, 0.6, 1);
      transform: scaleX(0.66148); }
    100% {
      transform: scaleX(0.08); } }

  @keyframes secondary-indeterminate-translate {
    0% {
      animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40969);
      transform: translateX(0); }
    25% {
      animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73371);
      transform: translateX(37.65191%); }
    48.35% {
      animation-timing-function: cubic-bezier(0.4, 0.62704, 0.6, 0.90203);
      transform: translateX(84.38617%); }
    100% {
      transform: translateX(160.27778%); } }

  @keyframes secondary-indeterminate-scale {
    0% {
      animation-timing-function: cubic-bezier(0.20503, 0.05705, 0.57661, 0.45397);
      transform: scaleX(0.08); }
    19.15% {
      animation-timing-function: cubic-bezier(0.15231, 0.19643, 0.64837, 1.00432);
      transform: scaleX(0.4571); }
    44.15% {
      animation-timing-function: cubic-bezier(0.25776, -0.00316, 0.21176, 1.38179);
      transform: scaleX(0.72796); }
    100% {
      transform: scaleX(0.08); } }

  @keyframes buffering {
    to {
      transform: translateX(-10px); } }

  @keyframes primary-indeterminate-translate-reverse {
    0% {
      transform: translateX(0); }
    20% {
      animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
      transform: translateX(0); }
    59.15% {
      animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
      transform: translateX(-83.67142%); }
    100% {
      transform: translateX(-200.61106%); } }

  @keyframes secondary-indeterminate-translate-reverse {
    0% {
      animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40969);
      transform: translateX(0); }
    25% {
      animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73371);
      transform: translateX(-37.65191%); }
    48.35% {
      animation-timing-function: cubic-bezier(0.4, 0.62704, 0.6, 0.90203);
      transform: translateX(-84.38617%); }
    100% {
      transform: translateX(-160.27778%); } }

  @keyframes buffering-reverse {
    to {
      transform: translateX(10px); } }

  .mdc-linear-progress {
    position: relative;
    width: 100%;
    height: 4px;
    transform: translateZ(0);
    transition: opacity 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
    overflow: hidden; }
    .mdc-linear-progress .mdc-linear-progress__bar-inner {
      /* @alternate */
      background-color: #6200ee;
      background-color: var(--mdc-theme-primary, #6200ee); }
    .mdc-linear-progress .mdc-linear-progress__buffering-dots {
      background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='%23e6e6e6'/%3E%3C/svg%3E"); }
    .mdc-linear-progress .mdc-linear-progress__buffer {
      background-color: #e6e6e6; }
    .mdc-linear-progress__bar {
      position: absolute;
      width: 100%;
      height: 100%;
      animation: none;
      transform-origin: top left;
      transition: transform 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1); }
    .mdc-linear-progress__bar-inner {
      display: inline-block;
      position: absolute;
      width: 100%;
      height: 100%;
      animation: none; }
    .mdc-linear-progress__buffering-dots {
      position: absolute;
      width: 100%;
      height: 100%;
      animation: buffering 250ms infinite linear;
      background-repeat: repeat-x;
      background-size: 10px 4px; }
    .mdc-linear-progress__buffer {
      position: absolute;
      width: 100%;
      height: 100%;
      transform-origin: top left;
      transition: transform 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1); }
    .mdc-linear-progress__primary-bar {
      transform: scaleX(0); }
    .mdc-linear-progress__secondary-bar {
      visibility: hidden; }
    .mdc-linear-progress--indeterminate .mdc-linear-progress__bar {
      transition: none; }
    .mdc-linear-progress--indeterminate .mdc-linear-progress__primary-bar {
      left: -145.166611%;
      animation: primary-indeterminate-translate 2s infinite linear; }
      .mdc-linear-progress--indeterminate .mdc-linear-progress__primary-bar > .mdc-linear-progress__bar-inner {
        animation: primary-indeterminate-scale 2s infinite linear; }
    .mdc-linear-progress--indeterminate .mdc-linear-progress__secondary-bar {
      left: -54.888891%;
      animation: secondary-indeterminate-translate 2s infinite linear;
      visibility: visible; }
      .mdc-linear-progress--indeterminate .mdc-linear-progress__secondary-bar > .mdc-linear-progress__bar-inner {
        animation: secondary-indeterminate-scale 2s infinite linear; }
    .mdc-linear-progress--reversed .mdc-linear-progress__bar,
    .mdc-linear-progress--reversed .mdc-linear-progress__buffer {
      right: 0;
      transform-origin: center right; }
    .mdc-linear-progress--reversed .mdc-linear-progress__primary-bar {
      animation-name: primary-indeterminate-translate-reverse; }
    .mdc-linear-progress--reversed .mdc-linear-progress__secondary-bar {
      animation-name: secondary-indeterminate-translate-reverse; }
    .mdc-linear-progress--reversed .mdc-linear-progress__buffering-dots {
      animation: buffering-reverse 250ms infinite linear; }
    .mdc-linear-progress--closed {
      opacity: 0; }

  .mdc-linear-progress--indeterminate.mdc-linear-progress--reversed .mdc-linear-progress__primary-bar {
    right: -145.166611%;
    left: auto; }

  .mdc-linear-progress--indeterminate.mdc-linear-progress--reversed .mdc-linear-progress__secondary-bar {
    right: -54.888891%;
    left: auto; }

  /**
   * Creates a rule that will be applied when an MDC-Web component is within the context of an RTL layout.
   *
   * Usage Example:
   * ```scss
   * .mdc-foo {
   *   position: absolute;
   *   left: 0;
   *
   *   @include mdc-rtl {
   *     left: auto;
   *     right: 0;
   *   }
   *
   *   &__bar {
   *     margin-left: 4px;
   *     @include mdc-rtl(".mdc-foo") {
   *       margin-left: auto;
   *       margin-right: 4px;
   *     }
   *   }
   * }
   *
   * .mdc-foo--mod {
   *   padding-left: 4px;
   *
   *   @include mdc-rtl {
   *     padding-left: auto;
   *     padding-right: 4px;
   *   }
   * }
   * ```
   *
   * Note that this works by checking for [dir="rtl"] on an ancestor element. While this will work
   * in most cases, it will in some cases lead to false negatives, e.g.
   *
   * ```html
   * <html dir="rtl">
   *   <!-- ... -->
   *   <div dir="ltr">
   *     <div class="mdc-foo">Styled incorrectly as RTL!</div>
   *   </div>
   * </html>
   * ```
   *
   * In the future, selectors such as :dir (http://mdn.io/:dir) will help us mitigate this.
   */
  /**
   * Takes a base box-model property - e.g. margin / border / padding - along with a default
   * direction and value, and emits rules which apply the value to the
   * "<base-property>-<default-direction>" property by default, but flips the direction
   * when within an RTL context.
   *
   * For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-box(margin, left, 8px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-left: 8px;
   *
   *   @include mdc-rtl {
   *     margin-right: 8px;
   *     margin-left: 0;
   *   }
   * }
   * ```
   * whereas:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-box(margin, right, 8px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-right: 8px;
   *
   *   @include mdc-rtl {
   *     margin-right: 0;
   *     margin-left: 8px;
   *   }
   * }
   * ```
   *
   * You can also pass a 4th optional $root-selector argument which will be forwarded to `mdc-rtl`,
   * e.g. `@include mdc-rtl-reflexive-box(margin, left, 8px, ".mdc-component")`.
   *
   * Note that this function will always zero out the original value in an RTL context. If you're
   * trying to flip the values, use mdc-rtl-reflexive-property().
   */
  /**
   * Takes a base property and emits rules that assign <base-property>-left to <left-value> and
   * <base-property>-right to <right-value> in a LTR context, and vice versa in a RTL context.
   * For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-property(margin, auto, 12px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-left: auto;
   *   margin-right: 12px;
   *
   *   @include mdc-rtl {
   *     margin-left: 12px;
   *     margin-right: auto;
   *   }
   * }
   * ```
   *
   * A 4th optional $root-selector argument can be given, which will be passed to `mdc-rtl`.
   */
  /**
   * Takes an argument specifying a horizontal position property (either "left" or "right") as well
   * as a value, and applies that value to the specified position in a LTR context, and flips it in a
   * RTL context. For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-position(left, 0);
   *   position: absolute;
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   *  .mdc-foo {
   *    position: absolute;
   *    left: 0;
   *    right: initial;
   *
   *    @include mdc-rtl {
   *      right: 0;
   *      left: initial;
   *    }
   *  }
   * ```
   * An optional third $root-selector argument may also be given, which is passed to `mdc-rtl`.
   */
  /* TODO(sgomes): Figure out what to do about desktop font sizes. */
  /* TODO(sgomes): Figure out what to do about i18n and i18n font sizes. */
  /* TODO(sgomes): Figure out what to do about desktop font sizes. */
  /* TODO(sgomes): Figure out what to do about i18n and i18n font sizes. */
  /**
   * Creates a rule that will be applied when an MDC-Web component is within the context of an RTL layout.
   *
   * Usage Example:
   * ```scss
   * .mdc-foo {
   *   position: absolute;
   *   left: 0;
   *
   *   @include mdc-rtl {
   *     left: auto;
   *     right: 0;
   *   }
   *
   *   &__bar {
   *     margin-left: 4px;
   *     @include mdc-rtl(".mdc-foo") {
   *       margin-left: auto;
   *       margin-right: 4px;
   *     }
   *   }
   * }
   *
   * .mdc-foo--mod {
   *   padding-left: 4px;
   *
   *   @include mdc-rtl {
   *     padding-left: auto;
   *     padding-right: 4px;
   *   }
   * }
   * ```
   *
   * Note that this works by checking for [dir="rtl"] on an ancestor element. While this will work
   * in most cases, it will in some cases lead to false negatives, e.g.
   *
   * ```html
   * <html dir="rtl">
   *   <!-- ... -->
   *   <div dir="ltr">
   *     <div class="mdc-foo">Styled incorrectly as RTL!</div>
   *   </div>
   * </html>
   * ```
   *
   * In the future, selectors such as :dir (http://mdn.io/:dir) will help us mitigate this.
   */
  /**
   * Takes a base box-model property - e.g. margin / border / padding - along with a default
   * direction and value, and emits rules which apply the value to the
   * "<base-property>-<default-direction>" property by default, but flips the direction
   * when within an RTL context.
   *
   * For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-box(margin, left, 8px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-left: 8px;
   *
   *   @include mdc-rtl {
   *     margin-right: 8px;
   *     margin-left: 0;
   *   }
   * }
   * ```
   * whereas:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-box(margin, right, 8px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-right: 8px;
   *
   *   @include mdc-rtl {
   *     margin-right: 0;
   *     margin-left: 8px;
   *   }
   * }
   * ```
   *
   * You can also pass a 4th optional $root-selector argument which will be forwarded to `mdc-rtl`,
   * e.g. `@include mdc-rtl-reflexive-box(margin, left, 8px, ".mdc-component")`.
   *
   * Note that this function will always zero out the original value in an RTL context. If you're
   * trying to flip the values, use mdc-rtl-reflexive-property().
   */
  /**
   * Takes a base property and emits rules that assign <base-property>-left to <left-value> and
   * <base-property>-right to <right-value> in a LTR context, and vice versa in a RTL context.
   * For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-property(margin, auto, 12px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-left: auto;
   *   margin-right: 12px;
   *
   *   @include mdc-rtl {
   *     margin-left: 12px;
   *     margin-right: auto;
   *   }
   * }
   * ```
   *
   * A 4th optional $root-selector argument can be given, which will be passed to `mdc-rtl`.
   */
  /**
   * Takes an argument specifying a horizontal position property (either "left" or "right") as well
   * as a value, and applies that value to the specified position in a LTR context, and flips it in a
   * RTL context. For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-position(left, 0);
   *   position: absolute;
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   *  .mdc-foo {
   *    position: absolute;
   *    left: 0;
   *    right: initial;
   *
   *    @include mdc-rtl {
   *      right: 0;
   *      left: initial;
   *    }
   *  }
   * ```
   * An optional third $root-selector argument may also be given, which is passed to `mdc-rtl`.
   */
  .mdc-list--theme-dark .mdc-list-divider,
  .mdc-theme--dark .mdc-list .mdc-list-divider,
  .mdc-list-group--theme-dark .mdc-list-divider,
  .mdc-theme--dark
  .mdc-list-group .mdc-list-divider {
    border-bottom-color: rgba(255, 255, 255, 0.2); }

  .mdc-list {
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 1rem;
    line-height: 1.75rem;
    font-weight: 400;
    letter-spacing: 0.04em;
    text-decoration: inherit;
    text-transform: inherit;
    /* @alternate */
    color: rgba(0, 0, 0, 0.87);
    color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
    margin: 0;
    padding: 8px 0;
    line-height: 1.5rem;
    list-style-type: none; }
    .mdc-list--theme-dark,
    .mdc-theme--dark .mdc-list {
      /* @alternate */
      color: white;
      color: var(--mdc-theme-text-primary-on-dark, white); }
      .mdc-list--theme-dark .mdc-list-item__secondary-text,
      .mdc-theme--dark .mdc-list .mdc-list-item__secondary-text {
        /* @alternate */
        color: rgba(255, 255, 255, 0.7);
        color: var(--mdc-theme-text-secondary-on-dark, rgba(255, 255, 255, 0.7)); }
      .mdc-list--theme-dark .mdc-list-item__graphic,
      .mdc-theme--dark .mdc-list .mdc-list-item__graphic {
        /* @alternate */
        color: rgba(255, 255, 255, 0.5);
        color: var(--mdc-theme-text-icon-on-dark, rgba(255, 255, 255, 0.5)); }
      .mdc-list--theme-dark .mdc-list-item__meta,
      .mdc-theme--dark .mdc-list .mdc-list-item__meta {
        /* @alternate */
        color: rgba(255, 255, 255, 0.5);
        color: var(--mdc-theme-text-hint-on-dark, rgba(255, 255, 255, 0.5)); }

  .mdc-list-item__secondary-text {
    /* @alternate */
    color: rgba(0, 0, 0, 0.54);
    color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54)); }

  .mdc-list-item__graphic {
    background-color: transparent; }

  .mdc-list-item__graphic {
    /* @alternate */
    color: rgba(0, 0, 0, 0.38);
    color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38)); }

  .mdc-list-item__meta {
    /* @alternate */
    color: rgba(0, 0, 0, 0.38);
    color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38)); }

  .mdc-list--dense {
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: .812rem; }

  .mdc-list-item {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 48px;
    padding: 0 16px; }
    .mdc-list-item--selected, .mdc-list-item--activated {
      /* @alternate */
      color: #6200ee;
      color: var(--mdc-theme-primary, #6200ee); }
      .mdc-list-item--selected .mdc-list-item__graphic, .mdc-list-item--activated .mdc-list-item__graphic {
        /* @alternate */
        color: #6200ee;
        color: var(--mdc-theme-primary, #6200ee); }
    .mdc-list-item__graphic {
      margin-left: 0;
      margin-right: 32px;
      width: 24px;
      height: 24px;
      display: inline-flex;
      align-items: center;
      justify-content: center; }
      [dir="rtl"] .mdc-list-item .mdc-list-item__graphic,
      .mdc-list-item[dir="rtl"] .mdc-list-item__graphic {
        margin-left: 32px;
        margin-right: 0; }
    .mdc-list-item__meta {
      margin-left: auto;
      margin-right: 0; }
      [dir="rtl"] .mdc-list-item .mdc-list-item__meta,
      .mdc-list-item[dir="rtl"] .mdc-list-item__meta {
        margin-left: 0;
        margin-right: auto; }
    .mdc-list-item__text {
      display: inline-flex;
      flex-direction: column; }
    .mdc-list-item__secondary-text {
      font-family: Roboto, sans-serif;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-weight: 400;
      letter-spacing: 0.04em;
      text-decoration: inherit;
      text-transform: inherit; }
      .mdc-list--dense .mdc-list-item__secondary-text {
        font-size: inherit; }
    .mdc-list--dense .mdc-list-item {
      height: 40px; }
      .mdc-list--dense .mdc-list-item__graphic {
        margin-left: 0;
        margin-right: 36px;
        width: 20px;
        height: 20px; }
        [dir="rtl"] .mdc-list-item .mdc-list--dense .mdc-list-item__graphic,
        .mdc-list-item[dir="rtl"] .mdc-list--dense .mdc-list-item__graphic {
          margin-left: 36px;
          margin-right: 0; }
    .mdc-list--avatar-list .mdc-list-item {
      height: 56px; }
      .mdc-list--avatar-list .mdc-list-item__graphic {
        margin-left: 0;
        margin-right: 16px;
        width: 40px;
        height: 40px;
        border-radius: 50%; }
        [dir="rtl"] .mdc-list-item .mdc-list--avatar-list .mdc-list-item__graphic,
        .mdc-list-item[dir="rtl"] .mdc-list--avatar-list .mdc-list-item__graphic {
          margin-left: 16px;
          margin-right: 0; }
    .mdc-list-item .mdc-list--avatar-list.mdc-list--dense .mdc-list__item {
      height: 48px; }
      .mdc-list-item .mdc-list--avatar-list.mdc-list--dense .mdc-list__item__graphic {
        margin-left: 0;
        margin-right: 20px;
        width: 36px;
        height: 36px; }
        [dir="rtl"] .mdc-list-item .mdc-list-item .mdc-list--avatar-list.mdc-list--dense .mdc-list__item__graphic,
        .mdc-list-item[dir="rtl"] .mdc-list-item .mdc-list--avatar-list.mdc-list--dense .mdc-list__item__graphic {
          margin-left: 20px;
          margin-right: 0; }
    .mdc-list--two-line .mdc-list-item {
      height: 72px; }
    .mdc-list--two-line.mdc-list--dense .mdc-list-item {
      height: 60px; }

  a.mdc-list-item {
    color: inherit;
    text-decoration: none; }

  .mdc-list-item {
    position: relative;
    overflow: hidden; }
    .mdc-list-item:focus {
      outline: none; }

  :not(.mdc-list--non-interactive) > .mdc-list-item {
    --mdc-ripple-fg-size: 0;
    --mdc-ripple-left: 0;
    --mdc-ripple-top: 0;
    --mdc-ripple-fg-scale: 1;
    --mdc-ripple-fg-translate-end: 0;
    --mdc-ripple-fg-translate-start: 0;
    -webkit-tap-highlight-color: transparent;
    will-change: transform, opacity; }
    :not(.mdc-list--non-interactive) > .mdc-list-item::before, :not(.mdc-list--non-interactive) > .mdc-list-item::after {
      position: absolute;
      border-radius: 50%;
      opacity: 0;
      pointer-events: none;
      content: ""; }
    :not(.mdc-list--non-interactive) > .mdc-list-item::before {
      transition: opacity 15ms linear; }
    :not(.mdc-list--non-interactive) > .mdc-list-item.mdc-ripple-upgraded::after {
      top: 0;
      left: 0;
      transform: scale(0);
      transform-origin: center center; }
    :not(.mdc-list--non-interactive) > .mdc-list-item.mdc-ripple-upgraded--unbounded::after {
      top: var(--mdc-ripple-top, 0);
      left: var(--mdc-ripple-left, 0); }
    :not(.mdc-list--non-interactive) > .mdc-list-item.mdc-ripple-upgraded--foreground-activation::after {
      animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards; }
    :not(.mdc-list--non-interactive) > .mdc-list-item.mdc-ripple-upgraded--foreground-deactivation::after {
      animation: 150ms mdc-ripple-fg-opacity-out;
      transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
    :not(.mdc-list--non-interactive) > .mdc-list-item::before, :not(.mdc-list--non-interactive) > .mdc-list-item::after {
      top: calc(50% - 100%);
      left: calc(50% - 100%);
      width: 200%;
      height: 200%; }
    :not(.mdc-list--non-interactive) > .mdc-list-item.mdc-ripple-upgraded::before {
      top: calc(50% - 100%);
      left: calc(50% - 100%);
      width: 200%;
      height: 200%;
      transform: scale(var(--mdc-ripple-fg-scale, 0)); }
    :not(.mdc-list--non-interactive) > .mdc-list-item.mdc-ripple-upgraded--unbounded::before {
      top: var(--mdc-ripple-top, calc(50% - 50%));
      left: var(--mdc-ripple-left, calc(50% - 50%));
      width: var(--mdc-ripple-fg-size, 100%);
      height: var(--mdc-ripple-fg-size, 100%);
      transform: scale(var(--mdc-ripple-fg-scale, 0)); }
    :not(.mdc-list--non-interactive) > .mdc-list-item.mdc-ripple-upgraded::after {
      width: var(--mdc-ripple-fg-size, 100%);
      height: var(--mdc-ripple-fg-size, 100%); }
    :not(.mdc-list--non-interactive) > .mdc-list-item::before, :not(.mdc-list--non-interactive) > .mdc-list-item::after {
      background-color: black; }
    :not(.mdc-list--non-interactive) > .mdc-list-item:hover::before {
      opacity: 0.04; }
    :not(.mdc-list--non-interactive) > .mdc-list-item:not(.mdc-ripple-upgraded):focus::before, :not(.mdc-list--non-interactive) > .mdc-list-item.mdc-ripple-upgraded--background-focused::before {
      transition-duration: 75ms;
      opacity: 0.12; }
    :not(.mdc-list--non-interactive) > .mdc-list-item:not(.mdc-ripple-upgraded)::after {
      transition: opacity 150ms linear; }
    :not(.mdc-list--non-interactive) > .mdc-list-item:not(.mdc-ripple-upgraded):active::after {
      transition-duration: 75ms;
      opacity: 0.16; }
    :not(.mdc-list--non-interactive) > .mdc-list-item.mdc-ripple-upgraded {
      --mdc-ripple-fg-opacity: 0.16; }
    :not(.mdc-list--non-interactive) > .mdc-list-item--activated::before {
      opacity: 0.12; }
    :not(.mdc-list--non-interactive) > .mdc-list-item--activated::before, :not(.mdc-list--non-interactive) > .mdc-list-item--activated::after {
      /* @alternate */
      background-color: #6200ee; }
      @supports not (-ms-ime-align: auto) {
        :not(.mdc-list--non-interactive) > .mdc-list-item--activated::before, :not(.mdc-list--non-interactive) > .mdc-list-item--activated::after {
          background-color: var(--mdc-theme-primary, #6200ee); } }
    :not(.mdc-list--non-interactive) > .mdc-list-item--activated:hover::before {
      opacity: 0.16; }
    :not(.mdc-list--non-interactive) > .mdc-list-item--activated:not(.mdc-ripple-upgraded):focus::before, :not(.mdc-list--non-interactive) > .mdc-list-item--activated.mdc-ripple-upgraded--background-focused::before {
      transition-duration: 75ms;
      opacity: 0.24; }
    :not(.mdc-list--non-interactive) > .mdc-list-item--activated:not(.mdc-ripple-upgraded)::after {
      transition: opacity 150ms linear; }
    :not(.mdc-list--non-interactive) > .mdc-list-item--activated:not(.mdc-ripple-upgraded):active::after {
      transition-duration: 75ms;
      opacity: 0.28; }
    :not(.mdc-list--non-interactive) > .mdc-list-item--activated.mdc-ripple-upgraded {
      --mdc-ripple-fg-opacity: 0.28; }
    :not(.mdc-list--non-interactive) > .mdc-list-item--selected::before {
      opacity: 0.04; }
    :not(.mdc-list--non-interactive) > .mdc-list-item--selected::before, :not(.mdc-list--non-interactive) > .mdc-list-item--selected::after {
      /* @alternate */
      background-color: #6200ee; }
      @supports not (-ms-ime-align: auto) {
        :not(.mdc-list--non-interactive) > .mdc-list-item--selected::before, :not(.mdc-list--non-interactive) > .mdc-list-item--selected::after {
          background-color: var(--mdc-theme-primary, #6200ee); } }
    :not(.mdc-list--non-interactive) > .mdc-list-item--selected:hover::before {
      opacity: 0.08; }
    :not(.mdc-list--non-interactive) > .mdc-list-item--selected:not(.mdc-ripple-upgraded):focus::before, :not(.mdc-list--non-interactive) > .mdc-list-item--selected.mdc-ripple-upgraded--background-focused::before {
      transition-duration: 75ms;
      opacity: 0.16; }
    :not(.mdc-list--non-interactive) > .mdc-list-item--selected:not(.mdc-ripple-upgraded)::after {
      transition: opacity 150ms linear; }
    :not(.mdc-list--non-interactive) > .mdc-list-item--selected:not(.mdc-ripple-upgraded):active::after {
      transition-duration: 75ms;
      opacity: 0.2; }
    :not(.mdc-list--non-interactive) > .mdc-list-item--selected.mdc-ripple-upgraded {
      --mdc-ripple-fg-opacity: 0.2; }
    .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item::before, .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item::after,
    .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item::before,
    .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item::after {
      background-color: white; }
    .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item:hover::before,
    .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item:hover::before {
      opacity: 0.08; }
    .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item:not(.mdc-ripple-upgraded):focus::before, .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item.mdc-ripple-upgraded--background-focused::before,
    .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item:not(.mdc-ripple-upgraded):focus::before,
    .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item.mdc-ripple-upgraded--background-focused::before {
      transition-duration: 75ms;
      opacity: 0.24; }
    .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item:not(.mdc-ripple-upgraded)::after,
    .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item:not(.mdc-ripple-upgraded)::after {
      transition: opacity 150ms linear; }
    .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item:not(.mdc-ripple-upgraded):active::after,
    .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item:not(.mdc-ripple-upgraded):active::after {
      transition-duration: 75ms;
      opacity: 0.32; }
    .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item.mdc-ripple-upgraded,
    .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item.mdc-ripple-upgraded {
      --mdc-ripple-fg-opacity: 0.32; }
    .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item--activated::before,
    .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item--activated::before {
      opacity: 0.12; }
    .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item--activated::before, .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item--activated::after,
    .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item--activated::before,
    .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item--activated::after {
      /* @alternate */
      background-color: #6200ee; }
      @supports not (-ms-ime-align: auto) {
        .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item--activated::before, .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item--activated::after,
        .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item--activated::before,
        .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item--activated::after {
          background-color: var(--mdc-theme-primary, #6200ee); } }
    .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item--activated:hover::before,
    .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item--activated:hover::before {
      opacity: 0.16; }
    .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item--activated:not(.mdc-ripple-upgraded):focus::before, .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item--activated.mdc-ripple-upgraded--background-focused::before,
    .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item--activated:not(.mdc-ripple-upgraded):focus::before,
    .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item--activated.mdc-ripple-upgraded--background-focused::before {
      transition-duration: 75ms;
      opacity: 0.24; }
    .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item--activated:not(.mdc-ripple-upgraded)::after,
    .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item--activated:not(.mdc-ripple-upgraded)::after {
      transition: opacity 150ms linear; }
    .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item--activated:not(.mdc-ripple-upgraded):active::after,
    .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item--activated:not(.mdc-ripple-upgraded):active::after {
      transition-duration: 75ms;
      opacity: 0.28; }
    .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item--activated.mdc-ripple-upgraded,
    .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item--activated.mdc-ripple-upgraded {
      --mdc-ripple-fg-opacity: 0.28; }
    .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item--selected::before,
    .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item--selected::before {
      opacity: 0.04; }
    .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item--selected::before, .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item--selected::after,
    .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item--selected::before,
    .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item--selected::after {
      /* @alternate */
      background-color: #6200ee; }
      @supports not (-ms-ime-align: auto) {
        .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item--selected::before, .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item--selected::after,
        .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item--selected::before,
        .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item--selected::after {
          background-color: var(--mdc-theme-primary, #6200ee); } }
    .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item--selected:hover::before,
    .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item--selected:hover::before {
      opacity: 0.08; }
    .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item--selected:not(.mdc-ripple-upgraded):focus::before, .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item--selected.mdc-ripple-upgraded--background-focused::before,
    .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item--selected:not(.mdc-ripple-upgraded):focus::before,
    .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item--selected.mdc-ripple-upgraded--background-focused::before {
      transition-duration: 75ms;
      opacity: 0.16; }
    .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item--selected:not(.mdc-ripple-upgraded)::after,
    .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item--selected:not(.mdc-ripple-upgraded)::after {
      transition: opacity 150ms linear; }
    .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item--selected:not(.mdc-ripple-upgraded):active::after,
    .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item--selected:not(.mdc-ripple-upgraded):active::after {
      transition-duration: 75ms;
      opacity: 0.2; }
    .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item--selected.mdc-ripple-upgraded,
    .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item--selected.mdc-ripple-upgraded {
      --mdc-ripple-fg-opacity: 0.2; }

  .mdc-list-divider {
    height: 0;
    margin: 0;
    border: none;
    border-bottom-width: 1px;
    border-bottom-style: solid; }

  .mdc-list-divider {
    border-bottom-color: rgba(0, 0, 0, 0.12); }

  .mdc-list-divider--padded {
    margin: 0 16px; }

  .mdc-list-divider--inset {
    margin-left: 72px;
    margin-right: 0;
    width: calc(100% - 72px); }
    [dir="rtl"] .mdc-list-group .mdc-list-divider--inset,
    .mdc-list-group[dir="rtl"] .mdc-list-divider--inset {
      margin-left: 0;
      margin-right: 72px; }

  .mdc-list-divider--inset.mdc-list-divider--padded {
    width: calc(100% - 72px - 16px); }

  .mdc-list-group--theme-dark .mdc-list-group__subheader,
  .mdc-theme--dark .mdc-list-group .mdc-list-group__subheader {
    /* @alternate */
    color: white;
    color: var(--mdc-theme-text-primary-on-dark, white); }

  .mdc-list-group__subheader {
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 500;
    letter-spacing: 0.04em;
    text-decoration: inherit;
    text-transform: inherit;
    margin: 0.75rem 16px; }

  .mdc-list-group .mdc-list {
    padding: 0; }

  .mdc-list-group__subheader {
    /* @alternate */
    color: rgba(0, 0, 0, 0.87);
    color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87)); }

  /**
   * The css property used for elevation. In most cases this should not be changed. It is exposed
   * as a variable for abstraction / easy use when needing to reference the property directly, for
   * example in a `will-change` rule.
   */
  /**
   * The default duration value for elevation transitions.
   */
  /**
   * The default easing value for elevation transitions.
   */
  /**
   * Applies the correct CSS rules to an element to give it the elevation specified by $z-value.
   * The $z-value must be between 0 and 24.
   * If $color has an alpha channel, it will be ignored and overridden. To increase the opacity of the shadow, use
   * $opacity-boost.
   */
  /**
   * Returns a string that can be used as the value for a `transition` property for elevation.
   * Calling this function directly is useful in situations where a component needs to transition
   * more than one property.
   *
   * ```scss
   * .foo {
   *   transition: mdc-elevation-transition-value(), opacity 100ms ease;
   *   will-change: $mdc-elevation-property, opacity;
   * }
   * ```
   */
  /* TODO(sgomes): Figure out what to do about desktop font sizes. */
  /* TODO(sgomes): Figure out what to do about i18n and i18n font sizes. */
  .mdc-simple-menu {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    display: none;
    position: absolute;
    box-sizing: border-box;
    min-width: 170px;
    max-width: calc(100vw - 32px);
    max-height: calc(100vh - 32px);
    margin: 0;
    padding: 0;
    transform: scale(1);
    transform-origin: top left;
    border-radius: 2px;
    background-color: white;
    opacity: 0;
    white-space: nowrap;
    overflow-x: hidden;
    overflow-y: auto;
    will-change: transform, opacity;
    z-index: 4; }
    .mdc-simple-menu--theme-dark,
    .mdc-theme--dark .mdc-simple-menu {
      background-color: #424242; }
    .mdc-simple-menu:focus {
      outline: none; }
    .mdc-simple-menu--animating-open {
      display: inline-block;
      overflow-y: hidden;
      opacity: 0;
      transform: scale(0.8);
      transition: opacity 0.03s linear, transform 0.12s cubic-bezier(0, 0, 0.2, 1); }
      .mdc-simple-menu--animating-open > .mdc-simple-menu__items {
        transform: scale(1.25); }
    .mdc-simple-menu--open {
      display: inline-block;
      transform: scale(1);
      opacity: 1; }
      .mdc-simple-menu--open > .mdc-simple-menu__items {
        transform: scale(1); }
    .mdc-simple-menu--animating-closed {
      display: inline-block;
      overflow-y: hidden;
      opacity: 0;
      transition: opacity 0.075s linear; }
    .mdc-simple-menu__items {
      box-sizing: border-box;
      overflow-x: hidden;
      overflow-y: auto;
      will-change: transform;
      transform: scale(1); }
      .mdc-simple-menu__items > .mdc-list-item {
        cursor: pointer; }
      .mdc-simple-menu--animating .mdc-simple-menu__items {
        overflow-y: hidden; }
    [dir="rtl"] .mdc-simple-menu {
      transform-origin: top right; }
    .mdc-simple-menu .mdc-list-group,
    .mdc-simple-menu .mdc-list {
      padding: 8px 0; }
    .mdc-simple-menu .mdc-list-item {
      font-family: Roboto, sans-serif;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-size: 1rem;
      line-height: 1.75rem;
      font-weight: 400;
      letter-spacing: 0.04em;
      text-decoration: inherit;
      text-transform: inherit;
      position: relative;
      outline: none;
      color: inherit;
      text-decoration: none;
      user-select: none; }
      .mdc-simple-menu--theme-dark.mdc-simple-menu .mdc-list-item,
      .mdc-theme--dark .mdc-simple-menu .mdc-list-item {
        color: white; }
    .mdc-simple-menu--theme-dark.mdc-simple-menu .mdc-list-divider,
    .mdc-theme--dark .mdc-simple-menu .mdc-list-divider {
      border-color: rgba(255, 255, 255, 0.12); }
    .mdc-simple-menu .mdc-list-item__graphic {
      color: rgba(0, 0, 0, 0.54); }
      .mdc-simple-menu--theme-dark.mdc-simple-menu .mdc-list-item__graphic,
      .mdc-theme--dark .mdc-simple-menu .mdc-list-item__graphic {
        color: rgba(255, 255, 255, 0.54); }
    .mdc-simple-menu .mdc-list-item[aria-disabled="true"] {
      /* @alternate */
      color: rgba(0, 0, 0, 0.38);
      color: var(--mdc-theme-text-disabled-on-light, rgba(0, 0, 0, 0.38));
      cursor: default;
      pointer-events: none; }
      .mdc-simple-menu--theme-dark .mdc-simple-menu .mdc-list-item[aria-disabled="true"],
      .mdc-theme--dark .mdc-simple-menu .mdc-list-item[aria-disabled="true"] {
        /* @alternate */
        color: rgba(255, 255, 255, 0.5);
        color: var(--mdc-theme-text-disabled-on-dark, rgba(255, 255, 255, 0.5)); }
      .mdc-simple-menu .mdc-list-item[aria-disabled="true"]:focus::before {
        opacity: 0; }

  .mdc-menu-anchor {
    position: relative;
    overflow: visible; }

  .mdc-radio {
    --mdc-ripple-fg-size: 0;
    --mdc-ripple-left: 0;
    --mdc-ripple-top: 0;
    --mdc-ripple-fg-scale: 1;
    --mdc-ripple-fg-translate-end: 0;
    --mdc-ripple-fg-translate-start: 0;
    -webkit-tap-highlight-color: transparent;
    will-change: transform, opacity;
    display: inline-block;
    position: relative;
    flex: 0 0 auto;
    box-sizing: border-box;
    width: 40px;
    height: 40px;
    padding: 10px;
    cursor: pointer;
    will-change: opacity, transform, border-color, background-color, color; }
    .mdc-radio .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle {
      /* @alternate */
      border-color: rgba(0, 0, 0, 0.54);
      border-color: var(--mdc-theme-text-secondary-on-light, rgba(0, 0, 0, 0.54)); }
    .mdc-radio--theme-dark .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle,
    .mdc-theme--dark .mdc-radio .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle {
      /* @alternate */
      border-color: rgba(255, 255, 255, 0.7);
      border-color: var(--mdc-theme-text-secondary-on-dark, rgba(255, 255, 255, 0.7)); }
    .mdc-radio .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle {
      /* @alternate */
      border-color: #018786;
      border-color: var(--mdc-theme-secondary, #018786); }
    .mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle {
      /* @alternate */
      background-color: #018786;
      background-color: var(--mdc-theme-secondary, #018786); }
    .mdc-radio .mdc-radio__background::before {
      /* @alternate */
      background-color: #018786; }
      @supports not (-ms-ime-align: auto) {
        .mdc-radio .mdc-radio__background::before {
          background-color: var(--mdc-theme-secondary, #018786); } }
    .mdc-radio::before, .mdc-radio::after {
      position: absolute;
      border-radius: 50%;
      opacity: 0;
      pointer-events: none;
      content: ""; }
    .mdc-radio::before {
      transition: opacity 15ms linear; }
    .mdc-radio.mdc-ripple-upgraded::after {
      top: 0;
      left: 0;
      transform: scale(0);
      transform-origin: center center; }
    .mdc-radio.mdc-ripple-upgraded--unbounded::after {
      top: var(--mdc-ripple-top, 0);
      left: var(--mdc-ripple-left, 0); }
    .mdc-radio.mdc-ripple-upgraded--foreground-activation::after {
      animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards; }
    .mdc-radio.mdc-ripple-upgraded--foreground-deactivation::after {
      animation: 150ms mdc-ripple-fg-opacity-out;
      transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
    .mdc-radio::before, .mdc-radio::after {
      top: calc(50% - 50%);
      left: calc(50% - 50%);
      width: 100%;
      height: 100%; }
    .mdc-radio.mdc-ripple-upgraded::before {
      top: calc(50% - 50%);
      left: calc(50% - 50%);
      width: 100%;
      height: 100%;
      transform: scale(var(--mdc-ripple-fg-scale, 0)); }
    .mdc-radio.mdc-ripple-upgraded--unbounded::before {
      top: var(--mdc-ripple-top, calc(50% - 25%));
      left: var(--mdc-ripple-left, calc(50% - 25%));
      width: var(--mdc-ripple-fg-size, 50%);
      height: var(--mdc-ripple-fg-size, 50%);
      transform: scale(var(--mdc-ripple-fg-scale, 0)); }
    .mdc-radio.mdc-ripple-upgraded::after {
      width: var(--mdc-ripple-fg-size, 50%);
      height: var(--mdc-ripple-fg-size, 50%); }
    .mdc-radio::before, .mdc-radio::after {
      /* @alternate */
      background-color: #018786; }
      @supports not (-ms-ime-align: auto) {
        .mdc-radio::before, .mdc-radio::after {
          background-color: var(--mdc-theme-secondary, #018786); } }
    .mdc-radio:hover::before {
      opacity: 0.04; }
    .mdc-radio:not(.mdc-ripple-upgraded):focus::before, .mdc-radio.mdc-ripple-upgraded--background-focused::before {
      transition-duration: 75ms;
      opacity: 0.12; }
    .mdc-radio:not(.mdc-ripple-upgraded)::after {
      transition: opacity 150ms linear; }
    .mdc-radio:not(.mdc-ripple-upgraded):active::after {
      transition-duration: 75ms;
      opacity: 0.16; }
    .mdc-radio.mdc-ripple-upgraded {
      --mdc-ripple-fg-opacity: 0.16; }
    .mdc-radio.mdc-ripple-upgraded .mdc-radio__background::before {
      content: none; }
    .mdc-radio__background {
      display: inline-block;
      position: absolute;
      left: 10px;
      box-sizing: border-box;
      width: 50%;
      height: 50%; }
      .mdc-radio__background::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: scale(0, 0);
        transition: opacity 120ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 120ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
        border-radius: 50%;
        opacity: 0;
        pointer-events: none;
        content: ""; }
    .mdc-radio__outer-circle {
      position: absolute;
      top: 0;
      left: 0;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      transition: border-color 120ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
      border-width: 2px;
      border-style: solid;
      border-radius: 50%; }
    .mdc-radio__inner-circle {
      position: absolute;
      top: 0;
      left: 0;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      transform: scale(0, 0);
      transition: transform 120ms 0ms cubic-bezier(0.4, 0, 0.6, 1), background-color 120ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
      border-radius: 50%; }
    .mdc-radio__native-control {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      opacity: 0;
      cursor: inherit;
      z-index: 1; }

  .mdc-radio__native-control:checked + .mdc-radio__background,
  .mdc-radio__native-control:disabled + .mdc-radio__background {
    transition: opacity 120ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 120ms 0ms cubic-bezier(0, 0, 0.2, 1); }
    .mdc-radio__native-control:checked + .mdc-radio__background .mdc-radio__outer-circle,
    .mdc-radio__native-control:disabled + .mdc-radio__background .mdc-radio__outer-circle {
      transition: border-color 120ms 0ms cubic-bezier(0, 0, 0.2, 1); }
    .mdc-radio__native-control:checked + .mdc-radio__background .mdc-radio__inner-circle,
    .mdc-radio__native-control:disabled + .mdc-radio__background .mdc-radio__inner-circle {
      transition: transform 120ms 0ms cubic-bezier(0, 0, 0.2, 1), background-color 120ms 0ms cubic-bezier(0, 0, 0.2, 1); }

  .mdc-radio--disabled {
    cursor: default;
    pointer-events: none; }

  .mdc-radio__native-control:checked + .mdc-radio__background .mdc-radio__inner-circle {
    transform: scale(0.5);
    transition: transform 120ms 0ms cubic-bezier(0, 0, 0.2, 1), background-color 120ms 0ms cubic-bezier(0, 0, 0.2, 1); }

  .mdc-radio__native-control:disabled + .mdc-radio__background,
  [aria-disabled="true"] .mdc-radio__native-control + .mdc-radio__background {
    cursor: default; }
    .mdc-radio__native-control:disabled + .mdc-radio__background .mdc-radio__outer-circle,
    [aria-disabled="true"] .mdc-radio__native-control + .mdc-radio__background .mdc-radio__outer-circle {
      border-color: rgba(0, 0, 0, 0.26); }
      .mdc-radio--theme-dark .mdc-radio__native-control:disabled + .mdc-radio__background .mdc-radio__outer-circle,
      .mdc-theme--dark .mdc-radio__native-control:disabled + .mdc-radio__background .mdc-radio__outer-circle, .mdc-radio--theme-dark
      [aria-disabled="true"] .mdc-radio__native-control + .mdc-radio__background .mdc-radio__outer-circle,
      .mdc-theme--dark
      [aria-disabled="true"] .mdc-radio__native-control + .mdc-radio__background .mdc-radio__outer-circle {
        border-color: rgba(255, 255, 255, 0.3); }
    .mdc-radio__native-control:disabled + .mdc-radio__background .mdc-radio__inner-circle,
    [aria-disabled="true"] .mdc-radio__native-control + .mdc-radio__background .mdc-radio__inner-circle {
      background-color: rgba(0, 0, 0, 0.26); }
      .mdc-radio--theme-dark .mdc-radio__native-control:disabled + .mdc-radio__background .mdc-radio__inner-circle,
      .mdc-theme--dark .mdc-radio__native-control:disabled + .mdc-radio__background .mdc-radio__inner-circle, .mdc-radio--theme-dark
      [aria-disabled="true"] .mdc-radio__native-control + .mdc-radio__background .mdc-radio__inner-circle,
      .mdc-theme--dark
      [aria-disabled="true"] .mdc-radio__native-control + .mdc-radio__background .mdc-radio__inner-circle {
        background-color: rgba(255, 255, 255, 0.3); }

  .mdc-radio__native-control:focus + .mdc-radio__background::before {
    transform: scale(2, 2);
    transition: opacity 120ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 120ms 0ms cubic-bezier(0, 0, 0.2, 1);
    opacity: .26; }

  .mdc-ripple-surface {
    --mdc-ripple-fg-size: 0;
    --mdc-ripple-left: 0;
    --mdc-ripple-top: 0;
    --mdc-ripple-fg-scale: 1;
    --mdc-ripple-fg-translate-end: 0;
    --mdc-ripple-fg-translate-start: 0;
    -webkit-tap-highlight-color: transparent;
    will-change: transform, opacity;
    position: relative;
    outline: none;
    overflow: hidden; }
    .mdc-ripple-surface::before, .mdc-ripple-surface::after {
      position: absolute;
      border-radius: 50%;
      opacity: 0;
      pointer-events: none;
      content: ""; }
    .mdc-ripple-surface::before {
      transition: opacity 15ms linear; }
    .mdc-ripple-surface.mdc-ripple-upgraded::after {
      top: 0;
      left: 0;
      transform: scale(0);
      transform-origin: center center; }
    .mdc-ripple-surface.mdc-ripple-upgraded--unbounded::after {
      top: var(--mdc-ripple-top, 0);
      left: var(--mdc-ripple-left, 0); }
    .mdc-ripple-surface.mdc-ripple-upgraded--foreground-activation::after {
      animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards; }
    .mdc-ripple-surface.mdc-ripple-upgraded--foreground-deactivation::after {
      animation: 150ms mdc-ripple-fg-opacity-out;
      transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
    .mdc-ripple-surface::before, .mdc-ripple-surface::after {
      background-color: black; }
    .mdc-ripple-surface:hover::before {
      opacity: 0.04; }
    .mdc-ripple-surface:not(.mdc-ripple-upgraded):focus::before, .mdc-ripple-surface.mdc-ripple-upgraded--background-focused::before {
      transition-duration: 75ms;
      opacity: 0.12; }
    .mdc-ripple-surface:not(.mdc-ripple-upgraded)::after {
      transition: opacity 150ms linear; }
    .mdc-ripple-surface:not(.mdc-ripple-upgraded):active::after {
      transition-duration: 75ms;
      opacity: 0.16; }
    .mdc-ripple-surface.mdc-ripple-upgraded {
      --mdc-ripple-fg-opacity: 0.16; }
    .mdc-ripple-surface::before, .mdc-ripple-surface::after {
      top: calc(50% - 100%);
      left: calc(50% - 100%);
      width: 200%;
      height: 200%; }
    .mdc-ripple-surface.mdc-ripple-upgraded::before {
      top: calc(50% - 100%);
      left: calc(50% - 100%);
      width: 200%;
      height: 200%;
      transform: scale(var(--mdc-ripple-fg-scale, 0)); }
    .mdc-ripple-surface.mdc-ripple-upgraded--unbounded::before {
      top: var(--mdc-ripple-top, calc(50% - 50%));
      left: var(--mdc-ripple-left, calc(50% - 50%));
      width: var(--mdc-ripple-fg-size, 100%);
      height: var(--mdc-ripple-fg-size, 100%);
      transform: scale(var(--mdc-ripple-fg-scale, 0)); }
    .mdc-ripple-surface.mdc-ripple-upgraded::after {
      width: var(--mdc-ripple-fg-size, 100%);
      height: var(--mdc-ripple-fg-size, 100%); }
    .mdc-ripple-surface[data-mdc-ripple-is-unbounded] {
      overflow: visible; }
    .mdc-ripple-surface--primary::before, .mdc-ripple-surface--primary::after {
      /* @alternate */
      background-color: #6200ee; }
      @supports not (-ms-ime-align: auto) {
        .mdc-ripple-surface--primary::before, .mdc-ripple-surface--primary::after {
          background-color: var(--mdc-theme-primary, #6200ee); } }
    .mdc-ripple-surface--primary:hover::before {
      opacity: 0.04; }
    .mdc-ripple-surface--primary:not(.mdc-ripple-upgraded):focus::before, .mdc-ripple-surface--primary.mdc-ripple-upgraded--background-focused::before {
      transition-duration: 75ms;
      opacity: 0.12; }
    .mdc-ripple-surface--primary:not(.mdc-ripple-upgraded)::after {
      transition: opacity 150ms linear; }
    .mdc-ripple-surface--primary:not(.mdc-ripple-upgraded):active::after {
      transition-duration: 75ms;
      opacity: 0.16; }
    .mdc-ripple-surface--primary.mdc-ripple-upgraded {
      --mdc-ripple-fg-opacity: 0.16; }
    .mdc-ripple-surface--accent::before, .mdc-ripple-surface--accent::after {
      /* @alternate */
      background-color: #018786; }
      @supports not (-ms-ime-align: auto) {
        .mdc-ripple-surface--accent::before, .mdc-ripple-surface--accent::after {
          background-color: var(--mdc-theme-secondary, #018786); } }
    .mdc-ripple-surface--accent:hover::before {
      opacity: 0.04; }
    .mdc-ripple-surface--accent:not(.mdc-ripple-upgraded):focus::before, .mdc-ripple-surface--accent.mdc-ripple-upgraded--background-focused::before {
      transition-duration: 75ms;
      opacity: 0.12; }
    .mdc-ripple-surface--accent:not(.mdc-ripple-upgraded)::after {
      transition: opacity 150ms linear; }
    .mdc-ripple-surface--accent:not(.mdc-ripple-upgraded):active::after {
      transition-duration: 75ms;
      opacity: 0.16; }
    .mdc-ripple-surface--accent.mdc-ripple-upgraded {
      --mdc-ripple-fg-opacity: 0.16; }

  /* TODO(sgomes): Figure out what to do about desktop font sizes. */
  /* TODO(sgomes): Figure out what to do about i18n and i18n font sizes. */
  /**
   * Creates a rule that will be applied when an MDC-Web component is within the context of an RTL layout.
   *
   * Usage Example:
   * ```scss
   * .mdc-foo {
   *   position: absolute;
   *   left: 0;
   *
   *   @include mdc-rtl {
   *     left: auto;
   *     right: 0;
   *   }
   *
   *   &__bar {
   *     margin-left: 4px;
   *     @include mdc-rtl(".mdc-foo") {
   *       margin-left: auto;
   *       margin-right: 4px;
   *     }
   *   }
   * }
   *
   * .mdc-foo--mod {
   *   padding-left: 4px;
   *
   *   @include mdc-rtl {
   *     padding-left: auto;
   *     padding-right: 4px;
   *   }
   * }
   * ```
   *
   * Note that this works by checking for [dir="rtl"] on an ancestor element. While this will work
   * in most cases, it will in some cases lead to false negatives, e.g.
   *
   * ```html
   * <html dir="rtl">
   *   <!-- ... -->
   *   <div dir="ltr">
   *     <div class="mdc-foo">Styled incorrectly as RTL!</div>
   *   </div>
   * </html>
   * ```
   *
   * In the future, selectors such as :dir (http://mdn.io/:dir) will help us mitigate this.
   */
  /**
   * Takes a base box-model property - e.g. margin / border / padding - along with a default
   * direction and value, and emits rules which apply the value to the
   * "<base-property>-<default-direction>" property by default, but flips the direction
   * when within an RTL context.
   *
   * For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-box(margin, left, 8px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-left: 8px;
   *
   *   @include mdc-rtl {
   *     margin-right: 8px;
   *     margin-left: 0;
   *   }
   * }
   * ```
   * whereas:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-box(margin, right, 8px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-right: 8px;
   *
   *   @include mdc-rtl {
   *     margin-right: 0;
   *     margin-left: 8px;
   *   }
   * }
   * ```
   *
   * You can also pass a 4th optional $root-selector argument which will be forwarded to `mdc-rtl`,
   * e.g. `@include mdc-rtl-reflexive-box(margin, left, 8px, ".mdc-component")`.
   *
   * Note that this function will always zero out the original value in an RTL context. If you're
   * trying to flip the values, use mdc-rtl-reflexive-property().
   */
  /**
   * Takes a base property and emits rules that assign <base-property>-left to <left-value> and
   * <base-property>-right to <right-value> in a LTR context, and vice versa in a RTL context.
   * For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-property(margin, auto, 12px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-left: auto;
   *   margin-right: 12px;
   *
   *   @include mdc-rtl {
   *     margin-left: 12px;
   *     margin-right: auto;
   *   }
   * }
   * ```
   *
   * A 4th optional $root-selector argument can be given, which will be passed to `mdc-rtl`.
   */
  /**
   * Takes an argument specifying a horizontal position property (either "left" or "right") as well
   * as a value, and applies that value to the specified position in a LTR context, and flips it in a
   * RTL context. For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-position(left, 0);
   *   position: absolute;
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   *  .mdc-foo {
   *    position: absolute;
   *    left: 0;
   *    right: initial;
   *
   *    @include mdc-rtl {
   *      right: 0;
   *      left: initial;
   *    }
   *  }
   * ```
   * An optional third $root-selector argument may also be given, which is passed to `mdc-rtl`.
   */
  .mdc-select {
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 1rem;
    line-height: 1.75rem;
    font-weight: 400;
    letter-spacing: 0.04em;
    text-decoration: inherit;
    text-transform: inherit;
    /* @alternate */
    color: rgba(0, 0, 0, 0.87);
    color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87));
    background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%230%22%20fill-rule%3D%22evenodd%22%20opacity%3D%220.54%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E);
    display: inline-flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    height: 56px;
    border: none;
    border-radius: 4px 4px 0 0;
    outline: none;
    background-repeat: no-repeat;
    background-position: right 10px center;
    cursor: pointer;
    overflow: visible; }
    [dir="rtl"] .mdc-select, .mdc-select[dir="rtl"] {
      background-position: left 10px center; }
    .mdc-select--theme-dark .mdc-select,
    .mdc-theme--dark .mdc-select {
      background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%23fff%22%20fill-rule%3D%22evenodd%22%20opacity%3D%220.54%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E);
      background-color: rgba(255, 255, 255, 0.1); }
    .mdc-select__menu {
      position: fixed;
      top: 0;
      left: 0;
      max-height: 100%;
      transform-origin: center center;
      z-index: 4; }
    .mdc-select__surface {
      font-family: Roboto, sans-serif;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-size: 1rem;
      line-height: 1.75rem;
      font-weight: 400;
      letter-spacing: 0.04em;
      text-decoration: inherit;
      text-transform: inherit;
      /* @alternate */
      color: rgba(0, 0, 0, 0.87);
      color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87));
      padding-left: 16px;
      padding-right: 26px;
      --mdc-ripple-fg-size: 0;
      --mdc-ripple-left: 0;
      --mdc-ripple-top: 0;
      --mdc-ripple-fg-scale: 1;
      --mdc-ripple-fg-translate-end: 0;
      --mdc-ripple-fg-translate-start: 0;
      -webkit-tap-highlight-color: transparent;
      will-change: transform, opacity;
      display: flex;
      position: relative;
      flex-grow: 1;
      width: 100%;
      height: 56px;
      border: none;
      border-radius: 4px 4px 0 0;
      outline: none;
      background-color: rgba(0, 0, 0, 0.04);
      appearance: none;
      overflow: hidden; }
      [dir="rtl"] .mdc-select .mdc-select__surface,
      .mdc-select[dir="rtl"] .mdc-select__surface {
        padding-left: 26px;
        padding-right: 16px; }
      .mdc-select__surface::before, .mdc-select__surface::after {
        position: absolute;
        border-radius: 50%;
        opacity: 0;
        pointer-events: none;
        content: ""; }
      .mdc-select__surface::before {
        transition: opacity 15ms linear; }
      .mdc-select__surface.mdc-ripple-upgraded::after {
        top: 0;
        left: 0;
        transform: scale(0);
        transform-origin: center center; }
      .mdc-select__surface.mdc-ripple-upgraded--unbounded::after {
        top: var(--mdc-ripple-top, 0);
        left: var(--mdc-ripple-left, 0); }
      .mdc-select__surface.mdc-ripple-upgraded--foreground-activation::after {
        animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards; }
      .mdc-select__surface.mdc-ripple-upgraded--foreground-deactivation::after {
        animation: 150ms mdc-ripple-fg-opacity-out;
        transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
      .mdc-select__surface::before, .mdc-select__surface::after {
        top: calc(50% - 100%);
        left: calc(50% - 100%);
        width: 200%;
        height: 200%; }
      .mdc-select__surface.mdc-ripple-upgraded::before {
        top: calc(50% - 100%);
        left: calc(50% - 100%);
        width: 200%;
        height: 200%;
        transform: scale(var(--mdc-ripple-fg-scale, 0)); }
      .mdc-select__surface.mdc-ripple-upgraded--unbounded::before {
        top: var(--mdc-ripple-top, calc(50% - 50%));
        left: var(--mdc-ripple-left, calc(50% - 50%));
        width: var(--mdc-ripple-fg-size, 100%);
        height: var(--mdc-ripple-fg-size, 100%);
        transform: scale(var(--mdc-ripple-fg-scale, 0)); }
      .mdc-select__surface.mdc-ripple-upgraded::after {
        width: var(--mdc-ripple-fg-size, 100%);
        height: var(--mdc-ripple-fg-size, 100%); }
      .mdc-select__surface::before, .mdc-select__surface::after {
        background-color: black; }
      .mdc-select__surface:hover::before {
        opacity: 0.04; }
      .mdc-select__surface:not(.mdc-ripple-upgraded):focus::before, .mdc-select__surface.mdc-ripple-upgraded--background-focused::before {
        transition-duration: 75ms;
        opacity: 0.12; }
      .mdc-select__surface:not(.mdc-ripple-upgraded)::after {
        transition: opacity 150ms linear; }
      .mdc-select__surface:not(.mdc-ripple-upgraded):active::after {
        transition-duration: 75ms;
        opacity: 0.16; }
      .mdc-select__surface.mdc-ripple-upgraded {
        --mdc-ripple-fg-opacity: 0.16; }
      .mdc-select--theme-dark .mdc-select__surface::before, .mdc-select--theme-dark .mdc-select__surface::after,
      .mdc-theme--dark .mdc-select__surface::before,
      .mdc-theme--dark .mdc-select__surface::after {
        background-color: white; }
      .mdc-select--theme-dark .mdc-select__surface:hover::before,
      .mdc-theme--dark .mdc-select__surface:hover::before {
        opacity: 0.08; }
      .mdc-select--theme-dark .mdc-select__surface:not(.mdc-ripple-upgraded):focus::before, .mdc-select--theme-dark .mdc-select__surface.mdc-ripple-upgraded--background-focused::before,
      .mdc-theme--dark .mdc-select__surface:not(.mdc-ripple-upgraded):focus::before,
      .mdc-theme--dark .mdc-select__surface.mdc-ripple-upgraded--background-focused::before {
        transition-duration: 75ms;
        opacity: 0.24; }
      .mdc-select--theme-dark .mdc-select__surface:not(.mdc-ripple-upgraded)::after,
      .mdc-theme--dark .mdc-select__surface:not(.mdc-ripple-upgraded)::after {
        transition: opacity 150ms linear; }
      .mdc-select--theme-dark .mdc-select__surface:not(.mdc-ripple-upgraded):active::after,
      .mdc-theme--dark .mdc-select__surface:not(.mdc-ripple-upgraded):active::after {
        transition-duration: 75ms;
        opacity: 0.32; }
      .mdc-select--theme-dark .mdc-select__surface.mdc-ripple-upgraded,
      .mdc-theme--dark .mdc-select__surface.mdc-ripple-upgraded {
        --mdc-ripple-fg-opacity: 0.32; }
      .mdc-select__surface::-ms-expand {
        display: none; }
    .mdc-select__label {
      left: 16px;
      right: initial;
      position: absolute;
      bottom: 12px;
      transform-origin: left top;
      transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1);
      color: rgba(0, 0, 0, 0.6);
      pointer-events: none;
      will-change: transform; }
      [dir="rtl"] .mdc-select__label, .mdc-select__label[dir="rtl"] {
        left: initial;
        right: 16px; }
      .mdc-select--theme-dark .mdc-select__label,
      .mdc-theme--dark .mdc-select__label {
        /* @alternate */
        color: rgba(255, 255, 255, 0.7);
        color: var(--mdc-theme-text-secondary-on-dark, rgba(255, 255, 255, 0.7)); }
      [dir="rtl"] .mdc-select .mdc-select__label,
      .mdc-select[dir="rtl"] .mdc-select__label {
        transform-origin: right top; }
      .mdc-select__label--float-above {
        transform: translateY(-40%) scale(0.75, 0.75); }
    .mdc-select__selected-text {
      display: flex;
      align-items: flex-end;
      margin-bottom: 6px;
      transition: opacity 125ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 125ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
      white-space: nowrap;
      overflow: hidden; }
      .mdc-select--theme-dark .mdc-select__selected-text,
      .mdc-theme--dark .mdc-select__selected-text {
        /* @alternate */
        color: rgba(255, 255, 255, 0.7);
        color: var(--mdc-theme-text-secondary-on-dark, rgba(255, 255, 255, 0.7)); }
    .mdc-select__bottom-line {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      transform: scaleY(1);
      transform-origin: bottom;
      transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1);
      background-color: rgba(0, 0, 0, 0.5); }
      .mdc-select__bottom-line::after {
        /* @alternate */
        background-color: #6200ee;
        background-color: var(--mdc-theme-primary, #6200ee);
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        height: 2px;
        transform: scaleX(0);
        transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1);
        opacity: 0;
        content: "";
        z-index: 2; }
    .mdc-select__bottom-line--active::after {
      transform: scaleX(1);
      opacity: 1; }
    .mdc-select__surface:focus .mdc-select__bottom-line,
    .mdc-select__surface:focus ~ .mdc-select__bottom-line {
      /* @alternate */
      background-color: #6200ee;
      background-color: var(--mdc-theme-primary, #6200ee);
      transform: scaleY(2); }
      .mdc-select__surface:focus .mdc-select__bottom-line::after,
      .mdc-select__surface:focus ~ .mdc-select__bottom-line::after {
        opacity: 1; }

  .mdc-select--open .mdc-select__surface::before {
    opacity: 0.12; }
    .mdc-select--theme-dark .mdc-select--open .mdc-select__surface::before,
    .mdc-theme--dark .mdc-select--open .mdc-select__surface::before {
      opacity: 0.24; }

  .mdc-select--open .mdc-select__selected-text {
    transform: translateY(8px);
    transition: opacity 125ms 125ms cubic-bezier(0, 0, 0.2, 1), transform 125ms 125ms cubic-bezier(0, 0, 0.2, 1);
    opacity: 0; }

  .mdc-select--open .mdc-select__bottom-line {
    /* @alternate */
    background-color: #6200ee;
    background-color: var(--mdc-theme-primary, #6200ee);
    transform: scaleY(2); }
    .mdc-select--open .mdc-select__bottom-line::after {
      opacity: 1; }

  .mdc-select--disabled,
  .mdc-select[disabled] {
    /* @alternate */
    color: rgba(0, 0, 0, 0.38);
    color: var(--mdc-theme-text-disabled-on-light, rgba(0, 0, 0, 0.38));
    background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%230%22%20fill-rule%3D%22evenodd%22%20opacity%3D%220.38%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E);
    border-bottom-width: 1px;
    border-bottom-style: dotted;
    opacity: .38;
    cursor: default;
    pointer-events: none;
    user-select: none; }
    .mdc-select--disabled .mdc-select__bottom-line,
    .mdc-select[disabled] .mdc-select__bottom-line {
      display: none; }

  .mdc-select--theme-dark.mdc-select--disabled,
  .mdc-theme--dark .mdc-select--disabled {
    /* @alternate */
    color: rgba(255, 255, 255, 0.5);
    color: var(--mdc-theme-text-disabled-on-dark, rgba(255, 255, 255, 0.5));
    background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%23ffffff%22%20fill-rule%3D%22evenodd%22%20opacity%3D%220.38%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E);
    border-bottom: 1px dotted rgba(255, 255, 255, 0.38); }

  .mdc-select--theme-dark.mdc-select[disabled],
  .mdc-theme--dark .mdc-select[disabled] {
    /* @alternate */
    color: rgba(255, 255, 255, 0.5);
    color: var(--mdc-theme-text-disabled-on-dark, rgba(255, 255, 255, 0.5));
    background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%23ffffff%22%20fill-rule%3D%22evenodd%22%20opacity%3D%220.38%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E);
    border-bottom: 1px dotted rgba(255, 255, 255, 0.38); }

  .mdc-select__menu .mdc-list-item {
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 1rem;
    line-height: 1.75rem;
    font-weight: 400;
    letter-spacing: 0.04em;
    text-decoration: inherit;
    text-transform: inherit;
    /* @alternate */
    color: rgba(0, 0, 0, 0.54);
    color: var(--mdc-theme-text-secondary-on-light, rgba(0, 0, 0, 0.54)); }
    .mdc-select__menu .mdc-list-item[aria-selected="true"] {
      /* @alternate */
      color: rgba(0, 0, 0, 0.87);
      color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87)); }
    .mdc-select--theme-dark .mdc-select__menu .mdc-list-item,
    .mdc-theme--dark .mdc-select__menu .mdc-list-item {
      /* @alternate */
      color: rgba(255, 255, 255, 0.7);
      color: var(--mdc-theme-text-secondary-on-dark, rgba(255, 255, 255, 0.7)); }
      .mdc-select--theme-dark .mdc-select__menu .mdc-list-item[aria-selected="true"],
      .mdc-theme--dark .mdc-select__menu .mdc-list-item[aria-selected="true"] {
        /* @alternate */
        color: white;
        color: var(--mdc-theme-text-primary-on-dark, white); }
    .mdc-select__menu .mdc-list-item::before, .mdc-select__menu .mdc-list-item::after {
      top: calc(50% - 50%);
      left: calc(50% - 50%);
      width: 100%;
      height: 100%; }
    .mdc-select__menu .mdc-list-item.mdc-ripple-upgraded::before {
      top: calc(50% - 50%);
      left: calc(50% - 50%);
      width: 100%;
      height: 100%;
      transform: scale(var(--mdc-ripple-fg-scale, 0)); }
    .mdc-select__menu .mdc-list-item.mdc-ripple-upgraded--unbounded::before {
      top: var(--mdc-ripple-top, calc(50% - 25%));
      left: var(--mdc-ripple-left, calc(50% - 25%));
      width: var(--mdc-ripple-fg-size, 50%);
      height: var(--mdc-ripple-fg-size, 50%);
      transform: scale(var(--mdc-ripple-fg-scale, 0)); }
    .mdc-select__menu .mdc-list-item.mdc-ripple-upgraded::after {
      width: var(--mdc-ripple-fg-size, 50%);
      height: var(--mdc-ripple-fg-size, 50%); }
    .mdc-select__menu .mdc-list-item::before, .mdc-select__menu .mdc-list-item::after {
      border-radius: 0; }

  .mdc-select__menu .mdc-list-group,
  .mdc-select__menu .mdc-list-group > .mdc-list-item:first-child {
    margin-top: 12px; }

  .mdc-select__menu .mdc-list-group {
    /* @alternate */
    color: rgba(0, 0, 0, 0.38);
    color: var(--mdc-theme-text-hint-on-light, rgba(0, 0, 0, 0.38));
    font-weight: normal; }
    .mdc-select__menu .mdc-list-group .mdc-list-item {
      /* @alternate */
      color: rgba(0, 0, 0, 0.87);
      color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87)); }

  .mdc-select--theme-dark .mdc-select__menu .mdc-list-group,
  .mdc-theme--dark .mdc-select__menu .mdc-list-group {
    /* @alternate */
    color: rgba(255, 255, 255, 0.5);
    color: var(--mdc-theme-text-hint-on-dark, rgba(255, 255, 255, 0.5)); }
    .mdc-select--theme-dark .mdc-select__menu .mdc-list-group .mdc-list-item,
    .mdc-theme--dark .mdc-select__menu .mdc-list-group .mdc-list-item {
      /* @alternate */
      color: white;
      color: var(--mdc-theme-text-primary-on-dark, white); }

  .mdc-multi-select {
    /* @alternate */
    border-color: rgba(0, 0, 0, 0.38);
    border-color: var(--mdc-theme-text-hint-on-light, rgba(0, 0, 0, 0.38));
    width: 250px;
    padding: 0;
    border-width: 1px;
    border-style: solid;
    outline: none;
    appearance: none; }
    .mdc-multi-select--theme-dark,
    .mdc-theme--dark .mdc-multi-select {
      /* @alternate */
      border-color: rgba(255, 255, 255, 0.5);
      border-color: var(--mdc-theme-text-hint-on-dark, rgba(255, 255, 255, 0.5)); }
    .mdc-multi-select .mdc-list-group {
      /* @alternate */
      color: rgba(0, 0, 0, 0.38);
      color: var(--mdc-theme-text-hint-on-light, rgba(0, 0, 0, 0.38));
      margin: 16px 0 0;
      padding: 0 0 0 16px;
      font-weight: normal; }
      .mdc-multi-select .mdc-list-group--theme-dark,
      .mdc-theme--dark .mdc-multi-select .mdc-list-group {
        /* @alternate */
        color: rgba(255, 255, 255, 0.5);
        color: var(--mdc-theme-text-hint-on-dark, rgba(255, 255, 255, 0.5)); }
      .mdc-multi-select .mdc-list-group:last-child {
        margin-bottom: 16px; }
      .mdc-multi-select .mdc-list-group .mdc-list-divider {
        margin-left: -16px; }
    .mdc-multi-select .mdc-list-item {
      /* @alternate */
      color: rgba(0, 0, 0, 0.87);
      color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87));
      margin: 0 0 0 -16px;
      padding: 0 16px; }
      .mdc-multi-select .mdc-list-item--theme-dark,
      .mdc-theme--dark .mdc-multi-select .mdc-list-item {
        /* @alternate */
        color: white;
        color: var(--mdc-theme-text-primary-on-dark, white); }
      .mdc-multi-select .mdc-list-item:first-child {
        margin-top: 12px; }
      .mdc-multi-select .mdc-list-item:last-child {
        margin-bottom: 8px; }
      .mdc-multi-select .mdc-list-item::before, .mdc-multi-select .mdc-list-item::after {
        content: none; }
    .mdc-multi-select .mdc-list-item:checked {
      /* @alternate */
      background-color: #fff;
      background-color: var(--mdc-theme-background, #fff); }
      .mdc-multi-select .mdc-list-item:checked--theme-dark,
      .mdc-theme--dark .mdc-multi-select .mdc-list-item:checked {
        /* @alternate */
        background-color: white;
        background-color: var(--mdc-theme-text-primary-on-dark, white); }
    .mdc-multi-select .mdc-list-divider {
      margin-bottom: 8px;
      padding-top: 8px;
      font-size: 0; }

  .mdc-multi-select:focus .mdc-list-item:checked {
    /* @alternate */
    background-color: #6200ee;
    background-color: var(--mdc-theme-primary, #6200ee); }
    .mdc-multi-select:focus .mdc-list-item:checked--theme-dark,
    .mdc-theme--dark .mdc-multi-select:focus .mdc-list-item:checked {
      /* @alternate */
      background-color: white;
      background-color: var(--mdc-theme-text-primary-on-dark, white); }

  .mdc-select-scroll-lock {
    overflow: hidden; }

  /* TODO(sgomes): Figure out what to do about desktop font sizes. */
  /* TODO(sgomes): Figure out what to do about i18n and i18n font sizes. */
  /**
   * Creates a rule that will be applied when an MDC-Web component is within the context of an RTL layout.
   *
   * Usage Example:
   * ```scss
   * .mdc-foo {
   *   position: absolute;
   *   left: 0;
   *
   *   @include mdc-rtl {
   *     left: auto;
   *     right: 0;
   *   }
   *
   *   &__bar {
   *     margin-left: 4px;
   *     @include mdc-rtl(".mdc-foo") {
   *       margin-left: auto;
   *       margin-right: 4px;
   *     }
   *   }
   * }
   *
   * .mdc-foo--mod {
   *   padding-left: 4px;
   *
   *   @include mdc-rtl {
   *     padding-left: auto;
   *     padding-right: 4px;
   *   }
   * }
   * ```
   *
   * Note that this works by checking for [dir="rtl"] on an ancestor element. While this will work
   * in most cases, it will in some cases lead to false negatives, e.g.
   *
   * ```html
   * <html dir="rtl">
   *   <!-- ... -->
   *   <div dir="ltr">
   *     <div class="mdc-foo">Styled incorrectly as RTL!</div>
   *   </div>
   * </html>
   * ```
   *
   * In the future, selectors such as :dir (http://mdn.io/:dir) will help us mitigate this.
   */
  /**
   * Takes a base box-model property - e.g. margin / border / padding - along with a default
   * direction and value, and emits rules which apply the value to the
   * "<base-property>-<default-direction>" property by default, but flips the direction
   * when within an RTL context.
   *
   * For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-box(margin, left, 8px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-left: 8px;
   *
   *   @include mdc-rtl {
   *     margin-right: 8px;
   *     margin-left: 0;
   *   }
   * }
   * ```
   * whereas:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-box(margin, right, 8px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-right: 8px;
   *
   *   @include mdc-rtl {
   *     margin-right: 0;
   *     margin-left: 8px;
   *   }
   * }
   * ```
   *
   * You can also pass a 4th optional $root-selector argument which will be forwarded to `mdc-rtl`,
   * e.g. `@include mdc-rtl-reflexive-box(margin, left, 8px, ".mdc-component")`.
   *
   * Note that this function will always zero out the original value in an RTL context. If you're
   * trying to flip the values, use mdc-rtl-reflexive-property().
   */
  /**
   * Takes a base property and emits rules that assign <base-property>-left to <left-value> and
   * <base-property>-right to <right-value> in a LTR context, and vice versa in a RTL context.
   * For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-property(margin, auto, 12px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-left: auto;
   *   margin-right: 12px;
   *
   *   @include mdc-rtl {
   *     margin-left: 12px;
   *     margin-right: auto;
   *   }
   * }
   * ```
   *
   * A 4th optional $root-selector argument can be given, which will be passed to `mdc-rtl`.
   */
  /**
   * Takes an argument specifying a horizontal position property (either "left" or "right") as well
   * as a value, and applies that value to the specified position in a LTR context, and flips it in a
   * RTL context. For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-position(left, 0);
   *   position: absolute;
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   *  .mdc-foo {
   *    position: absolute;
   *    left: 0;
   *    right: initial;
   *
   *    @include mdc-rtl {
   *      right: 0;
   *      left: initial;
   *    }
   *  }
   * ```
   * An optional third $root-selector argument may also be given, which is passed to `mdc-rtl`.
   */
  @keyframes mdc-slider-emphasize {
    0% {
      animation-timing-function: ease-out; }
    50% {
      animation-timing-function: ease-in;
      transform: scale(0.85); }
    100% {
      transform: scale(0.571); } }

  .mdc-slider {
    position: relative;
    width: 100%;
    height: 48px;
    cursor: pointer;
    touch-action: pan-x;
    -webkit-tap-highlight-color: transparent; }
    .mdc-slider:not(.mdc-slider--disabled) .mdc-slider__track {
      /* @alternate */
      background-color: #018786;
      background-color: var(--mdc-theme-secondary, #018786); }
    .mdc-slider:not(.mdc-slider--disabled) .mdc-slider__track-container {
      background-color: rgba(1, 135, 134, 0.26); }
    .mdc-slider:not(.mdc-slider--disabled) .mdc-slider__track-marker::after,
    .mdc-slider:not(.mdc-slider--disabled) .mdc-slider__track-marker-container::after {
      /* @alternate */
      background-color: #018786;
      background-color: var(--mdc-theme-secondary, #018786); }
    .mdc-slider:not(.mdc-slider--disabled) .mdc-slider__thumb {
      /* @alternate */
      fill: #018786;
      fill: var(--mdc-theme-secondary, #018786);
      /* @alternate */
      stroke: #018786;
      stroke: var(--mdc-theme-secondary, #018786); }
    .mdc-slider:not(.mdc-slider--disabled) .mdc-slider__focus-ring {
      /* @alternate */
      background-color: #018786;
      background-color: var(--mdc-theme-secondary, #018786); }
    .mdc-slider:not(.mdc-slider--disabled) .mdc-slider__pin {
      /* @alternate */
      background-color: #018786;
      background-color: var(--mdc-theme-secondary, #018786); }
    .mdc-slider:not(.mdc-slider--disabled) .mdc-slider__pin {
      /* @alternate */
      color: white;
      color: var(--mdc-theme-text-primary-on-dark, white); }
    .mdc-slider--disabled .mdc-slider__track {
      background-color: #9a9a9a; }
    .mdc-slider--disabled .mdc-slider__track-container {
      background-color: rgba(154, 154, 154, 0.26); }
    .mdc-slider--disabled .mdc-slider__track-marker::after,
    .mdc-slider--disabled .mdc-slider__track-marker-container::after {
      background-color: #9a9a9a; }
    .mdc-slider--disabled .mdc-slider__thumb {
      fill: #9a9a9a;
      stroke: #9a9a9a; }
    .mdc-slider--disabled .mdc-slider__thumb {
      /* @alternate */
      stroke: white;
      stroke: var(--mdc-slider-bg-color-behind-component, white); }
    .mdc-slider--theme-dark.mdc-slider--disabled .mdc-slider__track,
    .mdc-theme--dark .mdc-slider--disabled .mdc-slider__track {
      background-color: #787878; }
    .mdc-slider--theme-dark.mdc-slider--disabled .mdc-slider__track-container,
    .mdc-theme--dark .mdc-slider--disabled .mdc-slider__track-container {
      background-color: rgba(120, 120, 120, 0.26); }
    .mdc-slider--theme-dark.mdc-slider--disabled .mdc-slider__track-marker::after,
    .mdc-slider--theme-dark.mdc-slider--disabled .mdc-slider__track-marker-container::after,
    .mdc-theme--dark .mdc-slider--disabled .mdc-slider__track-marker::after,
    .mdc-theme--dark .mdc-slider--disabled .mdc-slider__track-marker-container::after {
      background-color: #787878; }
    .mdc-slider--theme-dark.mdc-slider--disabled .mdc-slider__thumb,
    .mdc-theme--dark .mdc-slider--disabled .mdc-slider__thumb {
      fill: #787878;
      stroke: #787878; }
    .mdc-slider--theme-dark.mdc-slider--disabled .mdc-slider__thumb,
    .mdc-theme--dark .mdc-slider--disabled .mdc-slider__thumb {
      /* @alternate */
      stroke: #333;
      stroke: var(--mdc-slider-bg-color-behind-component, #333); }
    .mdc-slider:focus {
      outline: none; }
    .mdc-slider__track-container {
      position: absolute;
      top: 50%;
      width: 100%;
      height: 2px;
      overflow: hidden; }
    .mdc-slider__track {
      position: absolute;
      width: 100%;
      height: 100%;
      transform-origin: left top;
      will-change: transform; }
      [dir="rtl"] .mdc-slider .mdc-slider__track,
      .mdc-slider[dir="rtl"] .mdc-slider__track {
        transform-origin: right top; }
    .mdc-slider__track-marker-container {
      display: flex;
      margin-right: 0;
      margin-left: -1px;
      visibility: hidden; }
      [dir="rtl"] .mdc-slider .mdc-slider__track-marker-container,
      .mdc-slider[dir="rtl"] .mdc-slider__track-marker-container {
        margin-right: -1px;
        margin-left: 0; }
      .mdc-slider__track-marker-container::after {
        display: block;
        width: 2px;
        height: 2px;
        content: ""; }
    .mdc-slider__track-marker {
      flex: 1; }
      .mdc-slider__track-marker::after {
        display: block;
        width: 2px;
        height: 2px;
        content: ""; }
      .mdc-slider__track-marker:first-child::after {
        width: 3px; }
    .mdc-slider__thumb-container {
      position: absolute;
      top: 15px;
      left: 0;
      width: 21px;
      height: 100%;
      user-select: none;
      will-change: transform; }
    .mdc-slider__thumb {
      position: absolute;
      top: 0;
      left: 0;
      transform: scale(0.571);
      transition: transform 100ms ease-out, fill 100ms ease-out, stroke 100ms ease-out;
      stroke-width: 3.5; }
    .mdc-slider__focus-ring {
      width: 21px;
      height: 21px;
      transition: transform 266.67ms ease-out, opacity 266.67ms ease-out, background-color 266.67ms ease-out;
      border-radius: 50%;
      opacity: 0; }
    .mdc-slider__pin {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      align-items: center;
      justify-content: center;
      width: 26px;
      height: 26px;
      margin-top: -2px;
      margin-left: -2px;
      transform: rotate(-45deg) scale(0) translate(0, 0);
      transition: transform 100ms ease-out;
      border-radius: 50% 50% 50% 0%;
      /**
       * Ensuring that the pin is higher than the thumb in the stacking order
       * removes some rendering jank observed in Chrome.
       */
      z-index: 1; }
    .mdc-slider__pin-value-marker {
      font-family: Roboto, sans-serif;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-size: 0.75rem;
      line-height: 1.25rem;
      font-weight: 400;
      letter-spacing: 0.08em;
      text-decoration: inherit;
      text-transform: inherit;
      transform: rotate(45deg); }

  .mdc-slider--active .mdc-slider__thumb {
    transform: scale3d(1, 1, 1); }

  .mdc-slider--focus .mdc-slider__thumb {
    animation: mdc-slider-emphasize 266.67ms linear; }

  .mdc-slider--focus .mdc-slider__focus-ring {
    transform: scale3d(1.55, 1.55, 1.55);
    opacity: .25; }

  .mdc-slider--disabled {
    cursor: auto; }

  .mdc-slider--in-transit .mdc-slider__thumb {
    transition-delay: 140ms; }

  .mdc-slider--in-transit .mdc-slider__thumb-container,
  .mdc-slider--in-transit .mdc-slider__track,
  .mdc-slider:focus:not(.mdc-slider--active) .mdc-slider__thumb-container,
  .mdc-slider:focus:not(.mdc-slider--active) .mdc-slider__track {
    transition: transform 80ms ease; }

  .mdc-slider--discrete.mdc-slider--active .mdc-slider__thumb {
    transform: scale(calc(12 / 21)); }

  .mdc-slider--discrete.mdc-slider--active .mdc-slider__pin {
    transform: rotate(-45deg) scale(1) translate(19px, -20px); }

  .mdc-slider--discrete.mdc-slider--focus .mdc-slider__thumb {
    animation: none; }

  .mdc-slider--discrete.mdc-slider--display-markers .mdc-slider__track-marker-container {
    visibility: visible; }

  /**
   * Creates a rule that will be applied when an MDC-Web component is within the context of an RTL layout.
   *
   * Usage Example:
   * ```scss
   * .mdc-foo {
   *   position: absolute;
   *   left: 0;
   *
   *   @include mdc-rtl {
   *     left: auto;
   *     right: 0;
   *   }
   *
   *   &__bar {
   *     margin-left: 4px;
   *     @include mdc-rtl(".mdc-foo") {
   *       margin-left: auto;
   *       margin-right: 4px;
   *     }
   *   }
   * }
   *
   * .mdc-foo--mod {
   *   padding-left: 4px;
   *
   *   @include mdc-rtl {
   *     padding-left: auto;
   *     padding-right: 4px;
   *   }
   * }
   * ```
   *
   * Note that this works by checking for [dir="rtl"] on an ancestor element. While this will work
   * in most cases, it will in some cases lead to false negatives, e.g.
   *
   * ```html
   * <html dir="rtl">
   *   <!-- ... -->
   *   <div dir="ltr">
   *     <div class="mdc-foo">Styled incorrectly as RTL!</div>
   *   </div>
   * </html>
   * ```
   *
   * In the future, selectors such as :dir (http://mdn.io/:dir) will help us mitigate this.
   */
  /**
   * Takes a base box-model property - e.g. margin / border / padding - along with a default
   * direction and value, and emits rules which apply the value to the
   * "<base-property>-<default-direction>" property by default, but flips the direction
   * when within an RTL context.
   *
   * For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-box(margin, left, 8px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-left: 8px;
   *
   *   @include mdc-rtl {
   *     margin-right: 8px;
   *     margin-left: 0;
   *   }
   * }
   * ```
   * whereas:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-box(margin, right, 8px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-right: 8px;
   *
   *   @include mdc-rtl {
   *     margin-right: 0;
   *     margin-left: 8px;
   *   }
   * }
   * ```
   *
   * You can also pass a 4th optional $root-selector argument which will be forwarded to `mdc-rtl`,
   * e.g. `@include mdc-rtl-reflexive-box(margin, left, 8px, ".mdc-component")`.
   *
   * Note that this function will always zero out the original value in an RTL context. If you're
   * trying to flip the values, use mdc-rtl-reflexive-property().
   */
  /**
   * Takes a base property and emits rules that assign <base-property>-left to <left-value> and
   * <base-property>-right to <right-value> in a LTR context, and vice versa in a RTL context.
   * For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-property(margin, auto, 12px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-left: auto;
   *   margin-right: 12px;
   *
   *   @include mdc-rtl {
   *     margin-left: 12px;
   *     margin-right: auto;
   *   }
   * }
   * ```
   *
   * A 4th optional $root-selector argument can be given, which will be passed to `mdc-rtl`.
   */
  /**
   * Takes an argument specifying a horizontal position property (either "left" or "right") as well
   * as a value, and applies that value to the specified position in a LTR context, and flips it in a
   * RTL context. For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-position(left, 0);
   *   position: absolute;
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   *  .mdc-foo {
   *    position: absolute;
   *    left: 0;
   *    right: initial;
   *
   *    @include mdc-rtl {
   *      right: 0;
   *      left: initial;
   *    }
   *  }
   * ```
   * An optional third $root-selector argument may also be given, which is passed to `mdc-rtl`.
   */
  /* TODO(sgomes): Figure out what to do about desktop font sizes. */
  /* TODO(sgomes): Figure out what to do about i18n and i18n font sizes. */
  /* postcss-bem-linter: define snackbar */
  .mdc-snackbar {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 50%;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    padding-right: 24px;
    padding-left: 24px;
    transform: translate(-50%, 100%);
    transition: transform 0.25s 0ms cubic-bezier(0.4, 0, 1, 1);
    background-color: #323232;
    pointer-events: none;
    will-change: transform;
    /* stylelint-disable plugin/selector-bem-pattern */
    /* stylelint-enable plugin/selector-bem-pattern */ }
    .mdc-snackbar--theme-dark .mdc-snackbar,
    .mdc-theme--dark .mdc-snackbar {
      background-color: #fafafa; }
    @media (max-width: 599px) {
      .mdc-snackbar {
        left: 0;
        width: 100%;
        transform: translate(0, 100%); } }
    @media (min-width: 600px) {
      .mdc-snackbar {
        min-width: 288px;
        max-width: 568px;
        border-radius: 2px; } }
    @media (min-width: 600px) {
      .mdc-snackbar--align-start {
        left: 24px;
        right: initial;
        bottom: 24px;
        transform: translate(0, 200%); }
        [dir="rtl"] .mdc-snackbar--align-start, .mdc-snackbar--align-start[dir="rtl"] {
          left: initial;
          right: 24px; } }
    @media (max-width: 599px) {
      .mdc-snackbar--align-start {
        bottom: 0;
        left: 0;
        width: 100%;
        transform: translate(0, 100%); } }
    .mdc-snackbar--active {
      transform: translate(0);
      transition: transform 0.25s 0ms cubic-bezier(0, 0, 0.2, 1);
      pointer-events: auto; }
      .mdc-snackbar--active:not(.mdc-snackbar--align-start) {
        transform: translate(-50%, 0); }
        @media (max-width: 599px) {
          .mdc-snackbar--active:not(.mdc-snackbar--align-start) {
            bottom: 0;
            left: 0;
            width: 100%;
            transform: translate(0); } }
    .mdc-snackbar__action-wrapper {
      padding-left: 24px;
      padding-right: 0; }
      [dir="rtl"] .mdc-snackbar__action-wrapper, .mdc-snackbar__action-wrapper[dir="rtl"] {
        padding-left: 0;
        padding-right: 24px; }
    .mdc-snackbar--action-on-bottom {
      flex-direction: column; }
    .mdc-snackbar--action-on-bottom .mdc-snackbar__text {
      margin-right: inherit; }
    .mdc-snackbar--action-on-bottom .mdc-snackbar__action-wrapper {
      margin-left: auto;
      margin-right: 0;
      flex-direction: column;
      justify-content: flex-start;
      margin-top: -12px;
      margin-bottom: 8px; }
      [dir="rtl"] .mdc-snackbar--action-on-bottom .mdc-snackbar__action-wrapper, .mdc-snackbar--action-on-bottom .mdc-snackbar__action-wrapper[dir="rtl"] {
        margin-left: 0;
        margin-right: auto; }
    .mdc-snackbar__text {
      font-family: Roboto, sans-serif;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-weight: 400;
      letter-spacing: 0.04em;
      text-decoration: inherit;
      text-transform: inherit;
      margin-left: 0;
      margin-right: auto;
      display: flex;
      align-items: center;
      height: 48px;
      transition: opacity 0.3s 0ms cubic-bezier(0.4, 0, 1, 1);
      opacity: 0;
      color: white; }
      [dir="rtl"] .mdc-snackbar .mdc-snackbar__text,
      .mdc-snackbar[dir="rtl"] .mdc-snackbar__text {
        margin-left: auto;
        margin-right: 0; }
      .mdc-snackbar--theme-dark .mdc-snackbar__text,
      .mdc-theme--dark .mdc-snackbar__text {
        /* @alternate */
        color: rgba(0, 0, 0, 0.87);
        color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87)); }
      @media (min-width: 600px) {
        .mdc-snackbar__text {
          padding-left: 0;
          padding-right: 24px; }
          [dir="rtl"] .mdc-snackbar__text, .mdc-snackbar__text[dir="rtl"] {
            padding-left: 24px;
            padding-right: 0; } }
    .mdc-snackbar--multiline .mdc-snackbar__text {
      height: 80px; }
    .mdc-snackbar--multiline.mdc-snackbar--action-on-bottom .mdc-snackbar__text {
      margin: 0; }
    .mdc-snackbar__action-button {
      font-family: Roboto, sans-serif;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-size: 0.875rem;
      line-height: 2.25rem;
      font-weight: 500;
      letter-spacing: 0.04em;
      text-decoration: none;
      text-transform: uppercase;
      /* @alternate */
      color: #018786;
      color: var(--mdc-theme-secondary, #018786);
      padding: 0;
      transition: opacity 0.3s 0ms cubic-bezier(0.4, 0, 1, 1);
      border: none;
      outline: none;
      background-color: transparent;
      opacity: 0;
      user-select: none;
      -webkit-appearance: none;
      visibility: hidden; }
      .mdc-snackbar--theme-dark .mdc-snackbar__action-button,
      .mdc-theme--dark .mdc-snackbar__action-button {
        /* @alternate */
        color: #6200ee;
        color: var(--mdc-theme-primary, #6200ee); }
      .mdc-snackbar__action-button:hover {
        cursor: pointer; }
      .mdc-snackbar__action-button::-moz-focus-inner {
        border: 0; }
      .mdc-snackbar__action-button:not([aria-hidden]) {
        visibility: inherit; }
    .mdc-snackbar--active .mdc-snackbar__text,
    .mdc-snackbar--active .mdc-snackbar__action-button:not([aria-hidden]) {
      transition: opacity 0.3s 0ms cubic-bezier(0.4, 0, 1, 1);
      opacity: 1; }

  /* postcss-bem-linter: end */
  /**
   * The css property used for elevation. In most cases this should not be changed. It is exposed
   * as a variable for abstraction / easy use when needing to reference the property directly, for
   * example in a `will-change` rule.
   */
  /**
   * The default duration value for elevation transitions.
   */
  /**
   * The default easing value for elevation transitions.
   */
  /**
   * Applies the correct CSS rules to an element to give it the elevation specified by $z-value.
   * The $z-value must be between 0 and 24.
   * If $color has an alpha channel, it will be ignored and overridden. To increase the opacity of the shadow, use
   * $opacity-boost.
   */
  /**
   * Returns a string that can be used as the value for a `transition` property for elevation.
   * Calling this function directly is useful in situations where a component needs to transition
   * more than one property.
   *
   * ```scss
   * .foo {
   *   transition: mdc-elevation-transition-value(), opacity 100ms ease;
   *   will-change: $mdc-elevation-property, opacity;
   * }
   * ```
   */
  .mdc-switch {
    display: inline-block;
    position: relative; }
    .mdc-switch .mdc-switch__native-control:enabled:not(:checked) ~ .mdc-switch__background::before {
      background-color: #000; }
    .mdc-switch .mdc-switch__native-control:enabled:not(:checked) ~ .mdc-switch__background .mdc-switch__knob {
      background-color: #fafafa; }
    .mdc-switch .mdc-switch__native-control:enabled:not(:checked) ~ .mdc-switch__background .mdc-switch__knob::before {
      background-color: #9e9e9e; }
    .mdc-switch--theme-dark .mdc-switch__native-control:enabled:not(:checked) ~ .mdc-switch__background::before,
    .mdc-theme--dark .mdc-switch .mdc-switch__native-control:enabled:not(:checked) ~ .mdc-switch__background::before {
      background-color: #fff; }
    .mdc-switch--theme-dark .mdc-switch__native-control:enabled:not(:checked) ~ .mdc-switch__background .mdc-switch__knob,
    .mdc-theme--dark .mdc-switch .mdc-switch__native-control:enabled:not(:checked) ~ .mdc-switch__background .mdc-switch__knob {
      background-color: #bdbdbd; }
    .mdc-switch--theme-dark .mdc-switch__native-control:enabled:not(:checked) ~ .mdc-switch__background .mdc-switch__knob::before,
    .mdc-theme--dark .mdc-switch .mdc-switch__native-control:enabled:not(:checked) ~ .mdc-switch__background .mdc-switch__knob::before {
      background-color: #f1f1f1; }
    .mdc-switch .mdc-switch__native-control:enabled:checked ~ .mdc-switch__background::before {
      /* @alternate */
      background-color: #018786;
      background-color: var(--mdc-theme-secondary, #018786); }
    .mdc-switch .mdc-switch__native-control:enabled:checked ~ .mdc-switch__background .mdc-switch__knob {
      /* @alternate */
      background-color: #018786;
      background-color: var(--mdc-theme-secondary, #018786); }
    .mdc-switch .mdc-switch__native-control:enabled:checked ~ .mdc-switch__background .mdc-switch__knob::before {
      /* @alternate */
      background-color: #018786;
      background-color: var(--mdc-theme-secondary, #018786); }
    .mdc-switch__native-control {
      position: absolute;
      top: -14px;
      left: -14px;
      width: 48px;
      height: 48px;
      display: inline-block;
      margin-top: -3px;
      transition: transform 90ms cubic-bezier(0.4, 0, 0.2, 1);
      opacity: 0;
      cursor: pointer;
      z-index: 2; }
      .mdc-switch__native-control:checked {
        transform: translateX(14px); }
    .mdc-switch__background {
      display: block;
      position: relative;
      width: 34px;
      height: 14px;
      border-radius: 7px;
      outline: none;
      user-select: none; }
      .mdc-switch__background::before {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transition: opacity 90ms cubic-bezier(0.4, 0, 0.2, 1), background-color 90ms cubic-bezier(0.4, 0, 0.2, 1);
        border-radius: 7px;
        opacity: .38;
        content: ""; }
        .mdc-switch--theme-dark .mdc-switch__background::before,
        .mdc-theme--dark .mdc-switch__background::before {
          opacity: .3; }
      .mdc-switch__background .mdc-switch__knob {
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
        display: block;
        position: absolute;
        top: -3px;
        left: 0;
        width: 20px;
        height: 20px;
        transform: translateX(0);
        transition: transform 90ms cubic-bezier(0.4, 0, 0.2, 1), background-color 90ms cubic-bezier(0.4, 0, 0.2, 1);
        border-radius: 10px;
        z-index: 1; }
        .mdc-switch__background .mdc-switch__knob::before {
          position: absolute;
          top: -14px;
          left: -14px;
          width: 48px;
          height: 48px;
          transform: scale(0);
          transition: transform 90ms cubic-bezier(0.4, 0, 0.2, 1), background-color 90ms cubic-bezier(0.4, 0, 0.2, 1);
          border-radius: 24px;
          opacity: .2;
          content: ""; }

  .mdc-switch__native-control:focus ~ .mdc-switch__background .mdc-switch__knob::before {
    position: absolute;
    width: 48px;
    height: 48px;
    transform: scale(1);
    transition: transform 90ms cubic-bezier(0.4, 0, 0.2, 1), background-color 90ms cubic-bezier(0.4, 0, 0.2, 1);
    border-radius: 24px; }
    .mdc-switch--theme-dark .mdc-switch__native-control:focus ~ .mdc-switch__background .mdc-switch__knob::before,
    .mdc-theme--dark .mdc-switch__native-control:focus ~ .mdc-switch__background .mdc-switch__knob::before {
      opacity: .14; }

  .mdc-switch__native-control:checked ~ .mdc-switch__background::before {
    opacity: .5; }

  .mdc-switch__native-control:checked ~ .mdc-switch__background .mdc-switch__knob {
    transform: translateX(14px);
    transition: transform 90ms cubic-bezier(0.4, 0, 0.2, 1), background-color 90ms cubic-bezier(0.4, 0, 0.2, 1); }
    .mdc-switch__native-control:checked ~ .mdc-switch__background .mdc-switch__knob::before {
      opacity: .15; }

  .mdc-switch__native-control:disabled {
    cursor: initial; }

  .mdc-switch__native-control:disabled ~ .mdc-switch__background::before {
    background-color: #000;
    opacity: .12; }
    .mdc-switch--theme-dark .mdc-switch__native-control:disabled ~ .mdc-switch__background::before,
    .mdc-theme--dark .mdc-switch__native-control:disabled ~ .mdc-switch__background::before {
      background-color: #fff;
      opacity: .1; }

  .mdc-switch__native-control:disabled ~ .mdc-switch__background .mdc-switch__knob {
    background-color: #bdbdbd; }
    .mdc-switch--theme-dark .mdc-switch__native-control:disabled ~ .mdc-switch__background .mdc-switch__knob,
    .mdc-theme--dark .mdc-switch__native-control:disabled ~ .mdc-switch__background .mdc-switch__knob {
      background-color: #424242; }

  /* TODO(sgomes): Figure out what to do about desktop font sizes. */
  /* TODO(sgomes): Figure out what to do about i18n and i18n font sizes. */
  /**
   * Creates a rule that will be applied when an MDC-Web component is within the context of an RTL layout.
   *
   * Usage Example:
   * ```scss
   * .mdc-foo {
   *   position: absolute;
   *   left: 0;
   *
   *   @include mdc-rtl {
   *     left: auto;
   *     right: 0;
   *   }
   *
   *   &__bar {
   *     margin-left: 4px;
   *     @include mdc-rtl(".mdc-foo") {
   *       margin-left: auto;
   *       margin-right: 4px;
   *     }
   *   }
   * }
   *
   * .mdc-foo--mod {
   *   padding-left: 4px;
   *
   *   @include mdc-rtl {
   *     padding-left: auto;
   *     padding-right: 4px;
   *   }
   * }
   * ```
   *
   * Note that this works by checking for [dir="rtl"] on an ancestor element. While this will work
   * in most cases, it will in some cases lead to false negatives, e.g.
   *
   * ```html
   * <html dir="rtl">
   *   <!-- ... -->
   *   <div dir="ltr">
   *     <div class="mdc-foo">Styled incorrectly as RTL!</div>
   *   </div>
   * </html>
   * ```
   *
   * In the future, selectors such as :dir (http://mdn.io/:dir) will help us mitigate this.
   */
  /**
   * Takes a base box-model property - e.g. margin / border / padding - along with a default
   * direction and value, and emits rules which apply the value to the
   * "<base-property>-<default-direction>" property by default, but flips the direction
   * when within an RTL context.
   *
   * For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-box(margin, left, 8px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-left: 8px;
   *
   *   @include mdc-rtl {
   *     margin-right: 8px;
   *     margin-left: 0;
   *   }
   * }
   * ```
   * whereas:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-box(margin, right, 8px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-right: 8px;
   *
   *   @include mdc-rtl {
   *     margin-right: 0;
   *     margin-left: 8px;
   *   }
   * }
   * ```
   *
   * You can also pass a 4th optional $root-selector argument which will be forwarded to `mdc-rtl`,
   * e.g. `@include mdc-rtl-reflexive-box(margin, left, 8px, ".mdc-component")`.
   *
   * Note that this function will always zero out the original value in an RTL context. If you're
   * trying to flip the values, use mdc-rtl-reflexive-property().
   */
  /**
   * Takes a base property and emits rules that assign <base-property>-left to <left-value> and
   * <base-property>-right to <right-value> in a LTR context, and vice versa in a RTL context.
   * For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-property(margin, auto, 12px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-left: auto;
   *   margin-right: 12px;
   *
   *   @include mdc-rtl {
   *     margin-left: 12px;
   *     margin-right: auto;
   *   }
   * }
   * ```
   *
   * A 4th optional $root-selector argument can be given, which will be passed to `mdc-rtl`.
   */
  /**
   * Takes an argument specifying a horizontal position property (either "left" or "right") as well
   * as a value, and applies that value to the specified position in a LTR context, and flips it in a
   * RTL context. For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-position(left, 0);
   *   position: absolute;
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   *  .mdc-foo {
   *    position: absolute;
   *    left: 0;
   *    right: initial;
   *
   *    @include mdc-rtl {
   *      right: 0;
   *      left: initial;
   *    }
   *  }
   * ```
   * An optional third $root-selector argument may also be given, which is passed to `mdc-rtl`.
   */
  .mdc-tab {
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 500;
    letter-spacing: 0.04em;
    text-decoration: inherit;
    text-transform: inherit;
    /* @alternate */
    color: rgba(0, 0, 0, 0.54);
    color: var(--mdc-theme-text-secondary-on-light, rgba(0, 0, 0, 0.54));
    display: table-cell;
    position: relative;
    box-sizing: border-box;
    min-width: 160px;
    min-height: 48px;
    padding: 0 24px;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;
    overflow: hidden;
    vertical-align: middle; }
    .mdc-tab .mdc-tab__icon {
      /* @alternate */
      color: rgba(0, 0, 0, 0.54);
      color: var(--mdc-theme-text-secondary-on-light, rgba(0, 0, 0, 0.54)); }
    .mdc-tab:hover {
      /* @alternate */
      color: rgba(0, 0, 0, 0.87);
      color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87)); }
      .mdc-tab:hover .mdc-tab__icon {
        /* @alternate */
        color: rgba(0, 0, 0, 0.87);
        color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87)); }
    .mdc-tab:focus {
      /* @alternate */
      outline-color: rgba(0, 0, 0, 0.54);
      outline-color: var(--mdc-theme-text-secondary-on-light, rgba(0, 0, 0, 0.54)); }
    .mdc-tab-bar--theme-dark .mdc-tab,
    .mdc-theme--dark .mdc-tab {
      /* @alternate */
      color: rgba(255, 255, 255, 0.7);
      color: var(--mdc-theme-text-secondary-on-dark, rgba(255, 255, 255, 0.7)); }
      .mdc-tab-bar--theme-dark .mdc-tab:hover,
      .mdc-theme--dark .mdc-tab:hover {
        /* @alternate */
        color: white;
        color: var(--mdc-theme-text-primary-on-dark, white); }
      .mdc-tab-bar--theme-dark .mdc-tab:focus,
      .mdc-theme--dark .mdc-tab:focus {
        /* @alternate */
        outline-color: rgba(255, 255, 255, 0.7);
        outline-color: var(--mdc-theme-text-secondary-on-dark, rgba(255, 255, 255, 0.7)); }
    @media screen and (max-width: 600px) {
      .mdc-tab {
        min-width: 72px;
        padding: 0 12px; } }
    .mdc-tab__icon {
      display: block;
      margin: 0 auto;
      width: 24px;
      height: 24px; }
      .mdc-tab-bar--icons-with-text .mdc-tab__icon {
        margin-top: 4px; }
    .mdc-tab__icon-text {
      display: block;
      margin: 0 auto; }
    .mdc-tab__icon + .mdc-tab__icon-text {
      padding-top: 6px; }

  .mdc-tab--active {
    /* @alternate */
    color: rgba(0, 0, 0, 0.87);
    color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87)); }
    .mdc-tab--active .mdc-tab__icon {
      /* @alternate */
      color: rgba(0, 0, 0, 0.87);
      color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87)); }
    .mdc-tab-bar--theme-dark .mdc-tab--active,
    .mdc-theme--dark .mdc-tab--active {
      /* @alternate */
      color: white;
      color: var(--mdc-theme-text-primary-on-dark, white); }
    .mdc-tab--active::before {
      bottom: 0; }

  .mdc-tab-bar:not(.mdc-tab-bar-upgraded) .mdc-tab {
    position: relative; }
    .mdc-tab-bar:not(.mdc-tab-bar-upgraded) .mdc-tab::after {
      display: none;
      position: absolute;
      top: 46px;
      left: 0;
      width: calc(100% - 4px);
      height: 2px;
      content: "";
      pointer-events: none;
      /* @alternate */
      background-color: rgba(0, 0, 0, 0.87);
      background-color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87)); }
      .mdc-tab-bar--theme-dark.mdc-tab-bar:not(.mdc-tab-bar-upgraded) .mdc-tab::after,
      .mdc-theme--dark .mdc-tab-bar:not(.mdc-tab-bar-upgraded) .mdc-tab::after {
        /* @alternate */
        background-color: white;
        background-color: var(--mdc-theme-text-primary-on-dark, white); }
      .mdc-toolbar .mdc-tab-bar:not(.mdc-tab-bar-upgraded) .mdc-tab::after {
        /* @alternate */
        background-color: white;
        background-color: var(--mdc-theme-text-primary-on-primary, white); }
    .mdc-tab-bar:not(.mdc-tab-bar-upgraded) .mdc-tab--active::after, .mdc-tab-bar:not(.mdc-tab-bar-upgraded) .mdc-tab:active::after, .mdc-tab-bar:not(.mdc-tab-bar-upgraded) .mdc-tab:hover::after {
      display: block; }
    .mdc-tab-bar:not(.mdc-tab-bar-upgraded) .mdc-tab:not(.mdc-tab--active):hover::after {
      opacity: .38; }
    .mdc-tab-bar:not(.mdc-tab-bar-upgraded) .mdc-tab--active, .mdc-tab-bar:not(.mdc-tab-bar-upgraded) .mdc-tab:not(.mdc-tab--active):active::after {
      opacity: .87; }

  .mdc-tab-bar--icons-with-text:not(.mdc-tab-bar-upgraded) .mdc-tab::after {
    top: 70px; }

  .mdc-tab.mdc-ripple-upgraded {
    --mdc-ripple-fg-size: 0;
    --mdc-ripple-left: 0;
    --mdc-ripple-top: 0;
    --mdc-ripple-fg-scale: 1;
    --mdc-ripple-fg-translate-end: 0;
    --mdc-ripple-fg-translate-start: 0;
    -webkit-tap-highlight-color: transparent;
    will-change: transform, opacity; }
    .mdc-tab.mdc-ripple-upgraded::before, .mdc-tab.mdc-ripple-upgraded::after {
      position: absolute;
      border-radius: 50%;
      opacity: 0;
      pointer-events: none;
      content: ""; }
    .mdc-tab.mdc-ripple-upgraded::before {
      transition: opacity 15ms linear; }
    .mdc-tab.mdc-ripple-upgraded.mdc-ripple-upgraded::after {
      top: 0;
      left: 0;
      transform: scale(0);
      transform-origin: center center; }
    .mdc-tab.mdc-ripple-upgraded.mdc-ripple-upgraded--unbounded::after {
      top: var(--mdc-ripple-top, 0);
      left: var(--mdc-ripple-left, 0); }
    .mdc-tab.mdc-ripple-upgraded.mdc-ripple-upgraded--foreground-activation::after {
      animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards; }
    .mdc-tab.mdc-ripple-upgraded.mdc-ripple-upgraded--foreground-deactivation::after {
      animation: 150ms mdc-ripple-fg-opacity-out;
      transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
    .mdc-tab.mdc-ripple-upgraded::before, .mdc-tab.mdc-ripple-upgraded::after {
      background-color: black; }
    .mdc-tab.mdc-ripple-upgraded:hover::before {
      opacity: 0.04; }
    .mdc-tab.mdc-ripple-upgraded:not(.mdc-ripple-upgraded):focus::before, .mdc-tab.mdc-ripple-upgraded.mdc-ripple-upgraded--background-focused::before {
      transition-duration: 75ms;
      opacity: 0.12; }
    .mdc-tab.mdc-ripple-upgraded:not(.mdc-ripple-upgraded)::after {
      transition: opacity 150ms linear; }
    .mdc-tab.mdc-ripple-upgraded:not(.mdc-ripple-upgraded):active::after {
      transition-duration: 75ms;
      opacity: 0.16; }
    .mdc-tab.mdc-ripple-upgraded.mdc-ripple-upgraded {
      --mdc-ripple-fg-opacity: 0.16; }
    .mdc-tab.mdc-ripple-upgraded::before, .mdc-tab.mdc-ripple-upgraded::after {
      top: calc(50% - 100%);
      left: calc(50% - 100%);
      width: 200%;
      height: 200%; }
    .mdc-tab.mdc-ripple-upgraded.mdc-ripple-upgraded::before {
      top: calc(50% - 100%);
      left: calc(50% - 100%);
      width: 200%;
      height: 200%;
      transform: scale(var(--mdc-ripple-fg-scale, 0)); }
    .mdc-tab.mdc-ripple-upgraded.mdc-ripple-upgraded--unbounded::before {
      top: var(--mdc-ripple-top, calc(50% - 50%));
      left: var(--mdc-ripple-left, calc(50% - 50%));
      width: var(--mdc-ripple-fg-size, 100%);
      height: var(--mdc-ripple-fg-size, 100%);
      transform: scale(var(--mdc-ripple-fg-scale, 0)); }
    .mdc-tab.mdc-ripple-upgraded.mdc-ripple-upgraded::after {
      width: var(--mdc-ripple-fg-size, 100%);
      height: var(--mdc-ripple-fg-size, 100%); }
    .mdc-tab-bar--theme-dark .mdc-tab.mdc-ripple-upgraded::before, .mdc-tab-bar--theme-dark .mdc-tab.mdc-ripple-upgraded::after,
    .mdc-theme--dark .mdc-tab.mdc-ripple-upgraded::before,
    .mdc-theme--dark .mdc-tab.mdc-ripple-upgraded::after {
      /* @alternate */
      background-color: white; }
      @supports not (-ms-ime-align: auto) {
        .mdc-tab-bar--theme-dark .mdc-tab.mdc-ripple-upgraded::before, .mdc-tab-bar--theme-dark .mdc-tab.mdc-ripple-upgraded::after,
        .mdc-theme--dark .mdc-tab.mdc-ripple-upgraded::before,
        .mdc-theme--dark .mdc-tab.mdc-ripple-upgraded::after {
          background-color: var(--mdc-theme-text-primary-on-dark, white); } }
    .mdc-tab-bar--theme-dark .mdc-tab.mdc-ripple-upgraded:hover::before,
    .mdc-theme--dark .mdc-tab.mdc-ripple-upgraded:hover::before {
      opacity: 0.08; }
    .mdc-tab-bar--theme-dark .mdc-tab.mdc-ripple-upgraded:not(.mdc-ripple-upgraded):focus::before, .mdc-tab-bar--theme-dark .mdc-tab.mdc-ripple-upgraded.mdc-ripple-upgraded--background-focused::before,
    .mdc-theme--dark .mdc-tab.mdc-ripple-upgraded:not(.mdc-ripple-upgraded):focus::before,
    .mdc-theme--dark .mdc-tab.mdc-ripple-upgraded.mdc-ripple-upgraded--background-focused::before {
      transition-duration: 75ms;
      opacity: 0.24; }
    .mdc-tab-bar--theme-dark .mdc-tab.mdc-ripple-upgraded:not(.mdc-ripple-upgraded)::after,
    .mdc-theme--dark .mdc-tab.mdc-ripple-upgraded:not(.mdc-ripple-upgraded)::after {
      transition: opacity 150ms linear; }
    .mdc-tab-bar--theme-dark .mdc-tab.mdc-ripple-upgraded:not(.mdc-ripple-upgraded):active::after,
    .mdc-theme--dark .mdc-tab.mdc-ripple-upgraded:not(.mdc-ripple-upgraded):active::after {
      transition-duration: 75ms;
      opacity: 0.32; }
    .mdc-tab-bar--theme-dark .mdc-tab.mdc-ripple-upgraded.mdc-ripple-upgraded,
    .mdc-theme--dark .mdc-tab.mdc-ripple-upgraded.mdc-ripple-upgraded {
      --mdc-ripple-fg-opacity: 0.32; }
    .mdc-toolbar .mdc-tab.mdc-ripple-upgraded::before, .mdc-toolbar .mdc-tab.mdc-ripple-upgraded::after {
      /* @alternate */
      background-color: white; }
      @supports not (-ms-ime-align: auto) {
        .mdc-toolbar .mdc-tab.mdc-ripple-upgraded::before, .mdc-toolbar .mdc-tab.mdc-ripple-upgraded::after {
          background-color: var(--mdc-theme-text-primary-on-primary, white); } }
    .mdc-toolbar .mdc-tab.mdc-ripple-upgraded:hover::before {
      opacity: 0.08; }
    .mdc-toolbar .mdc-tab.mdc-ripple-upgraded:not(.mdc-ripple-upgraded):focus::before, .mdc-toolbar .mdc-tab.mdc-ripple-upgraded.mdc-ripple-upgraded--background-focused::before {
      transition-duration: 75ms;
      opacity: 0.24; }
    .mdc-toolbar .mdc-tab.mdc-ripple-upgraded:not(.mdc-ripple-upgraded)::after {
      transition: opacity 150ms linear; }
    .mdc-toolbar .mdc-tab.mdc-ripple-upgraded:not(.mdc-ripple-upgraded):active::after {
      transition-duration: 75ms;
      opacity: 0.32; }
    .mdc-toolbar .mdc-tab.mdc-ripple-upgraded.mdc-ripple-upgraded {
      --mdc-ripple-fg-opacity: 0.32; }
    .mdc-tab.mdc-ripple-upgraded:focus {
      outline: none; }

  /**
   * Creates a rule that will be applied when an MDC-Web component is within the context of an RTL layout.
   *
   * Usage Example:
   * ```scss
   * .mdc-foo {
   *   position: absolute;
   *   left: 0;
   *
   *   @include mdc-rtl {
   *     left: auto;
   *     right: 0;
   *   }
   *
   *   &__bar {
   *     margin-left: 4px;
   *     @include mdc-rtl(".mdc-foo") {
   *       margin-left: auto;
   *       margin-right: 4px;
   *     }
   *   }
   * }
   *
   * .mdc-foo--mod {
   *   padding-left: 4px;
   *
   *   @include mdc-rtl {
   *     padding-left: auto;
   *     padding-right: 4px;
   *   }
   * }
   * ```
   *
   * Note that this works by checking for [dir="rtl"] on an ancestor element. While this will work
   * in most cases, it will in some cases lead to false negatives, e.g.
   *
   * ```html
   * <html dir="rtl">
   *   <!-- ... -->
   *   <div dir="ltr">
   *     <div class="mdc-foo">Styled incorrectly as RTL!</div>
   *   </div>
   * </html>
   * ```
   *
   * In the future, selectors such as :dir (http://mdn.io/:dir) will help us mitigate this.
   */
  /**
   * Takes a base box-model property - e.g. margin / border / padding - along with a default
   * direction and value, and emits rules which apply the value to the
   * "<base-property>-<default-direction>" property by default, but flips the direction
   * when within an RTL context.
   *
   * For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-box(margin, left, 8px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-left: 8px;
   *
   *   @include mdc-rtl {
   *     margin-right: 8px;
   *     margin-left: 0;
   *   }
   * }
   * ```
   * whereas:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-box(margin, right, 8px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-right: 8px;
   *
   *   @include mdc-rtl {
   *     margin-right: 0;
   *     margin-left: 8px;
   *   }
   * }
   * ```
   *
   * You can also pass a 4th optional $root-selector argument which will be forwarded to `mdc-rtl`,
   * e.g. `@include mdc-rtl-reflexive-box(margin, left, 8px, ".mdc-component")`.
   *
   * Note that this function will always zero out the original value in an RTL context. If you're
   * trying to flip the values, use mdc-rtl-reflexive-property().
   */
  /**
   * Takes a base property and emits rules that assign <base-property>-left to <left-value> and
   * <base-property>-right to <right-value> in a LTR context, and vice versa in a RTL context.
   * For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-property(margin, auto, 12px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-left: auto;
   *   margin-right: 12px;
   *
   *   @include mdc-rtl {
   *     margin-left: 12px;
   *     margin-right: auto;
   *   }
   * }
   * ```
   *
   * A 4th optional $root-selector argument can be given, which will be passed to `mdc-rtl`.
   */
  /**
   * Takes an argument specifying a horizontal position property (either "left" or "right") as well
   * as a value, and applies that value to the specified position in a LTR context, and flips it in a
   * RTL context. For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-position(left, 0);
   *   position: absolute;
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   *  .mdc-foo {
   *    position: absolute;
   *    left: 0;
   *    right: initial;
   *
   *    @include mdc-rtl {
   *      right: 0;
   *      left: initial;
   *    }
   *  }
   * ```
   * An optional third $root-selector argument may also be given, which is passed to `mdc-rtl`.
   */
  .mdc-tab-bar {
    display: table;
    position: relative;
    height: 48px;
    margin: 0 auto;
    text-transform: uppercase; }
    .mdc-tab-bar__indicator {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      /* @alternate */
      background-color: rgba(0, 0, 0, 0.87);
      background-color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87));
      transform-origin: left top;
      transition: transform 240ms 0ms cubic-bezier(0, 0, 0.2, 1);
      will-change: transform;
      visibility: hidden; }
      .mdc-tab-bar--theme-dark .mdc-tab-bar__indicator,
      .mdc-theme--dark .mdc-tab-bar__indicator {
        /* @alternate */
        background-color: white;
        background-color: var(--mdc-theme-text-primary-on-dark, white); }
    .mdc-toolbar .mdc-tab-bar .mdc-tab {
      /* @alternate */
      color: rgba(255, 255, 255, 0.7);
      color: var(--mdc-theme-text-secondary-on-primary, rgba(255, 255, 255, 0.7)); }
      .mdc-tab-bar--theme-dark .mdc-toolbar .mdc-tab-bar .mdc-tab,
      .mdc-theme--dark .mdc-toolbar .mdc-tab-bar .mdc-tab {
        /* @alternate */
        color: rgba(255, 255, 255, 0.7);
        color: var(--mdc-theme-text-secondary-on-dark, rgba(255, 255, 255, 0.7)); }
    .mdc-toolbar .mdc-tab-bar .mdc-tab--active,
    .mdc-toolbar .mdc-tab-bar .mdc-tab:hover {
      /* @alternate */
      color: white;
      color: var(--mdc-theme-text-primary-on-primary, white); }
      .mdc-tab-bar--theme-dark .mdc-toolbar .mdc-tab-bar .mdc-tab--active,
      .mdc-theme--dark .mdc-toolbar .mdc-tab-bar .mdc-tab--active, .mdc-tab-bar--theme-dark
      .mdc-toolbar .mdc-tab-bar .mdc-tab:hover,
      .mdc-theme--dark
      .mdc-toolbar .mdc-tab-bar .mdc-tab:hover {
        /* @alternate */
        color: white;
        color: var(--mdc-theme-text-primary-on-dark, white); }
    .mdc-toolbar .mdc-tab-bar .mdc-tab-bar__indicator {
      /* @alternate */
      background-color: white;
      background-color: var(--mdc-theme-text-primary-on-primary, white); }
      .mdc-tab-bar--theme-dark .mdc-toolbar .mdc-tab-bar .mdc-tab-bar__indicator,
      .mdc-theme--dark .mdc-toolbar .mdc-tab-bar .mdc-tab-bar__indicator {
        /* @alternate */
        background-color: white;
        background-color: var(--mdc-theme-text-primary-on-dark, white); }

  .mdc-tab-bar--icons-with-text {
    height: 72px; }

  .mdc-tab-bar--indicator-primary .mdc-tab-bar__indicator,
  .mdc-toolbar .mdc-tab-bar--indicator-primary .mdc-tab-bar__indicator {
    /* @alternate */
    background-color: #6200ee;
    background-color: var(--mdc-theme-primary, #6200ee); }
    .mdc-tab-bar--theme-dark .mdc-tab-bar--indicator-primary .mdc-tab-bar__indicator,
    .mdc-theme--dark .mdc-tab-bar--indicator-primary .mdc-tab-bar__indicator, .mdc-tab-bar--theme-dark
    .mdc-toolbar .mdc-tab-bar--indicator-primary .mdc-tab-bar__indicator,
    .mdc-theme--dark
    .mdc-toolbar .mdc-tab-bar--indicator-primary .mdc-tab-bar__indicator {
      /* @alternate */
      background-color: #6200ee;
      background-color: var(--mdc-theme-primary, #6200ee); }

  .mdc-tab-bar--indicator-primary.mdc-tab-bar:not(.mdc-tab-bar-upgraded) .mdc-tab::after,
  .mdc-toolbar .mdc-tab-bar--indicator-primary.mdc-tab-bar:not(.mdc-tab-bar-upgraded) .mdc-tab::after {
    /* @alternate */
    background-color: #6200ee;
    background-color: var(--mdc-theme-primary, #6200ee); }

  .mdc-tab-bar--indicator-accent .mdc-tab-bar__indicator,
  .mdc-toolbar .mdc-tab-bar--indicator-accent .mdc-tab-bar__indicator {
    /* @alternate */
    background-color: #018786;
    background-color: var(--mdc-theme-secondary, #018786); }
    .mdc-tab-bar--theme-dark .mdc-tab-bar--indicator-accent .mdc-tab-bar__indicator,
    .mdc-theme--dark .mdc-tab-bar--indicator-accent .mdc-tab-bar__indicator, .mdc-tab-bar--theme-dark
    .mdc-toolbar .mdc-tab-bar--indicator-accent .mdc-tab-bar__indicator,
    .mdc-theme--dark
    .mdc-toolbar .mdc-tab-bar--indicator-accent .mdc-tab-bar__indicator {
      /* @alternate */
      background-color: #018786;
      background-color: var(--mdc-theme-secondary, #018786); }

  .mdc-tab-bar--indicator-accent.mdc-tab-bar:not(.mdc-tab-bar-upgraded) .mdc-tab::after,
  .mdc-toolbar .mdc-tab-bar--indicator-accent.mdc-tab-bar:not(.mdc-tab-bar-upgraded) .mdc-tab::after {
    /* @alternate */
    background-color: #018786;
    background-color: var(--mdc-theme-secondary, #018786); }

  /**
   * Creates a rule that will be applied when an MDC-Web component is within the context of an RTL layout.
   *
   * Usage Example:
   * ```scss
   * .mdc-foo {
   *   position: absolute;
   *   left: 0;
   *
   *   @include mdc-rtl {
   *     left: auto;
   *     right: 0;
   *   }
   *
   *   &__bar {
   *     margin-left: 4px;
   *     @include mdc-rtl(".mdc-foo") {
   *       margin-left: auto;
   *       margin-right: 4px;
   *     }
   *   }
   * }
   *
   * .mdc-foo--mod {
   *   padding-left: 4px;
   *
   *   @include mdc-rtl {
   *     padding-left: auto;
   *     padding-right: 4px;
   *   }
   * }
   * ```
   *
   * Note that this works by checking for [dir="rtl"] on an ancestor element. While this will work
   * in most cases, it will in some cases lead to false negatives, e.g.
   *
   * ```html
   * <html dir="rtl">
   *   <!-- ... -->
   *   <div dir="ltr">
   *     <div class="mdc-foo">Styled incorrectly as RTL!</div>
   *   </div>
   * </html>
   * ```
   *
   * In the future, selectors such as :dir (http://mdn.io/:dir) will help us mitigate this.
   */
  /**
   * Takes a base box-model property - e.g. margin / border / padding - along with a default
   * direction and value, and emits rules which apply the value to the
   * "<base-property>-<default-direction>" property by default, but flips the direction
   * when within an RTL context.
   *
   * For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-box(margin, left, 8px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-left: 8px;
   *
   *   @include mdc-rtl {
   *     margin-right: 8px;
   *     margin-left: 0;
   *   }
   * }
   * ```
   * whereas:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-box(margin, right, 8px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-right: 8px;
   *
   *   @include mdc-rtl {
   *     margin-right: 0;
   *     margin-left: 8px;
   *   }
   * }
   * ```
   *
   * You can also pass a 4th optional $root-selector argument which will be forwarded to `mdc-rtl`,
   * e.g. `@include mdc-rtl-reflexive-box(margin, left, 8px, ".mdc-component")`.
   *
   * Note that this function will always zero out the original value in an RTL context. If you're
   * trying to flip the values, use mdc-rtl-reflexive-property().
   */
  /**
   * Takes a base property and emits rules that assign <base-property>-left to <left-value> and
   * <base-property>-right to <right-value> in a LTR context, and vice versa in a RTL context.
   * For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-property(margin, auto, 12px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-left: auto;
   *   margin-right: 12px;
   *
   *   @include mdc-rtl {
   *     margin-left: 12px;
   *     margin-right: auto;
   *   }
   * }
   * ```
   *
   * A 4th optional $root-selector argument can be given, which will be passed to `mdc-rtl`.
   */
  /**
   * Takes an argument specifying a horizontal position property (either "left" or "right") as well
   * as a value, and applies that value to the specified position in a LTR context, and flips it in a
   * RTL context. For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-position(left, 0);
   *   position: absolute;
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   *  .mdc-foo {
   *    position: absolute;
   *    left: 0;
   *    right: initial;
   *
   *    @include mdc-rtl {
   *      right: 0;
   *      left: initial;
   *    }
   *  }
   * ```
   * An optional third $root-selector argument may also be given, which is passed to `mdc-rtl`.
   */
  .mdc-tab-bar-scroller {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    background-color: inherit;
    overflow: hidden; }
    .mdc-tab-bar-scroller__scroll-frame {
      display: flex;
      position: relative;
      flex: 1;
      justify-content: flex-start;
      overflow: hidden; }
      .mdc-tab-bar-scroller__scroll-frame__tabs {
        transition: transform 240ms 0ms cubic-bezier(0, 0, 0.2, 1);
        will-change: transform; }
    .mdc-tab-bar-scroller__indicator {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      cursor: pointer;
      visibility: hidden;
      /* @alternate */
      color: rgba(0, 0, 0, 0.54);
      color: var(--mdc-theme-text-secondary-on-light, rgba(0, 0, 0, 0.54)); }
      .mdc-tab-bar--theme-dark .mdc-tab-bar-scroller__indicator,
      .mdc-theme--dark .mdc-tab-bar-scroller__indicator {
        /* @alternate */
        color: rgba(255, 255, 255, 0.7);
        color: var(--mdc-theme-text-secondary-on-dark, rgba(255, 255, 255, 0.7)); }
      .mdc-tab-bar-scroller__indicator:hover {
        /* @alternate */
        color: rgba(0, 0, 0, 0.87);
        color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87)); }
        .mdc-tab-bar--theme-dark .mdc-tab-bar-scroller__indicator:hover,
        .mdc-theme--dark .mdc-tab-bar-scroller__indicator:hover {
          /* @alternate */
          color: white;
          color: var(--mdc-theme-text-primary-on-dark, white); }
      .mdc-tab-bar-scroller__indicator__inner {
        color: inherit;
        text-decoration: inherit;
        cursor: inherit; }
        .mdc-tab-bar-scroller__indicator__inner:focus {
          outline-color: inherit; }
        [dir="rtl"] .mdc-tab-bar-scroller .mdc-tab-bar-scroller__indicator__inner,
        .mdc-tab-bar-scroller[dir="rtl"] .mdc-tab-bar-scroller__indicator__inner {
          transform: rotate(180deg); }
      .mdc-tab-bar-scroller__indicator__inner:hover {
        color: inherit; }
        .mdc-tab-bar--theme-dark .mdc-tab-bar-scroller__indicator__inner:hover,
        .mdc-theme--dark .mdc-tab-bar-scroller__indicator__inner:hover {
          /* @alternate */
          color: rgba(255, 255, 255, 0.7);
          color: var(--mdc-theme-text-secondary-on-dark, rgba(255, 255, 255, 0.7)); }
    .mdc-tab-bar-scroller__indicator--enabled {
      visibility: visible; }

  /**
   * Creates a rule that will be applied when an MDC-Web component is within the context of an RTL layout.
   *
   * Usage Example:
   * ```scss
   * .mdc-foo {
   *   position: absolute;
   *   left: 0;
   *
   *   @include mdc-rtl {
   *     left: auto;
   *     right: 0;
   *   }
   *
   *   &__bar {
   *     margin-left: 4px;
   *     @include mdc-rtl(".mdc-foo") {
   *       margin-left: auto;
   *       margin-right: 4px;
   *     }
   *   }
   * }
   *
   * .mdc-foo--mod {
   *   padding-left: 4px;
   *
   *   @include mdc-rtl {
   *     padding-left: auto;
   *     padding-right: 4px;
   *   }
   * }
   * ```
   *
   * Note that this works by checking for [dir="rtl"] on an ancestor element. While this will work
   * in most cases, it will in some cases lead to false negatives, e.g.
   *
   * ```html
   * <html dir="rtl">
   *   <!-- ... -->
   *   <div dir="ltr">
   *     <div class="mdc-foo">Styled incorrectly as RTL!</div>
   *   </div>
   * </html>
   * ```
   *
   * In the future, selectors such as :dir (http://mdn.io/:dir) will help us mitigate this.
   */
  /**
   * Takes a base box-model property - e.g. margin / border / padding - along with a default
   * direction and value, and emits rules which apply the value to the
   * "<base-property>-<default-direction>" property by default, but flips the direction
   * when within an RTL context.
   *
   * For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-box(margin, left, 8px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-left: 8px;
   *
   *   @include mdc-rtl {
   *     margin-right: 8px;
   *     margin-left: 0;
   *   }
   * }
   * ```
   * whereas:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-box(margin, right, 8px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-right: 8px;
   *
   *   @include mdc-rtl {
   *     margin-right: 0;
   *     margin-left: 8px;
   *   }
   * }
   * ```
   *
   * You can also pass a 4th optional $root-selector argument which will be forwarded to `mdc-rtl`,
   * e.g. `@include mdc-rtl-reflexive-box(margin, left, 8px, ".mdc-component")`.
   *
   * Note that this function will always zero out the original value in an RTL context. If you're
   * trying to flip the values, use mdc-rtl-reflexive-property().
   */
  /**
   * Takes a base property and emits rules that assign <base-property>-left to <left-value> and
   * <base-property>-right to <right-value> in a LTR context, and vice versa in a RTL context.
   * For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-property(margin, auto, 12px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-left: auto;
   *   margin-right: 12px;
   *
   *   @include mdc-rtl {
   *     margin-left: 12px;
   *     margin-right: auto;
   *   }
   * }
   * ```
   *
   * A 4th optional $root-selector argument can be given, which will be passed to `mdc-rtl`.
   */
  /**
   * Takes an argument specifying a horizontal position property (either "left" or "right") as well
   * as a value, and applies that value to the specified position in a LTR context, and flips it in a
   * RTL context. For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-position(left, 0);
   *   position: absolute;
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   *  .mdc-foo {
   *    position: absolute;
   *    left: 0;
   *    right: initial;
   *
   *    @include mdc-rtl {
   *      right: 0;
   *      left: initial;
   *    }
   *  }
   * ```
   * An optional third $root-selector argument may also be given, which is passed to `mdc-rtl`.
   */
  /* TODO(sgomes): Figure out what to do about desktop font sizes. */
  /* TODO(sgomes): Figure out what to do about i18n and i18n font sizes. */
  /* TODO(sgomes): Figure out what to do about desktop font sizes. */
  /* TODO(sgomes): Figure out what to do about i18n and i18n font sizes. */
  .mdc-text-field__bottom-line {
    /* @alternate */
    background-color: #6200ee;
    background-color: var(--mdc-theme-primary, #6200ee);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    transform: scaleX(0);
    transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1), opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
    z-index: 2; }

  .mdc-text-field__bottom-line--active {
    transform: scaleX(1); }

  .mdc-text-field .mdc-text-field__input:focus ~ .mdc-text-field__bottom-line {
    opacity: 1; }

  .mdc-text-field-helper-text {
    /* @alternate */
    color: rgba(0, 0, 0, 0.38);
    color: var(--mdc-theme-text-hint-on-light, rgba(0, 0, 0, 0.38));
    margin: 0;
    transition: opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
    font-size: .75rem;
    will-change: opacity; }
    .mdc-text-field-helper-text--theme-dark,
    .mdc-theme--dark .mdc-text-field-helper-text {
      /* @alternate */
      color: rgba(255, 255, 255, 0.5);
      color: var(--mdc-theme-text-hint-on-dark, rgba(255, 255, 255, 0.5)); }
    .mdc-text-field + .mdc-text-field-helper-text {
      margin-bottom: 8px; }

  .mdc-text-field-helper-text--persistent {
    transition: none;
    opacity: 1;
    will-change: initial; }

  .mdc-text-field--with-leading-icon .mdc-text-field__icon,
  .mdc-text-field--with-trailing-icon .mdc-text-field__icon {
    position: absolute;
    bottom: 16px;
    cursor: pointer; }
    .mdc-text-field--theme-dark .mdc-text-field--with-leading-icon .mdc-text-field__icon,
    .mdc-theme--dark .mdc-text-field--with-leading-icon .mdc-text-field__icon, .mdc-text-field--theme-dark
    .mdc-text-field--with-trailing-icon .mdc-text-field__icon,
    .mdc-theme--dark
    .mdc-text-field--with-trailing-icon .mdc-text-field__icon {
      /* @alternate */
      color: rgba(255, 255, 255, 0.7);
      color: var(--mdc-theme-text-secondary-on-dark, rgba(255, 255, 255, 0.7)); }

  .mdc-text-field__icon:not([tabindex]),
  .mdc-text-field__icon[tabindex="-1"] {
    cursor: default;
    pointer-events: none; }

  /**
   * Creates a rule that will be applied when an MDC-Web component is within the context of an RTL layout.
   *
   * Usage Example:
   * ```scss
   * .mdc-foo {
   *   position: absolute;
   *   left: 0;
   *
   *   @include mdc-rtl {
   *     left: auto;
   *     right: 0;
   *   }
   *
   *   &__bar {
   *     margin-left: 4px;
   *     @include mdc-rtl(".mdc-foo") {
   *       margin-left: auto;
   *       margin-right: 4px;
   *     }
   *   }
   * }
   *
   * .mdc-foo--mod {
   *   padding-left: 4px;
   *
   *   @include mdc-rtl {
   *     padding-left: auto;
   *     padding-right: 4px;
   *   }
   * }
   * ```
   *
   * Note that this works by checking for [dir="rtl"] on an ancestor element. While this will work
   * in most cases, it will in some cases lead to false negatives, e.g.
   *
   * ```html
   * <html dir="rtl">
   *   <!-- ... -->
   *   <div dir="ltr">
   *     <div class="mdc-foo">Styled incorrectly as RTL!</div>
   *   </div>
   * </html>
   * ```
   *
   * In the future, selectors such as :dir (http://mdn.io/:dir) will help us mitigate this.
   */
  /**
   * Takes a base box-model property - e.g. margin / border / padding - along with a default
   * direction and value, and emits rules which apply the value to the
   * "<base-property>-<default-direction>" property by default, but flips the direction
   * when within an RTL context.
   *
   * For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-box(margin, left, 8px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-left: 8px;
   *
   *   @include mdc-rtl {
   *     margin-right: 8px;
   *     margin-left: 0;
   *   }
   * }
   * ```
   * whereas:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-box(margin, right, 8px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-right: 8px;
   *
   *   @include mdc-rtl {
   *     margin-right: 0;
   *     margin-left: 8px;
   *   }
   * }
   * ```
   *
   * You can also pass a 4th optional $root-selector argument which will be forwarded to `mdc-rtl`,
   * e.g. `@include mdc-rtl-reflexive-box(margin, left, 8px, ".mdc-component")`.
   *
   * Note that this function will always zero out the original value in an RTL context. If you're
   * trying to flip the values, use mdc-rtl-reflexive-property().
   */
  /**
   * Takes a base property and emits rules that assign <base-property>-left to <left-value> and
   * <base-property>-right to <right-value> in a LTR context, and vice versa in a RTL context.
   * For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-property(margin, auto, 12px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-left: auto;
   *   margin-right: 12px;
   *
   *   @include mdc-rtl {
   *     margin-left: 12px;
   *     margin-right: auto;
   *   }
   * }
   * ```
   *
   * A 4th optional $root-selector argument can be given, which will be passed to `mdc-rtl`.
   */
  /**
   * Takes an argument specifying a horizontal position property (either "left" or "right") as well
   * as a value, and applies that value to the specified position in a LTR context, and flips it in a
   * RTL context. For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-position(left, 0);
   *   position: absolute;
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   *  .mdc-foo {
   *    position: absolute;
   *    left: 0;
   *    right: initial;
   *
   *    @include mdc-rtl {
   *      right: 0;
   *      left: initial;
   *    }
   *  }
   * ```
   * An optional third $root-selector argument may also be given, which is passed to `mdc-rtl`.
   */
  @keyframes invalid-shake-float-above-standard {
    0% {
      transform: translateX(0) translateY(-100%) scale(0.75); }
    33% {
      animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
      transform: translateX(5px) translateY(-100%) scale(0.75); }
    66% {
      animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
      transform: translateX(-5px) translateY(-100%) scale(0.75); }
    100% {
      transform: translateX(0) translateY(-100%) scale(0.75); } }

  @keyframes invalid-shake-float-above-box {
    0% {
      transform: translateX(0) translateY(-50%) scale(0.75); }
    33% {
      animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
      transform: translateX(5px) translateY(-50%) scale(0.75); }
    66% {
      animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
      transform: translateX(-5px) translateY(-50%) scale(0.75); }
    100% {
      transform: translateX(0) translateY(-50%) scale(0.75); } }

  .mdc-text-field__label {
    position: absolute;
    bottom: 8px;
    left: 0;
    transform-origin: left top;
    transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1), color 180ms cubic-bezier(0.4, 0, 0.2, 1);
    color: rgba(0, 0, 0, 0.6);
    cursor: text; }
    [dir="rtl"] .mdc-text-field .mdc-text-field__label,
    .mdc-text-field[dir="rtl"] .mdc-text-field__label {
      right: 0;
      left: auto;
      transform-origin: right top; }
    .mdc-text-field--theme-dark .mdc-text-field__label,
    .mdc-theme--dark .mdc-text-field__label {
      color: rgba(255, 255, 255, 0.6); }
    .mdc-text-field__label--float-above {
      transform: translateY(-100%) scale(0.75, 0.75);
      cursor: auto; }

  .mdc-text-field__label--float-above.mdc-text-field__label--shake {
    animation: invalid-shake-float-above-standard 250ms 1; }

  .mdc-text-field__idle-outline {
    border-radius: 4px;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    transition: opacity 100ms ease;
    border: 1px solid rgba(0, 0, 0, 0.12);
    opacity: 1; }

  .mdc-text-field__outline {
    --mdc-ripple-fg-size: 0;
    --mdc-ripple-left: 0;
    --mdc-ripple-top: 0;
    --mdc-ripple-fg-scale: 1;
    --mdc-ripple-fg-translate-end: 0;
    --mdc-ripple-fg-translate-start: 0;
    -webkit-tap-highlight-color: transparent;
    will-change: transform, opacity;
    /* @alternate */
    color: #6200ee;
    color: var(--mdc-theme-primary, #6200ee);
    border-radius: 4px;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 1px);
    height: calc(100% - 2px);
    transition: opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
    overflow: hidden; }
    .mdc-text-field__outline::before, .mdc-text-field__outline::after {
      position: absolute;
      border-radius: 50%;
      opacity: 0;
      pointer-events: none;
      content: ""; }
    .mdc-text-field__outline::before {
      transition: opacity 15ms linear; }
    .mdc-text-field__outline.mdc-ripple-upgraded::after {
      top: 0;
      left: 0;
      transform: scale(0);
      transform-origin: center center; }
    .mdc-text-field__outline.mdc-ripple-upgraded--unbounded::after {
      top: var(--mdc-ripple-top, 0);
      left: var(--mdc-ripple-left, 0); }
    .mdc-text-field__outline.mdc-ripple-upgraded--foreground-activation::after {
      animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards; }
    .mdc-text-field__outline.mdc-ripple-upgraded--foreground-deactivation::after {
      animation: 150ms mdc-ripple-fg-opacity-out;
      transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
    .mdc-text-field__outline::before, .mdc-text-field__outline::after {
      top: calc(50% - 100%);
      left: calc(50% - 100%);
      width: 200%;
      height: 200%; }
    .mdc-text-field__outline.mdc-ripple-upgraded::before {
      top: calc(50% - 100%);
      left: calc(50% - 100%);
      width: 200%;
      height: 200%;
      transform: scale(var(--mdc-ripple-fg-scale, 0)); }
    .mdc-text-field__outline.mdc-ripple-upgraded--unbounded::before {
      top: var(--mdc-ripple-top, calc(50% - 50%));
      left: var(--mdc-ripple-left, calc(50% - 50%));
      width: var(--mdc-ripple-fg-size, 100%);
      height: var(--mdc-ripple-fg-size, 100%);
      transform: scale(var(--mdc-ripple-fg-scale, 0)); }
    .mdc-text-field__outline.mdc-ripple-upgraded::after {
      width: var(--mdc-ripple-fg-size, 100%);
      height: var(--mdc-ripple-fg-size, 100%); }
    .mdc-text-field__outline::before, .mdc-text-field__outline::after {
      /* @alternate */
      background-color: rgba(0, 0, 0, 0.87); }
      @supports not (-ms-ime-align: auto) {
        .mdc-text-field__outline::before, .mdc-text-field__outline::after {
          background-color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87)); } }
    .mdc-text-field__outline:not(.mdc-ripple-upgraded)::after {
      transition: opacity 150ms linear; }
    .mdc-text-field__outline:not(.mdc-ripple-upgraded):active::after {
      transition-duration: 75ms;
      opacity: 0.16; }
    .mdc-text-field__outline.mdc-ripple-upgraded {
      --mdc-ripple-fg-opacity: 0.16; }
    .mdc-text-field__outline svg {
      position: absolute;
      width: 100%;
      height: 100%; }
      .mdc-text-field__outline svg .mdc-text-field__outline-path {
        stroke: rgba(0, 0, 0, 0.12);
        stroke-width: 1px;
        transition: stroke 180ms cubic-bezier(0.4, 0, 0.2, 1), stroke-width 180ms cubic-bezier(0.4, 0, 0.2, 1), opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
        fill: transparent; }

  @keyframes invalid-shake-float-above-standard {
    0% {
      transform: translateX(0) translateY(-100%) scale(0.75); }
    33% {
      animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
      transform: translateX(5px) translateY(-100%) scale(0.75); }
    66% {
      animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
      transform: translateX(-5px) translateY(-100%) scale(0.75); }
    100% {
      transform: translateX(0) translateY(-100%) scale(0.75); } }

  @keyframes invalid-shake-float-above-box {
    0% {
      transform: translateX(0) translateY(-50%) scale(0.75); }
    33% {
      animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
      transform: translateX(5px) translateY(-50%) scale(0.75); }
    66% {
      animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
      transform: translateX(-5px) translateY(-50%) scale(0.75); }
    100% {
      transform: translateX(0) translateY(-50%) scale(0.75); } }

  @keyframes invalid-shake-float-above-outlined {
    0% {
      transform: translateX(0) translateY(-130%) scale(0.75); }
    33% {
      animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
      transform: translateX(5px) translateY(-130%) scale(0.75); }
    66% {
      animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
      transform: translateX(-5px) translateY(-130%) scale(0.75); }
    100% {
      transform: translateX(0) translateY(-130%) scale(0.75); } }

  @keyframes invalid-shake-float-above-outlined-dense {
    0% {
      transform: translateX(0) translateY(-138%) scale(0.923); }
    33% {
      animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
      transform: translateX(5px) translateY(-138%) scale(0.923); }
    66% {
      animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
      transform: translateX(-5px) translateY(-138%) scale(0.923); }
    100% {
      transform: translateX(0) translateY(-138%) scale(0.923); } }

  .mdc-text-field {
    display: inline-block;
    position: relative;
    margin-bottom: 8px;
    will-change: opacity, transform, color; }

  .mdc-text-field__input {
    /* @alternate */
    color: rgba(0, 0, 0, 0.87);
    color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87));
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    letter-spacing: 0.04em;
    width: 100%;
    padding: 0 0 8px;
    transition: opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 0;
    background: none;
    font-size: inherit;
    appearance: none; }
    .mdc-text-field__input::placeholder {
      /* @alternate */
      color: rgba(0, 0, 0, 0.38);
      color: var(--mdc-theme-text-hint-on-light, rgba(0, 0, 0, 0.38));
      transition: color 180ms cubic-bezier(0.4, 0, 0.2, 1);
      opacity: 1; }
    .mdc-text-field__input:hover {
      border-color: black; }
    .mdc-text-field__input:focus {
      outline: none; }
      .mdc-text-field__input:focus::placeholder {
        color: rgba(0, 0, 0, 0.38); }
    .mdc-text-field__input:invalid {
      box-shadow: none; }
    .mdc-text-field__input--theme-dark,
    .mdc-theme--dark .mdc-text-field__input {
      /* @alternate */
      color: white;
      color: var(--mdc-theme-text-primary-on-dark, white);
      border-bottom: 1px solid rgba(255, 255, 255, 0.5); }
      .mdc-text-field__input--theme-dark:hover,
      .mdc-theme--dark .mdc-text-field__input:hover {
        border-bottom: 1px solid white; }
      .mdc-text-field__input--theme-dark::placeholder,
      .mdc-theme--dark .mdc-text-field__input::placeholder {
        /* @alternate */
        color: rgba(255, 255, 255, 0.5);
        color: var(--mdc-theme-text-hint-on-dark, rgba(255, 255, 255, 0.5)); }
      .mdc-text-field__input--theme-dark:focus::placeholder,
      .mdc-theme--dark .mdc-text-field__input:focus::placeholder {
        color: rgba(255, 255, 255, 0.3); }

  .mdc-text-field__input:-webkit-autofill + .mdc-text-field__label {
    transform: translateY(-100%) scale(0.75, 0.75);
    cursor: auto; }

  .mdc-text-field--outlined {
    height: 56px;
    border: none; }
    .mdc-text-field--outlined .mdc-text-field__input {
      display: flex;
      height: 30px;
      padding: 12px;
      border: none;
      background-color: transparent;
      z-index: 1; }
      .mdc-text-field--outlined .mdc-text-field__input:hover ~ .mdc-text-field__idle-outline {
        border: 1px solid rgba(0, 0, 0, 0.87); }
    .mdc-text-field--outlined .mdc-text-field__label {
      left: 16px;
      right: initial;
      position: absolute;
      bottom: 20px;
      transition: transform 260ms ease; }
      [dir="rtl"] .mdc-text-field--outlined .mdc-text-field__label, .mdc-text-field--outlined .mdc-text-field__label[dir="rtl"] {
        left: initial;
        right: 16px; }
      .mdc-text-field--outlined .mdc-text-field__label--float-above {
        transform: scale(0.75) translateY(-170%); }
        .mdc-text-field--outlined .mdc-text-field__label--float-above.mdc-text-field__label--shake {
          animation: invalid-shake-float-above-outlined 250ms 1; }
    .mdc-text-field--outlined .mdc-text-field__icon {
      z-index: 2; }
      .mdc-text-field--outlined .mdc-text-field__icon:hover ~ .mdc-text-field__idle-outline {
        border: 1px solid rgba(0, 0, 0, 0.87); }
    .mdc-text-field--outlined.mdc-text-field--focused .mdc-text-field__idle-outline,
    .mdc-text-field--outlined .mdc-text-field__label--float-above ~ .mdc-text-field__idle-outline {
      opacity: 0; }
    .mdc-text-field--outlined.mdc-text-field--focused .mdc-text-field__outline,
    .mdc-text-field--outlined .mdc-text-field__label--float-above ~ .mdc-text-field__outline {
      opacity: 1; }
    .mdc-text-field--outlined.mdc-text-field--focused .mdc-text-field__outline-path {
      /* @alternate */
      stroke: #6200ee;
      stroke: var(--mdc-theme-primary, #6200ee);
      stroke-width: 2px; }
    .mdc-text-field--outlined:not(.mdc-text-field--invalid).mdc-text-field--focused .mdc-text-field__label {
      /* @alternate */
      color: #6200ee;
      color: var(--mdc-theme-primary, #6200ee); }
    .mdc-text-field--outlined:not(.mdc-text-field--invalid) .mdc-text-field__input:hover ~ .mdc-text-field__outline .mdc-text-field__outline-path {
      stroke: rgba(0, 0, 0, 0.87); }
    .mdc-text-field--outlined.mdc-text-field--disabled {
      color: rgba(0, 0, 0, 0.38); }
      .mdc-text-field--outlined.mdc-text-field--disabled .mdc-text-field__input {
        border-bottom: none; }
      .mdc-text-field--outlined.mdc-text-field--disabled .mdc-text-field__outline-path {
        stroke: rgba(0, 0, 0, 0.06);
        stroke-width: 1px; }
      .mdc-text-field--outlined.mdc-text-field--disabled .mdc-text-field__idle-outline {
        border-color: rgba(0, 0, 0, 0.06); }
      .mdc-text-field--outlined.mdc-text-field--disabled .mdc-text-field__label {
        bottom: 20px; }
      .mdc-text-field--outlined.mdc-text-field--disabled .mdc-text-field__icon {
        color: rgba(0, 0, 0, 0.3); }
    .mdc-text-field--outlined.mdc-text-field--dense {
      height: 48px; }
      .mdc-text-field--outlined.mdc-text-field--dense .mdc-text-field__input {
        padding: 12px 12px 7px; }
      .mdc-text-field--outlined.mdc-text-field--dense .mdc-text-field__label {
        bottom: 18px; }
        .mdc-text-field--outlined.mdc-text-field--dense .mdc-text-field__label--float-above {
          transform: translateY(calc(-122% - 2px)) scale(0.923); }
          .mdc-text-field--outlined.mdc-text-field--dense .mdc-text-field__label--float-above.mdc-text-field__label--shake {
            animation: invalid-shake-float-above-outlined-dense 250ms 1; }
      .mdc-text-field--outlined.mdc-text-field--dense .mdc-text-field__icon {
        top: 12px; }

  .mdc-text-field--box {
    --mdc-ripple-fg-size: 0;
    --mdc-ripple-left: 0;
    --mdc-ripple-top: 0;
    --mdc-ripple-fg-scale: 1;
    --mdc-ripple-fg-translate-end: 0;
    --mdc-ripple-fg-translate-start: 0;
    -webkit-tap-highlight-color: transparent;
    will-change: transform, opacity;
    border-radius: 4px 4px 0 0;
    display: inline-flex;
    position: relative;
    height: 56px;
    margin-top: 16px;
    background-color: rgba(0, 0, 0, 0.04);
    overflow: hidden; }
    .mdc-text-field--box::before, .mdc-text-field--box::after {
      position: absolute;
      border-radius: 50%;
      opacity: 0;
      pointer-events: none;
      content: ""; }
    .mdc-text-field--box::before {
      transition: opacity 15ms linear; }
    .mdc-text-field--box.mdc-ripple-upgraded::after {
      top: 0;
      left: 0;
      transform: scale(0);
      transform-origin: center center; }
    .mdc-text-field--box.mdc-ripple-upgraded--unbounded::after {
      top: var(--mdc-ripple-top, 0);
      left: var(--mdc-ripple-left, 0); }
    .mdc-text-field--box.mdc-ripple-upgraded--foreground-activation::after {
      animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards; }
    .mdc-text-field--box.mdc-ripple-upgraded--foreground-deactivation::after {
      animation: 150ms mdc-ripple-fg-opacity-out;
      transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
    .mdc-text-field--box::before, .mdc-text-field--box::after {
      /* @alternate */
      background-color: rgba(0, 0, 0, 0.87); }
      @supports not (-ms-ime-align: auto) {
        .mdc-text-field--box::before, .mdc-text-field--box::after {
          background-color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87)); } }
    .mdc-text-field--box:hover::before {
      opacity: 0.04; }
    .mdc-text-field--box:not(.mdc-ripple-upgraded):focus::before, .mdc-text-field--box:not(.mdc-ripple-upgraded):focus-within::before, .mdc-text-field--box.mdc-ripple-upgraded--background-focused::before {
      transition-duration: 75ms;
      opacity: 0.12; }
    .mdc-text-field--box:not(.mdc-ripple-upgraded)::after {
      transition: opacity 150ms linear; }
    .mdc-text-field--box:not(.mdc-ripple-upgraded):active::after {
      transition-duration: 75ms;
      opacity: 0.16; }
    .mdc-text-field--box.mdc-ripple-upgraded {
      --mdc-ripple-fg-opacity: 0.16; }
    .mdc-text-field--box::before, .mdc-text-field--box::after {
      top: calc(50% - 100%);
      left: calc(50% - 100%);
      width: 200%;
      height: 200%; }
    .mdc-text-field--box.mdc-ripple-upgraded::before {
      top: calc(50% - 100%);
      left: calc(50% - 100%);
      width: 200%;
      height: 200%;
      transform: scale(var(--mdc-ripple-fg-scale, 0)); }
    .mdc-text-field--box.mdc-ripple-upgraded--unbounded::before {
      top: var(--mdc-ripple-top, calc(50% - 50%));
      left: var(--mdc-ripple-left, calc(50% - 50%));
      width: var(--mdc-ripple-fg-size, 100%);
      height: var(--mdc-ripple-fg-size, 100%);
      transform: scale(var(--mdc-ripple-fg-scale, 0)); }
    .mdc-text-field--box.mdc-ripple-upgraded::after {
      width: var(--mdc-ripple-fg-size, 100%);
      height: var(--mdc-ripple-fg-size, 100%); }
    .mdc-text-field--theme-dark.mdc-text-field--box,
    .mdc-theme--dark .mdc-text-field--box {
      background-color: rgba(255, 255, 255, 0.1); }
    .mdc-text-field--theme-dark.mdc-text-field--box::before, .mdc-text-field--theme-dark.mdc-text-field--box::after,
    .mdc-theme--dark .mdc-text-field--box::before,
    .mdc-theme--dark .mdc-text-field--box::after {
      /* @alternate */
      background-color: white; }
      @supports not (-ms-ime-align: auto) {
        .mdc-text-field--theme-dark.mdc-text-field--box::before, .mdc-text-field--theme-dark.mdc-text-field--box::after,
        .mdc-theme--dark .mdc-text-field--box::before,
        .mdc-theme--dark .mdc-text-field--box::after {
          background-color: var(--mdc-theme-text-primary-on-dark, white); } }
    .mdc-text-field--theme-dark.mdc-text-field--box:hover::before,
    .mdc-theme--dark .mdc-text-field--box:hover::before {
      opacity: 0.08; }
    .mdc-text-field--theme-dark.mdc-text-field--box:not(.mdc-ripple-upgraded):focus::before, .mdc-text-field--theme-dark.mdc-text-field--box:not(.mdc-ripple-upgraded):focus-within::before, .mdc-text-field--theme-dark.mdc-text-field--box.mdc-ripple-upgraded--background-focused::before,
    .mdc-theme--dark .mdc-text-field--box:not(.mdc-ripple-upgraded):focus::before,
    .mdc-theme--dark .mdc-text-field--box:not(.mdc-ripple-upgraded):focus-within::before,
    .mdc-theme--dark .mdc-text-field--box.mdc-ripple-upgraded--background-focused::before {
      transition-duration: 75ms;
      opacity: 0.24; }
    .mdc-text-field--theme-dark.mdc-text-field--box:not(.mdc-ripple-upgraded)::after,
    .mdc-theme--dark .mdc-text-field--box:not(.mdc-ripple-upgraded)::after {
      transition: opacity 150ms linear; }
    .mdc-text-field--theme-dark.mdc-text-field--box:not(.mdc-ripple-upgraded):active::after,
    .mdc-theme--dark .mdc-text-field--box:not(.mdc-ripple-upgraded):active::after {
      transition-duration: 75ms;
      opacity: 0.32; }
    .mdc-text-field--theme-dark.mdc-text-field--box.mdc-ripple-upgraded,
    .mdc-theme--dark .mdc-text-field--box.mdc-ripple-upgraded {
      --mdc-ripple-fg-opacity: 0.32; }
    .mdc-text-field--box .mdc-text-field__input {
      align-self: flex-end;
      box-sizing: border-box;
      height: 100%;
      padding: 20px 16px 0; }
    .mdc-text-field--box .mdc-text-field__label {
      left: 16px;
      right: initial;
      position: absolute;
      bottom: 20px;
      width: calc(100% - 48px);
      color: rgba(0, 0, 0, 0.6);
      text-overflow: ellipsis;
      white-space: nowrap;
      pointer-events: none;
      overflow: hidden;
      will-change: transform; }
      [dir="rtl"] .mdc-text-field--box .mdc-text-field__label, .mdc-text-field--box .mdc-text-field__label[dir="rtl"] {
        left: initial;
        right: 16px; }
      .mdc-text-field--theme-dark .mdc-text-field--box .mdc-text-field__label,
      .mdc-theme--dark .mdc-text-field--box .mdc-text-field__label {
        /* @alternate */
        color: rgba(255, 255, 255, 0.7);
        color: var(--mdc-theme-text-secondary-on-dark, rgba(255, 255, 255, 0.7)); }
      .mdc-text-field--box .mdc-text-field__label--float-above {
        transform: translateY(-50%) scale(0.75, 0.75); }
    .mdc-text-field--box .mdc-text-field__label--float-above.mdc-text-field__label--shake {
      animation: invalid-shake-float-above-box 250ms 1; }
    .mdc-text-field--box.mdc-text-field--disabled {
      color: rgba(0, 0, 0, 0.38);
      border-bottom: none;
      background-color: rgba(0, 0, 0, 0.02); }
      .mdc-text-field--theme-dark.mdc-text-field--box.mdc-text-field--disabled,
      .mdc-theme--dark .mdc-text-field--box.mdc-text-field--disabled {
        background-color: #303030;
        color: rgba(255, 255, 255, 0.3);
        border-bottom: none; }
      .mdc-text-field--box.mdc-text-field--disabled .mdc-text-field__label {
        bottom: 20px; }
      .mdc-text-field--box.mdc-text-field--disabled .mdc-text-field__icon {
        color: rgba(0, 0, 0, 0.3); }
        .mdc-text-field--box.mdc-text-field--disabled .mdc-text-field__icon--theme-dark,
        .mdc-theme--dark .mdc-text-field--box.mdc-text-field--disabled .mdc-text-field__icon {
          color: rgba(255, 255, 255, 0.3); }
    .mdc-text-field--box.mdc-text-field--dense .mdc-text-field__input {
      padding: 12px 12px 0; }
    .mdc-text-field--box.mdc-text-field--dense .mdc-text-field__label {
      bottom: 20px; }
      .mdc-text-field--box.mdc-text-field--dense .mdc-text-field__label--float-above {
        transform: translateY(calc(-75% - 2px)) scale(0.923, 0.923); }

  .mdc-text-field--with-leading-icon .mdc-text-field__input {
    padding-left: 48px;
    padding-right: 15px; }
    [dir="rtl"] .mdc-text-field--with-leading-icon .mdc-text-field__input, .mdc-text-field--with-leading-icon .mdc-text-field__input[dir="rtl"] {
      padding-left: 15px;
      padding-right: 48px; }

  .mdc-text-field--with-leading-icon .mdc-text-field__icon {
    left: 15px;
    right: initial; }
    [dir="rtl"] .mdc-text-field--with-leading-icon .mdc-text-field__icon, .mdc-text-field--with-leading-icon .mdc-text-field__icon[dir="rtl"] {
      left: initial;
      right: 15px; }

  .mdc-text-field--with-leading-icon .mdc-text-field__label {
    left: 48px;
    right: initial; }
    [dir="rtl"] .mdc-text-field--with-leading-icon .mdc-text-field__label, .mdc-text-field--with-leading-icon .mdc-text-field__label[dir="rtl"] {
      left: initial;
      right: 48px; }

  .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-text-field__label--float-above {
    transform: scale(0.75) translateX(-36%) translateY(-170%); }
    [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-text-field__label--float-above, .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-text-field__label--float-above[dir="rtl"] {
      transform: scale(0.75) translateX(36%) translateY(-170%); }

  .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-text-field__label--float-above {
    transform: translateX(-25%) translateY(-145%) scale(0.923); }
    [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-text-field__label--float-above, .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-text-field__label--float-above[dir="rtl"] {
      transform: translateX(25%) translateY(-145%) scale(0.923); }

  .mdc-text-field--with-trailing-icon .mdc-text-field__input {
    padding-left: 15px;
    padding-right: 48px; }
    [dir="rtl"] .mdc-text-field--with-trailing-icon .mdc-text-field__input, .mdc-text-field--with-trailing-icon .mdc-text-field__input[dir="rtl"] {
      padding-left: 48px;
      padding-right: 15px; }

  .mdc-text-field--with-trailing-icon .mdc-text-field__icon {
    left: initial;
    right: 15px; }
    [dir="rtl"] .mdc-text-field--with-trailing-icon .mdc-text-field__icon, .mdc-text-field--with-trailing-icon .mdc-text-field__icon[dir="rtl"] {
      left: 15px;
      right: initial; }

  .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__icon,
  .mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon {
    bottom: 16px;
    transform: scale(0.8); }

  .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__input {
    padding-left: 38px;
    padding-right: 12px; }
    [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__input, .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__input[dir="rtl"] {
      padding-left: 12px;
      padding-right: 38px; }

  .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__icon {
    left: 12px;
    right: initial; }
    [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__icon, .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__icon[dir="rtl"] {
      left: initial;
      right: 12px; }

  .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__label {
    left: 38px;
    right: initial; }
    [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__label, .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__label[dir="rtl"] {
      left: initial;
      right: 38px; }

  .mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__input {
    padding-left: 12px;
    padding-right: 38px; }
    [dir="rtl"] .mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__input, .mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__input[dir="rtl"] {
      padding-left: 38px;
      padding-right: 12px; }

  .mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon {
    left: initial;
    right: 12px; }
    [dir="rtl"] .mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon, .mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon[dir="rtl"] {
      left: 12px;
      right: initial; }

  .mdc-text-field--upgraded:not(.mdc-text-field--fullwidth):not(.mdc-text-field--box) {
    display: inline-flex;
    position: relative;
    align-items: flex-end;
    box-sizing: border-box;
    margin-top: 16px; }
    .mdc-text-field--upgraded:not(.mdc-text-field--fullwidth):not(.mdc-text-field--box):not(.mdc-text-field--textarea):not(.mdc-text-field--outlined) {
      height: 48px; }
    .mdc-text-field--upgraded:not(.mdc-text-field--fullwidth):not(.mdc-text-field--box) .mdc-text-field__label {
      pointer-events: none; }

  .mdc-text-field--invalid .mdc-text-field__label {
    color: #d50000; }

  .mdc-text-field--invalid .mdc-text-field__input {
    border-color: #d50000; }

  .mdc-text-field--invalid .mdc-text-field__bottom-line {
    background-color: #d50000; }

  .mdc-text-field--invalid .mdc-text-field__idle-outline {
    border-color: #d50000; }

  .mdc-text-field--invalid .mdc-text-field__input:hover ~ .mdc-text-field__idle-outline {
    border-color: #d50000; }

  .mdc-text-field--invalid .mdc-text-field__outline .mdc-text-field__outline-path {
    stroke: #d50000; }

  .mdc-text-field--invalid + .mdc-text-field-helper-text--validation-msg {
    opacity: 1;
    color: #d50000; }

  .mdc-text-field--theme-dark.mdc-text-field--invalid + .mdc-text-field-helper-text--validation-msg,
  .mdc-theme--dark .mdc-text-field--invalid + .mdc-text-field-helper-text--validation-msg {
    color: #ff6e6e; }

  .mdc-text-field--invalid.mdc-text-field--textarea {
    border-color: #d50000; }

  .mdc-text-field--dense {
    margin-top: 12px;
    margin-bottom: 4px;
    font-size: .813rem; }
    .mdc-text-field--dense .mdc-text-field__label--float-above {
      transform: translateY(calc(-100% - 2px)) scale(0.923, 0.923); }

  .mdc-text-field--disabled {
    pointer-events: none; }
    .mdc-text-field--disabled .mdc-text-field__input {
      border-bottom: 1px dotted rgba(35, 31, 32, 0.26); }
      .mdc-text-field--theme-dark.mdc-text-field--disabled .mdc-text-field__input,
      .mdc-theme--dark .mdc-text-field--disabled .mdc-text-field__input {
        border-bottom: 1px dotted rgba(255, 255, 255, 0.3); }
    .mdc-text-field--disabled .mdc-text-field__input,
    .mdc-text-field--disabled .mdc-text-field__label,
    .mdc-text-field--disabled + .mdc-text-field-helper-text {
      /* @alternate */
      color: rgba(0, 0, 0, 0.38);
      color: var(--mdc-theme-text-disabled-on-light, rgba(0, 0, 0, 0.38)); }
    .mdc-text-field--theme-dark .mdc-text-field--disabled .mdc-text-field__input,
    .mdc-theme--dark .mdc-text-field--disabled .mdc-text-field__input, .mdc-text-field--theme-dark
    .mdc-text-field--disabled .mdc-text-field__label,
    .mdc-theme--dark
    .mdc-text-field--disabled .mdc-text-field__label {
      /* @alternate */
      color: rgba(255, 255, 255, 0.5);
      color: var(--mdc-theme-text-disabled-on-dark, rgba(255, 255, 255, 0.5)); }
    .mdc-text-field--theme-dark.mdc-text-field--disabled + .mdc-text-field-helper-text,
    .mdc-theme--dark .mdc-text-field--disabled + .mdc-text-field-helper-text {
      /* @alternate */
      color: rgba(255, 255, 255, 0.5);
      color: var(--mdc-theme-text-disabled-on-dark, rgba(255, 255, 255, 0.5)); }
    .mdc-text-field--disabled .mdc-text-field__label {
      bottom: 8px;
      cursor: default; }

  .mdc-text-field__input:required + .mdc-text-field__label::after {
    margin-left: 1px;
    content: "*"; }

  .mdc-text-field--focused .mdc-text-field__input:required + .mdc-text-field__label::after {
    color: #d50000; }
    .mdc-text-field--theme-dark.mdc-text-field--focused .mdc-text-field__input:required + .mdc-text-field__label::after,
    .mdc-theme--dark .mdc-text-field--focused .mdc-text-field__input:required + .mdc-text-field__label::after {
      color: #ff6e6e; }

  .mdc-text-field--focused + .mdc-text-field-helper-text:not(.mdc-text-field-helper-text--validation-msg) {
    opacity: 1; }

  .mdc-text-field--textarea {
    border-radius: 4px;
    display: flex;
    height: initial;
    transition: none;
    border: 1px solid rgba(0, 0, 0, 0.73);
    overflow: hidden; }
    .mdc-text-field--textarea .mdc-text-field__label {
      border-radius: 4px 4px 0 0; }
    .mdc-text-field--textarea .mdc-text-field__input {
      border-radius: 2px; }
    .mdc-text-field--theme-dark .mdc-text-field--textarea,
    .mdc-theme--dark .mdc-text-field--textarea {
      border-color: white; }
    .mdc-text-field--textarea .mdc-text-field__input {
      padding: 16px;
      padding-top: 32px;
      border: 1px solid transparent; }
      .mdc-text-field--textarea .mdc-text-field__input:focus {
        /* @alternate */
        border-color: #6200ee;
        border-color: var(--mdc-theme-primary, #6200ee); }
      .mdc-text-field--textarea .mdc-text-field__input:invalid:not(:focus) {
        border-color: #d50000; }
      .mdc-text-field--theme-dark .mdc-text-field--textarea .mdc-text-field__input:hover,
      .mdc-theme--dark .mdc-text-field--textarea .mdc-text-field__input:hover {
        border-bottom-color: transparent; }
      .mdc-text-field--theme-dark .mdc-text-field--textarea .mdc-text-field__input:focus,
      .mdc-theme--dark .mdc-text-field--textarea .mdc-text-field__input:focus {
        /* @alternate */
        border-color: #018786;
        border-color: var(--mdc-theme-secondary, #018786); }
      .mdc-text-field--theme-dark .mdc-text-field--textarea .mdc-text-field__input:invalid:not(:focus),
      .mdc-theme--dark .mdc-text-field--textarea .mdc-text-field__input:invalid:not(:focus) {
        border-color: #ff6e6e; }
    .mdc-text-field--textarea .mdc-text-field__label {
      left: 1px;
      right: initial;
      top: 18px;
      bottom: auto;
      padding: 8px 16px;
      background-color: white; }
      [dir="rtl"] .mdc-text-field--textarea .mdc-text-field__label, .mdc-text-field--textarea .mdc-text-field__label[dir="rtl"] {
        left: initial;
        right: 1px; }
      .mdc-text-field--theme-dark .mdc-text-field--textarea .mdc-text-field__label,
      .mdc-theme--dark .mdc-text-field--textarea .mdc-text-field__label {
        background-color: #303030; }
      .mdc-text-field--textarea .mdc-text-field__label--float-above {
        transform: translateY(-50%) scale(0.923, 0.923); }
    .mdc-text-field--textarea.mdc-text-field--disabled {
      border-style: solid;
      border-color: rgba(35, 31, 32, 0.26);
      background-color: #f9f9f9; }
      .mdc-text-field--theme-dark .mdc-text-field--textarea.mdc-text-field--disabled,
      .mdc-theme--dark .mdc-text-field--textarea.mdc-text-field--disabled {
        border-color: rgba(255, 255, 255, 0.3);
        background-color: #2f2f2f; }
      .mdc-text-field--textarea.mdc-text-field--disabled .mdc-text-field__label {
        background-color: #f9f9f9; }
        .mdc-text-field--theme-dark .mdc-text-field--textarea.mdc-text-field--disabled .mdc-text-field__label,
        .mdc-theme--dark .mdc-text-field--textarea.mdc-text-field--disabled .mdc-text-field__label {
          background-color: #2f2f2f; }
    .mdc-text-field--textarea:not(.mdc-text-field--upgraded) .mdc-text-field__input {
      padding-top: 16px; }

  .mdc-text-field--textarea.mdc-text-field--focused {
    /* @alternate */
    border-color: #6200ee;
    border-color: var(--mdc-theme-primary, #6200ee); }

  .mdc-text-field--fullwidth {
    width: 100%; }
    .mdc-text-field--fullwidth:not(.mdc-text-field--textarea) {
      display: block;
      box-sizing: border-box;
      height: 56px;
      margin: 0;
      border: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      outline: none; }
      .mdc-text-field--fullwidth:not(.mdc-text-field--textarea) .mdc-text-field__input {
        width: 100%;
        height: 100%;
        padding: 0;
        resize: none;
        border: none !important; }
    .mdc-text-field--fullwidth--theme-dark,
    .mdc-theme--dark .mdc-text-field--fullwidth {
      border-bottom: 1px solid rgba(255, 255, 255, 0.12); }

  .mdc-text-field:not(.mdc-text-field--upgraded) .mdc-text-field__input {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
    .mdc-text-field:not(.mdc-text-field--upgraded) .mdc-text-field__input:focus {
      /* @alternate */
      border-color: #6200ee;
      border-color: var(--mdc-theme-primary, #6200ee); }
    .mdc-text-field:not(.mdc-text-field--upgraded) .mdc-text-field__input:disabled {
      color: rgba(0, 0, 0, 0.38);
      border-bottom-style: dotted; }
    .mdc-text-field:not(.mdc-text-field--upgraded) .mdc-text-field__input:invalid:not(:focus) {
      border-color: #d50000; }

  .mdc-text-field--theme-dark:not(.mdc-text-field--upgraded) .mdc-text-field__input:not(:focus),
  .mdc-theme--dark .mdc-text-field:not(.mdc-text-field--upgraded) .mdc-text-field__input:not(:focus) {
    border-color: rgba(255, 255, 255, 0.5); }

  .mdc-text-field--theme-dark:not(.mdc-text-field--upgraded) .mdc-text-field__input:disabled,
  .mdc-theme--dark .mdc-text-field:not(.mdc-text-field--upgraded) .mdc-text-field__input:disabled {
    color: rgba(255, 255, 255, 0.3);
    border-color: rgba(255, 255, 255, 0.3);
    background-color: #2f2f2f; }

  .mdc-text-field--theme-dark:not(.mdc-text-field--upgraded) .mdc-text-field__input:invalid:not(:focus),
  .mdc-theme--dark .mdc-text-field:not(.mdc-text-field--upgraded) .mdc-text-field__input:invalid:not(:focus) {
    border-color: #ff6e6e; }

  .mdc-text-field--outlined:not(.mdc-text-field--upgraded) {
    height: 56px; }
    .mdc-text-field--outlined:not(.mdc-text-field--upgraded) .mdc-text-field__input {
      border-radius: 4px;
      height: 100%;
      padding: 0 0 0 12px;
      border: 1px solid rgba(0, 0, 0, 0.12); }
      .mdc-text-field--outlined:not(.mdc-text-field--upgraded) .mdc-text-field__input:hover {
        border-color: rgba(0, 0, 0, 0.87); }
      .mdc-text-field--outlined:not(.mdc-text-field--upgraded) .mdc-text-field__input:focus {
        /* @alternate */
        border-color: #6200ee;
        border-color: var(--mdc-theme-primary, #6200ee); }

  .mdc-text-field--box:not(.mdc-text-field--upgraded) {
    height: 56px; }
    .mdc-text-field--box:not(.mdc-text-field--upgraded)::before, .mdc-text-field--box:not(.mdc-text-field--upgraded)::after {
      border-radius: 0; }
    .mdc-text-field--box:not(.mdc-text-field--upgraded) .mdc-text-field__input {
      padding-top: 0; }

  .mdc-text-field--dense + .mdc-text-field-helper-text {
    margin-bottom: 4px; }

  .mdc-text-field--box + .mdc-text-field-helper-text {
    margin-right: 16px;
    margin-left: 16px; }

  .mdc-form-field > .mdc-text-field + label {
    align-self: flex-start; }

  :root {
    --mdc-theme-primary: #6200ee;
    --mdc-theme-primary-light: #b37eff;
    --mdc-theme-primary-dark: #8936ff;
    --mdc-theme-secondary: #018786;
    --mdc-theme-secondary-light: #02cecc;
    --mdc-theme-secondary-dark: #004040;
    --mdc-theme-background: #fff;
    --mdc-theme-text-primary-on-primary: white;
    --mdc-theme-text-secondary-on-primary: rgba(255, 255, 255, 0.7);
    --mdc-theme-text-hint-on-primary: rgba(255, 255, 255, 0.5);
    --mdc-theme-text-disabled-on-primary: rgba(255, 255, 255, 0.5);
    --mdc-theme-text-icon-on-primary: rgba(255, 255, 255, 0.5);
    --mdc-theme-text-primary-on-primary-light: rgba(0, 0, 0, 0.87);
    --mdc-theme-text-secondary-on-primary-light: rgba(0, 0, 0, 0.54);
    --mdc-theme-text-hint-on-primary-light: rgba(0, 0, 0, 0.38);
    --mdc-theme-text-disabled-on-primary-light: rgba(0, 0, 0, 0.38);
    --mdc-theme-text-icon-on-primary-light: rgba(0, 0, 0, 0.38);
    --mdc-theme-text-primary-on-primary-dark: white;
    --mdc-theme-text-secondary-on-primary-dark: rgba(255, 255, 255, 0.7);
    --mdc-theme-text-hint-on-primary-dark: rgba(255, 255, 255, 0.5);
    --mdc-theme-text-disabled-on-primary-dark: rgba(255, 255, 255, 0.5);
    --mdc-theme-text-icon-on-primary-dark: rgba(255, 255, 255, 0.5);
    --mdc-theme-text-primary-on-secondary: white;
    --mdc-theme-text-secondary-on-secondary: rgba(255, 255, 255, 0.7);
    --mdc-theme-text-hint-on-secondary: rgba(255, 255, 255, 0.5);
    --mdc-theme-text-disabled-on-secondary: rgba(255, 255, 255, 0.5);
    --mdc-theme-text-icon-on-secondary: rgba(255, 255, 255, 0.5);
    --mdc-theme-text-primary-on-secondary-light: rgba(0, 0, 0, 0.87);
    --mdc-theme-text-secondary-on-secondary-light: rgba(0, 0, 0, 0.54);
    --mdc-theme-text-hint-on-secondary-light: rgba(0, 0, 0, 0.38);
    --mdc-theme-text-disabled-on-secondary-light: rgba(0, 0, 0, 0.38);
    --mdc-theme-text-icon-on-secondary-light: rgba(0, 0, 0, 0.38);
    --mdc-theme-text-primary-on-secondary-dark: white;
    --mdc-theme-text-secondary-on-secondary-dark: rgba(255, 255, 255, 0.7);
    --mdc-theme-text-hint-on-secondary-dark: rgba(255, 255, 255, 0.5);
    --mdc-theme-text-disabled-on-secondary-dark: rgba(255, 255, 255, 0.5);
    --mdc-theme-text-icon-on-secondary-dark: rgba(255, 255, 255, 0.5);
    --mdc-theme-text-primary-on-background: rgba(0, 0, 0, 0.87);
    --mdc-theme-text-secondary-on-background: rgba(0, 0, 0, 0.54);
    --mdc-theme-text-hint-on-background: rgba(0, 0, 0, 0.38);
    --mdc-theme-text-disabled-on-background: rgba(0, 0, 0, 0.38);
    --mdc-theme-text-icon-on-background: rgba(0, 0, 0, 0.38);
    --mdc-theme-text-primary-on-light: rgba(0, 0, 0, 0.87);
    --mdc-theme-text-secondary-on-light: rgba(0, 0, 0, 0.54);
    --mdc-theme-text-hint-on-light: rgba(0, 0, 0, 0.38);
    --mdc-theme-text-disabled-on-light: rgba(0, 0, 0, 0.38);
    --mdc-theme-text-icon-on-light: rgba(0, 0, 0, 0.38);
    --mdc-theme-text-primary-on-dark: white;
    --mdc-theme-text-secondary-on-dark: rgba(255, 255, 255, 0.7);
    --mdc-theme-text-hint-on-dark: rgba(255, 255, 255, 0.5);
    --mdc-theme-text-disabled-on-dark: rgba(255, 255, 255, 0.5);
    --mdc-theme-text-icon-on-dark: rgba(255, 255, 255, 0.5); }

  .mdc-theme--background {
    /* @alternate */
    background-color: #fff;
    background-color: var(--mdc-theme-background, #fff); }

  .mdc-theme--primary {
    /* @alternate */
    color: #6200ee !important;
    color: var(--mdc-theme-primary, #6200ee) !important; }

  .mdc-theme--primary-light {
    /* @alternate */
    color: #b37eff !important;
    color: var(--mdc-theme-primary-light, #b37eff) !important; }

  .mdc-theme--primary-dark {
    /* @alternate */
    color: #8936ff !important;
    color: var(--mdc-theme-primary-dark, #8936ff) !important; }

  .mdc-theme--secondary {
    /* @alternate */
    color: #018786 !important;
    color: var(--mdc-theme-secondary, #018786) !important; }

  .mdc-theme--secondary-light {
    /* @alternate */
    color: #02cecc !important;
    color: var(--mdc-theme-secondary-light, #02cecc) !important; }

  .mdc-theme--secondary-dark {
    /* @alternate */
    color: #004040 !important;
    color: var(--mdc-theme-secondary-dark, #004040) !important; }

  .mdc-theme--text-primary-on-primary {
    /* @alternate */
    color: white !important;
    color: var(--mdc-theme-text-primary-on-primary, white) !important; }

  .mdc-theme--text-secondary-on-primary {
    /* @alternate */
    color: rgba(255, 255, 255, 0.7) !important;
    color: var(--mdc-theme-text-secondary-on-primary, rgba(255, 255, 255, 0.7)) !important; }

  .mdc-theme--text-hint-on-primary {
    /* @alternate */
    color: rgba(255, 255, 255, 0.5) !important;
    color: var(--mdc-theme-text-hint-on-primary, rgba(255, 255, 255, 0.5)) !important; }

  .mdc-theme--text-disabled-on-primary {
    /* @alternate */
    color: rgba(255, 255, 255, 0.5) !important;
    color: var(--mdc-theme-text-disabled-on-primary, rgba(255, 255, 255, 0.5)) !important; }

  .mdc-theme--text-icon-on-primary {
    /* @alternate */
    color: rgba(255, 255, 255, 0.5) !important;
    color: var(--mdc-theme-text-icon-on-primary, rgba(255, 255, 255, 0.5)) !important; }

  .mdc-theme--text-primary-on-primary-light {
    /* @alternate */
    color: rgba(0, 0, 0, 0.87) !important;
    color: var(--mdc-theme-text-primary-on-primary-light, rgba(0, 0, 0, 0.87)) !important; }

  .mdc-theme--text-secondary-on-primary-light {
    /* @alternate */
    color: rgba(0, 0, 0, 0.54) !important;
    color: var(--mdc-theme-text-secondary-on-primary-light, rgba(0, 0, 0, 0.54)) !important; }

  .mdc-theme--text-hint-on-primary-light {
    /* @alternate */
    color: rgba(0, 0, 0, 0.38) !important;
    color: var(--mdc-theme-text-hint-on-primary-light, rgba(0, 0, 0, 0.38)) !important; }

  .mdc-theme--text-disabled-on-primary-light {
    /* @alternate */
    color: rgba(0, 0, 0, 0.38) !important;
    color: var(--mdc-theme-text-disabled-on-primary-light, rgba(0, 0, 0, 0.38)) !important; }

  .mdc-theme--text-icon-on-primary-light {
    /* @alternate */
    color: rgba(0, 0, 0, 0.38) !important;
    color: var(--mdc-theme-text-icon-on-primary-light, rgba(0, 0, 0, 0.38)) !important; }

  .mdc-theme--text-primary-on-primary-dark {
    /* @alternate */
    color: white !important;
    color: var(--mdc-theme-text-primary-on-primary-dark, white) !important; }

  .mdc-theme--text-secondary-on-primary-dark {
    /* @alternate */
    color: rgba(255, 255, 255, 0.7) !important;
    color: var(--mdc-theme-text-secondary-on-primary-dark, rgba(255, 255, 255, 0.7)) !important; }

  .mdc-theme--text-hint-on-primary-dark {
    /* @alternate */
    color: rgba(255, 255, 255, 0.5) !important;
    color: var(--mdc-theme-text-hint-on-primary-dark, rgba(255, 255, 255, 0.5)) !important; }

  .mdc-theme--text-disabled-on-primary-dark {
    /* @alternate */
    color: rgba(255, 255, 255, 0.5) !important;
    color: var(--mdc-theme-text-disabled-on-primary-dark, rgba(255, 255, 255, 0.5)) !important; }

  .mdc-theme--text-icon-on-primary-dark {
    /* @alternate */
    color: rgba(255, 255, 255, 0.5) !important;
    color: var(--mdc-theme-text-icon-on-primary-dark, rgba(255, 255, 255, 0.5)) !important; }

  .mdc-theme--text-primary-on-secondary {
    /* @alternate */
    color: white !important;
    color: var(--mdc-theme-text-primary-on-secondary, white) !important; }

  .mdc-theme--text-secondary-on-secondary {
    /* @alternate */
    color: rgba(255, 255, 255, 0.7) !important;
    color: var(--mdc-theme-text-secondary-on-secondary, rgba(255, 255, 255, 0.7)) !important; }

  .mdc-theme--text-hint-on-secondary {
    /* @alternate */
    color: rgba(255, 255, 255, 0.5) !important;
    color: var(--mdc-theme-text-hint-on-secondary, rgba(255, 255, 255, 0.5)) !important; }

  .mdc-theme--text-disabled-on-secondary {
    /* @alternate */
    color: rgba(255, 255, 255, 0.5) !important;
    color: var(--mdc-theme-text-disabled-on-secondary, rgba(255, 255, 255, 0.5)) !important; }

  .mdc-theme--text-icon-on-secondary {
    /* @alternate */
    color: rgba(255, 255, 255, 0.5) !important;
    color: var(--mdc-theme-text-icon-on-secondary, rgba(255, 255, 255, 0.5)) !important; }

  .mdc-theme--text-primary-on-secondary-light {
    /* @alternate */
    color: rgba(0, 0, 0, 0.87) !important;
    color: var(--mdc-theme-text-primary-on-secondary-light, rgba(0, 0, 0, 0.87)) !important; }

  .mdc-theme--text-secondary-on-secondary-light {
    /* @alternate */
    color: rgba(0, 0, 0, 0.54) !important;
    color: var(--mdc-theme-text-secondary-on-secondary-light, rgba(0, 0, 0, 0.54)) !important; }

  .mdc-theme--text-hint-on-secondary-light {
    /* @alternate */
    color: rgba(0, 0, 0, 0.38) !important;
    color: var(--mdc-theme-text-hint-on-secondary-light, rgba(0, 0, 0, 0.38)) !important; }

  .mdc-theme--text-disabled-on-secondary-light {
    /* @alternate */
    color: rgba(0, 0, 0, 0.38) !important;
    color: var(--mdc-theme-text-disabled-on-secondary-light, rgba(0, 0, 0, 0.38)) !important; }

  .mdc-theme--text-icon-on-secondary-light {
    /* @alternate */
    color: rgba(0, 0, 0, 0.38) !important;
    color: var(--mdc-theme-text-icon-on-secondary-light, rgba(0, 0, 0, 0.38)) !important; }

  .mdc-theme--text-primary-on-secondary-dark {
    /* @alternate */
    color: white !important;
    color: var(--mdc-theme-text-primary-on-secondary-dark, white) !important; }

  .mdc-theme--text-secondary-on-secondary-dark {
    /* @alternate */
    color: rgba(255, 255, 255, 0.7) !important;
    color: var(--mdc-theme-text-secondary-on-secondary-dark, rgba(255, 255, 255, 0.7)) !important; }

  .mdc-theme--text-hint-on-secondary-dark {
    /* @alternate */
    color: rgba(255, 255, 255, 0.5) !important;
    color: var(--mdc-theme-text-hint-on-secondary-dark, rgba(255, 255, 255, 0.5)) !important; }

  .mdc-theme--text-disabled-on-secondary-dark {
    /* @alternate */
    color: rgba(255, 255, 255, 0.5) !important;
    color: var(--mdc-theme-text-disabled-on-secondary-dark, rgba(255, 255, 255, 0.5)) !important; }

  .mdc-theme--text-icon-on-secondary-dark {
    /* @alternate */
    color: rgba(255, 255, 255, 0.5) !important;
    color: var(--mdc-theme-text-icon-on-secondary-dark, rgba(255, 255, 255, 0.5)) !important; }

  .mdc-theme--text-primary-on-background {
    /* @alternate */
    color: rgba(0, 0, 0, 0.87) !important;
    color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87)) !important; }

  .mdc-theme--text-secondary-on-background {
    /* @alternate */
    color: rgba(0, 0, 0, 0.54) !important;
    color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54)) !important; }

  .mdc-theme--text-hint-on-background {
    /* @alternate */
    color: rgba(0, 0, 0, 0.38) !important;
    color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38)) !important; }

  .mdc-theme--text-disabled-on-background {
    /* @alternate */
    color: rgba(0, 0, 0, 0.38) !important;
    color: var(--mdc-theme-text-disabled-on-background, rgba(0, 0, 0, 0.38)) !important; }

  .mdc-theme--text-icon-on-background {
    /* @alternate */
    color: rgba(0, 0, 0, 0.38) !important;
    color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38)) !important; }

  .mdc-theme--text-primary-on-light {
    /* @alternate */
    color: rgba(0, 0, 0, 0.87) !important;
    color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87)) !important; }

  .mdc-theme--text-secondary-on-light {
    /* @alternate */
    color: rgba(0, 0, 0, 0.54) !important;
    color: var(--mdc-theme-text-secondary-on-light, rgba(0, 0, 0, 0.54)) !important; }

  .mdc-theme--text-hint-on-light {
    /* @alternate */
    color: rgba(0, 0, 0, 0.38) !important;
    color: var(--mdc-theme-text-hint-on-light, rgba(0, 0, 0, 0.38)) !important; }

  .mdc-theme--text-disabled-on-light {
    /* @alternate */
    color: rgba(0, 0, 0, 0.38) !important;
    color: var(--mdc-theme-text-disabled-on-light, rgba(0, 0, 0, 0.38)) !important; }

  .mdc-theme--text-icon-on-light {
    /* @alternate */
    color: rgba(0, 0, 0, 0.38) !important;
    color: var(--mdc-theme-text-icon-on-light, rgba(0, 0, 0, 0.38)) !important; }

  .mdc-theme--text-primary-on-dark {
    /* @alternate */
    color: white !important;
    color: var(--mdc-theme-text-primary-on-dark, white) !important; }

  .mdc-theme--text-secondary-on-dark {
    /* @alternate */
    color: rgba(255, 255, 255, 0.7) !important;
    color: var(--mdc-theme-text-secondary-on-dark, rgba(255, 255, 255, 0.7)) !important; }

  .mdc-theme--text-hint-on-dark {
    /* @alternate */
    color: rgba(255, 255, 255, 0.5) !important;
    color: var(--mdc-theme-text-hint-on-dark, rgba(255, 255, 255, 0.5)) !important; }

  .mdc-theme--text-disabled-on-dark {
    /* @alternate */
    color: rgba(255, 255, 255, 0.5) !important;
    color: var(--mdc-theme-text-disabled-on-dark, rgba(255, 255, 255, 0.5)) !important; }

  .mdc-theme--text-icon-on-dark {
    /* @alternate */
    color: rgba(255, 255, 255, 0.5) !important;
    color: var(--mdc-theme-text-icon-on-dark, rgba(255, 255, 255, 0.5)) !important; }

  .mdc-theme--primary-bg {
    /* @alternate */
    background-color: #6200ee !important;
    background-color: var(--mdc-theme-primary, #6200ee) !important; }

  .mdc-theme--primary-light-bg {
    /* @alternate */
    background-color: #b37eff !important;
    background-color: var(--mdc-theme-primary-light, #b37eff) !important; }

  .mdc-theme--primary-dark-bg {
    /* @alternate */
    background-color: #8936ff !important;
    background-color: var(--mdc-theme-primary-dark, #8936ff) !important; }

  .mdc-theme--secondary-bg {
    /* @alternate */
    background-color: #018786 !important;
    background-color: var(--mdc-theme-secondary, #018786) !important; }

  .mdc-theme--secondary-light-bg {
    /* @alternate */
    background-color: #02cecc !important;
    background-color: var(--mdc-theme-secondary-light, #02cecc) !important; }

  .mdc-theme--secondary-dark-bg {
    /* @alternate */
    background-color: #004040 !important;
    background-color: var(--mdc-theme-secondary-dark, #004040) !important; }

  /**
   * The css property used for elevation. In most cases this should not be changed. It is exposed
   * as a variable for abstraction / easy use when needing to reference the property directly, for
   * example in a `will-change` rule.
   */
  /**
   * The default duration value for elevation transitions.
   */
  /**
   * The default easing value for elevation transitions.
   */
  /**
   * Applies the correct CSS rules to an element to give it the elevation specified by $z-value.
   * The $z-value must be between 0 and 24.
   * If $color has an alpha channel, it will be ignored and overridden. To increase the opacity of the shadow, use
   * $opacity-boost.
   */
  /**
   * Returns a string that can be used as the value for a `transition` property for elevation.
   * Calling this function directly is useful in situations where a component needs to transition
   * more than one property.
   *
   * ```scss
   * .foo {
   *   transition: mdc-elevation-transition-value(), opacity 100ms ease;
   *   will-change: $mdc-elevation-property, opacity;
   * }
   * ```
   */
  /**
   * Creates a rule that will be applied when an MDC-Web component is within the context of an RTL layout.
   *
   * Usage Example:
   * ```scss
   * .mdc-foo {
   *   position: absolute;
   *   left: 0;
   *
   *   @include mdc-rtl {
   *     left: auto;
   *     right: 0;
   *   }
   *
   *   &__bar {
   *     margin-left: 4px;
   *     @include mdc-rtl(".mdc-foo") {
   *       margin-left: auto;
   *       margin-right: 4px;
   *     }
   *   }
   * }
   *
   * .mdc-foo--mod {
   *   padding-left: 4px;
   *
   *   @include mdc-rtl {
   *     padding-left: auto;
   *     padding-right: 4px;
   *   }
   * }
   * ```
   *
   * Note that this works by checking for [dir="rtl"] on an ancestor element. While this will work
   * in most cases, it will in some cases lead to false negatives, e.g.
   *
   * ```html
   * <html dir="rtl">
   *   <!-- ... -->
   *   <div dir="ltr">
   *     <div class="mdc-foo">Styled incorrectly as RTL!</div>
   *   </div>
   * </html>
   * ```
   *
   * In the future, selectors such as :dir (http://mdn.io/:dir) will help us mitigate this.
   */
  /**
   * Takes a base box-model property - e.g. margin / border / padding - along with a default
   * direction and value, and emits rules which apply the value to the
   * "<base-property>-<default-direction>" property by default, but flips the direction
   * when within an RTL context.
   *
   * For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-box(margin, left, 8px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-left: 8px;
   *
   *   @include mdc-rtl {
   *     margin-right: 8px;
   *     margin-left: 0;
   *   }
   * }
   * ```
   * whereas:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-box(margin, right, 8px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-right: 8px;
   *
   *   @include mdc-rtl {
   *     margin-right: 0;
   *     margin-left: 8px;
   *   }
   * }
   * ```
   *
   * You can also pass a 4th optional $root-selector argument which will be forwarded to `mdc-rtl`,
   * e.g. `@include mdc-rtl-reflexive-box(margin, left, 8px, ".mdc-component")`.
   *
   * Note that this function will always zero out the original value in an RTL context. If you're
   * trying to flip the values, use mdc-rtl-reflexive-property().
   */
  /**
   * Takes a base property and emits rules that assign <base-property>-left to <left-value> and
   * <base-property>-right to <right-value> in a LTR context, and vice versa in a RTL context.
   * For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-property(margin, auto, 12px);
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   * .mdc-foo {
   *   margin-left: auto;
   *   margin-right: 12px;
   *
   *   @include mdc-rtl {
   *     margin-left: 12px;
   *     margin-right: auto;
   *   }
   * }
   * ```
   *
   * A 4th optional $root-selector argument can be given, which will be passed to `mdc-rtl`.
   */
  /**
   * Takes an argument specifying a horizontal position property (either "left" or "right") as well
   * as a value, and applies that value to the specified position in a LTR context, and flips it in a
   * RTL context. For example:
   *
   * ```scss
   * .mdc-foo {
   *   @include mdc-rtl-reflexive-position(left, 0);
   *   position: absolute;
   * }
   * ```
   * is equivalent to:
   *
   * ```scss
   *  .mdc-foo {
   *    position: absolute;
   *    left: 0;
   *    right: initial;
   *
   *    @include mdc-rtl {
   *      right: 0;
   *      left: initial;
   *    }
   *  }
   * ```
   * An optional third $root-selector argument may also be given, which is passed to `mdc-rtl`.
   */
  /* TODO(sgomes): Figure out what to do about desktop font sizes. */
  /* TODO(sgomes): Figure out what to do about i18n and i18n font sizes. */
  .mdc-toolbar {
    /* @alternate */
    background-color: #6200ee;
    background-color: var(--mdc-theme-primary, #6200ee);
    color: white;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%; }
    .mdc-toolbar__row {
      display: flex;
      position: relative;
      align-items: center;
      background-color: #49555e;
      box-sizing: border-box;
      width: 100%;
      height: auto;
      min-height: 64px; }
      @media (max-width: 959px) and (orientation: landscape) {
        .mdc-toolbar__row {
          min-height: 48px; } }
      @media (max-width: 599px) {
        .mdc-toolbar__row {
          min-height: 56px; } }
    .mdc-toolbar__section {
      display: inline-flex;
      flex: 1;
      align-items: flex-start;
      justify-content: center;
      min-width: 0;
      height: 100%;
      z-index: 1; }
      .mdc-toolbar__section--align-start {
        justify-content: flex-start;
        order: -1; }
      .mdc-toolbar__section--align-end {
        justify-content: flex-end;
        order: 1; }
    .mdc-toolbar__title {
      font-family: Roboto, sans-serif;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-size: 1.25rem;
      line-height: 2rem;
      font-weight: 500;
      letter-spacing: 0.02em;
      text-decoration: inherit;
      text-transform: inherit;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin-left: 24px;
      margin-right: 0;
      align-self: center;
      padding: 16px 0;
      line-height: 1.5rem;
      z-index: 1; }
      [dir="rtl"] .mdc-toolbar__title, .mdc-toolbar__title[dir="rtl"] {
        margin-left: 0;
        margin-right: 24px; }
    .mdc-toolbar__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px;
      border: none;
      background-color: inherit;
      color: inherit;
      text-decoration: none;
      cursor: pointer;
      padding-right: 12px;
      padding-left: 12px; }
    .mdc-toolbar__icon:last-of-type {
      padding-left: 12px;
      padding-right: 24px; }
      [dir="rtl"] .mdc-toolbar__icon:last-of-type, .mdc-toolbar__icon:last-of-type[dir="rtl"] {
        padding-left: 24px;
        padding-right: 12px; }
    .mdc-toolbar__menu-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px;
      border: none;
      background-color: inherit;
      color: inherit;
      text-decoration: none;
      cursor: pointer;
      padding-right: 24px;
      padding-left: 24px; }

  .mdc-toolbar__menu-icon + .mdc-toolbar__title {
    margin-left: 8px;
    margin-right: 0; }
    [dir="rtl"] .mdc-toolbar__menu-icon + .mdc-toolbar__title, .mdc-toolbar__menu-icon + .mdc-toolbar__title[dir="rtl"] {
      margin-left: 0;
      margin-right: 8px; }

  @media (max-width: 599px) {
    .mdc-toolbar__title {
      margin-left: 16px;
      margin-right: 0; }
      [dir="rtl"] .mdc-toolbar__title, .mdc-toolbar__title[dir="rtl"] {
        margin-left: 0;
        margin-right: 16px; }
    .mdc-toolbar__icon {
      padding-right: 8px;
      padding-left: 8px; }
    .mdc-toolbar__icon:last-of-type {
      padding-left: 8px;
      padding-right: 16px; }
      [dir="rtl"] .mdc-toolbar__icon:last-of-type, .mdc-toolbar__icon:last-of-type[dir="rtl"] {
        padding-left: 16px;
        padding-right: 8px; }
    .mdc-toolbar__menu-icon {
      padding-right: 16px;
      padding-left: 16px; }
    .mdc-toolbar__menu-icon + .mdc-toolbar__title {
      margin-left: 16px;
      margin-right: 0; }
      [dir="rtl"] .mdc-toolbar__menu-icon + .mdc-toolbar__title, .mdc-toolbar__menu-icon + .mdc-toolbar__title[dir="rtl"] {
        margin-left: 0;
        margin-right: 16px; } }

  .mdc-toolbar--fixed {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4; }

  .mdc-toolbar--flexible {
    --mdc-toolbar-ratio-to-extend-flexible: 4; }
    .mdc-toolbar--flexible .mdc-toolbar__row:first-child {
      height: 256px;
      height: calc(64px * var(--mdc-toolbar-ratio-to-extend-flexible, 4)); }
      @media (max-width: 599px) {
        .mdc-toolbar--flexible .mdc-toolbar__row:first-child {
          height: 224px;
          height: calc(56px * var(--mdc-toolbar-ratio-to-extend-flexible, 4)); } }
      .mdc-toolbar--flexible .mdc-toolbar__row:first-child::after {
        position: absolute;
        content: ""; }
    .mdc-toolbar--flexible-default-behavior .mdc-toolbar__title {
      font-family: Roboto, sans-serif;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-size: 2.125rem;
      line-height: 2.5rem;
      font-weight: 400;
      letter-spacing: normal;
      text-decoration: inherit;
      text-transform: inherit;
      align-self: flex-end;
      line-height: 1.5rem; }
    .mdc-toolbar--flexible-default-behavior .mdc-toolbar__row:first-child::after {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: opacity .2s ease;
      opacity: 1; }
    .mdc-toolbar--flexible-default-behavior.mdc-toolbar--flexible-space-minimized .mdc-toolbar__row:first-child::after {
      opacity: 0; }
    .mdc-toolbar--flexible-default-behavior.mdc-toolbar--flexible-space-minimized .mdc-toolbar__title {
      font-weight: 500; }

  .mdc-toolbar--waterfall.mdc-toolbar--fixed {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
    transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
    will-change: box-shadow; }
    .mdc-toolbar--waterfall.mdc-toolbar--fixed.mdc-toolbar--flexible-space-minimized {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .mdc-toolbar--waterfall.mdc-toolbar--fixed.mdc-toolbar--fixed-lastrow-only.mdc-toolbar--flexible-space-minimized {
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12); }
    .mdc-toolbar--waterfall.mdc-toolbar--fixed.mdc-toolbar--fixed-lastrow-only.mdc-toolbar--fixed-at-last-row {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }

  .mdc-toolbar-fixed-adjust {
    margin-top: 64px; }
    @media (max-width: 959px) and (max-height: 599px) {
      .mdc-toolbar-fixed-adjust {
        margin-top: 48px; } }
    @media (max-width: 599px) {
      .mdc-toolbar-fixed-adjust {
        margin-top: 56px; } }

  .mdc-toolbar__section--shrink-to-fit {
    flex: none; }

  /* TODO(sgomes): Figure out what to do about desktop font sizes. */
  /* TODO(sgomes): Figure out what to do about i18n and i18n font sizes. */
  /* TODO(sgomes): Figure out what to do about desktop font sizes. */
  /* TODO(sgomes): Figure out what to do about i18n and i18n font sizes. */
  .mdc-typography {
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased; }

  .mdc-typography--display4 {
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 7rem;
    line-height: 7rem;
    font-weight: 300;
    letter-spacing: -0.04em;
    text-decoration: inherit;
    text-transform: inherit; }

  .mdc-typography--adjust-margin.mdc-typography--display4 {
    margin: -1rem 0 3.5rem -0.085em; }

  .mdc-typography--display3 {
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 3.5rem;
    line-height: 3.5rem;
    font-weight: 400;
    letter-spacing: -0.02em;
    text-decoration: inherit;
    text-transform: inherit; }

  .mdc-typography--adjust-margin.mdc-typography--display3 {
    margin: -8px 0 64px -0.07em; }

  .mdc-typography--display2 {
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 2.8125rem;
    line-height: 3rem;
    font-weight: 400;
    letter-spacing: normal;
    text-decoration: inherit;
    text-transform: inherit; }

  .mdc-typography--adjust-margin.mdc-typography--display2 {
    margin: -0.5rem 0 4rem -0.07em; }

  .mdc-typography--display1 {
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 2.125rem;
    line-height: 2.5rem;
    font-weight: 400;
    letter-spacing: normal;
    text-decoration: inherit;
    text-transform: inherit; }

  .mdc-typography--adjust-margin.mdc-typography--display1 {
    margin: -0.5rem 0 4rem -0.07em; }

  .mdc-typography--headline {
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 400;
    letter-spacing: normal;
    text-decoration: inherit;
    text-transform: inherit; }

  .mdc-typography--adjust-margin.mdc-typography--headline {
    margin: -0.5rem 0 1rem -0.06em; }

  .mdc-typography--title {
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 1.25rem;
    line-height: 2rem;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-decoration: inherit;
    text-transform: inherit; }

  .mdc-typography--adjust-margin.mdc-typography--title {
    margin: -0.5rem 0 1rem -0.05em; }

  .mdc-typography--subheading2 {
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 1rem;
    line-height: 1.75rem;
    font-weight: 400;
    letter-spacing: 0.04em;
    text-decoration: inherit;
    text-transform: inherit; }

  .mdc-typography--adjust-margin.mdc-typography--subheading2 {
    margin: -0.5rem 0 1rem -0.06em; }

  .mdc-typography--subheading1 {
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 0.938rem;
    line-height: 1.5rem;
    font-weight: 400;
    letter-spacing: 0.04em;
    text-decoration: inherit;
    text-transform: inherit; }

  .mdc-typography--adjust-margin.mdc-typography--subheading1 {
    margin: -0.313rem 0 0.813rem -0.06em; }

  .mdc-typography--body2 {
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 500;
    letter-spacing: 0.04em;
    text-decoration: inherit;
    text-transform: inherit; }

  .mdc-typography--adjust-margin.mdc-typography--body2 {
    margin: -0.25rem 0 0.75rem 0; }

  .mdc-typography--body1 {
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 400;
    letter-spacing: 0.04em;
    text-decoration: inherit;
    text-transform: inherit; }

  .mdc-typography--adjust-margin.mdc-typography--body1 {
    margin: -0.25rem 0 0.75rem 0; }

  .mdc-typography--caption {
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 0.75rem;
    line-height: 1.25rem;
    font-weight: 400;
    letter-spacing: 0.08em;
    text-decoration: inherit;
    text-transform: inherit; }

  .mdc-typography--adjust-margin.mdc-typography--caption {
    margin: -0.5rem 0 1rem -0.04em; }

  .mdc-typography--button {
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 0.875rem;
    line-height: 2.25rem;
    font-weight: 500;
    letter-spacing: 0.04em;
    text-decoration: none;
    text-transform: uppercase; }

  .mdc-typography--adjust-margin.mdc-typography--button {
    margin: inherit; }

  :root {
    --mdc-theme-primary: #23a4e6; }



  .wrapper {
    margin: 24px;
    display: flex;
    flex-flow: row wrap;
    align-content: left;
    justify-content: left; }

  .margin {
    margin: 24px; }

  .fill-space {
    flex: 1 1 auto; }

  .mdc-button__icon {
    margin-top: 8px; }

  .mdc-toolbar__row {
    padding: 5px 36px; }

  .hidden {
    display: none !important; }

  .top-header {
    padding: 12px 24px;
    display: flex;
    flex-flow: row wrap;
    align-content: left;
    justify-content: left;
    background-color: #49555e;
  }

  span.driverlogo {
    font-size: 24px;
    }


  .demo-card {
    min-width: 32%;
    margin-right: 24px;
    flex-direction: inherit;
    justify-content: flex-start;
    background-color: #fff; }
    .demo-card .mdc-card__horizontal-block {
      justify-content: space-evenly; }
    .demo-card div.chart {
      position: relative;
      margin: 0px 20px 8px 30px;
      width: 140px;
      height: 140px;
      display: block;
      float: left; }
    .demo-card canvas {
      display: block;
      position: absolute;
      top: 0;
      left: 0; }
    .demo-card div.chart span {
      color: --mdc-theme-primary;
      display: block;
      line-height: 28px;
      margin-top: 47px;
      margin-left: 6px;
      text-align: center;
      width: 128px;
      font-family: sans-serif;
      font-size: 24px;
      font-weight: 100; }
    .demo-card div.chart span.ratehead {
      display: block;
      line-height: 15px;
      text-align: center;
      width: 120px;
      font-family: sans-serif;
      font-size: 18px;
      font-weight: 100;
      color: #555;
      margin-top: 25px; }
    .demo-card div.chart span.rate {
      display: block;
      line-height: 45px;
      text-align: center;
      width: 120px;
      font-family: sans-serif;
      font-size: 28px;
      font-weight: 100;
      margin-top: 18px;
      color: #000;
      margin-top: 5px; }
    .demo-card div.chart span.sub {
      display: block;
      line-height: 15px;
      text-align: center;
      width: 128px;
      font-family: sans-serif;
      font-size: 18px;
      font-weight: 100;
      margin-left: 6px;
      color: #555;
      margin-top: 5px; }
    .demo-card p {
      width: 170px;
      float: right; }
    .demo-card .mdc-list-item {
      height: auto;
      margin-top: 6px; }
      .demo-card .mdc-list-item .messagepreview {
        width: 225px; }

  .demo-card:last-of-type {
    margin-right: 0px; }

  .warnings ul {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0;
    background-color: #fff; }

  .warnings ul li {
    padding: 0; }

  .mdc-list-item .mdc-list-item__graphic.violation {
    height: 72px;
    width: 72px;
    background-color: #ee4c38;
    border-radius: 0;
    color: #fff; }

  .mdc-list-item .mdc-list-item__graphic.warning {
    height: 72px;
    width: 72px;
    background-color: #f3af3a;
    border-radius: 0;
    color: #fff; }

  .mdc-list-item .mdc-list-item__graphic.alert {
    height: 72px;
    width: 72px;
    background-color: #2d9ee6;
    border-radius: 0;
    color: #fff; }

  .mdc-list-item__meta {
    margin-right: 16px;
    font-size: 40px !important;
    font-weight: lighter;
    color: #aaa !important;
    text-decoration: none; }

  #logs a.material-icons {
    color: #23a4e6; }

  #logs .mdc-button {
    background-color: #2683cb; }

  #logs div.chart-small span {
    color: --mdc-theme-primary;
    display: block;
    line-height: 50px;
    text-align: center;
    width: 50px;
    background-color: #eeeeee;
    font-family: sans-serif;
    font-size: 18px;
    font-weight: 100;
    border-radius: 50px; }

  #logs div.chart-small {
    position: relative;
    margin: 8px 20px 8px 8px;
    width: 50px;
    height: 50px;
    display: block; }

  #logs canvas {
    display: block;
    position: absolute;
    top: 0;
    left: 0; }

  #logs .mdc-list-item__graphic {
    margin-left: 20px; }

  #logs .mdc-list-item {
    background-color: #fff;
    margin: 16px 0;
    height: 100px; }

  #logs .mdc-list-item p {
    margin: 20px; }

  .znr-logo-zonar-white {
    background-image: url(./static/img/zonar-logo-footer.png);
    background-size: cover;
    width: 143px;
    height: 40px;
    display: block; }

  .znr-toolbar-footer {
    background-color: #49555E;
    height: 100px;
    align-self: flex-end; }

  .layout, .layout-column, .layout-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex; }

  .layout-align-center-center, .layout-align-end-center, .layout-align-space-around-center, .layout-align-space-between-center, .layout-align-start-center {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-grid-row-align: center;
    align-items: center;
    -webkit-align-content: center;
    align-content: center;
    max-width: 100%; }

  .layout-align-center, .layout-align-center-center, .layout-align-center-end, .layout-align-center-start, .layout-align-center-stretch {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center; }

  .flex-gt-sm-50, .layout-row > .flex-gt-sm-50 {
    max-width: 50%;
    max-height: 100%; }

  .layout-column, .layout-row {
    -webkit-box-direction: normal; }

  .layout-column {
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    flex-direction: column; }

  .layout, .layout-column, .layout-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex; }

  .layout-align-center-stretch, .layout-align-end-stretch, .layout-align-space-around-stretch, .layout-align-space-between-stretch, .layout-align-start-stretch {
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-grid-row-align: stretch;
    align-items: stretch;
    -webkit-align-content: stretch;
    align-content: stretch; }

  .layout-align-center, .layout-align-center-center, .layout-align-center-end, .layout-align-center-start, .layout-align-center-stretch {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center; }

  .layout-align-gt-sm-center, .layout-align-gt-sm-center-center, .layout-align-gt-sm-center-end, .layout-align-gt-sm-center-start, .layout-align-gt-sm-center-stretch {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center; }

  @media (min-width: 960px) {
    .layout-align-gt-sm-center-end, .layout-align-gt-sm-end-end, .layout-align-gt-sm-space-around-end, .layout-align-gt-sm-space-between-end, .layout-align-gt-sm-start-end {
      -webkit-box-align: end;
      -webkit-align-items: flex-end;
      -ms-grid-row-align: flex-end;
      align-items: flex-end;
      -webkit-align-content: flex-end;
      align-content: flex-end; } }

  @media (min-width: 960px) {
    .layout-gt-sm-row {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      flex-direction: row; } }

  @media (min-width: 960px) {
    .layout-gt-sm,
    .layout-gt-sm-column,
    .layout-gt-sm-row {
      box-sizing: border-box;
      display: -webkit-box;
      display: -webkit-flex;
      display: flex; } }

  @media (min-width: 960px) {
    .layout-align-gt-sm-end, .layout-align-gt-sm-end-center, .layout-align-gt-sm-end-end, .layout-align-gt-sm-end-start, .layout-align-gt-sm-end-stretch {
      -webkit-box-pack: end;
      -webkit-justify-content: flex-end;
      justify-content: flex-end; } }

  @media (min-width: 960px) {
    .layout-align-gt-sm-center-end, .layout-align-gt-sm-end-end, .layout-align-gt-sm-space-around-end, .layout-align-gt-sm-space-between-end, .layout-align-gt-sm-start-end {
      -webkit-box-align: end;
      -webkit-align-items: flex-end;
      -ms-grid-row-align: flex-end;
      /* align-items: flex-end; */
      /* -webkit-align-content: flex-end; */
      /* align-content: flex-end; */ } }

  @media (min-width: 960px) {
    .layout-align-gt-sm-center, .layout-align-gt-sm-center-center, .layout-align-gt-sm-center-end, .layout-align-gt-sm-center-start, .layout-align-gt-sm-center-stretch {
      /* -webkit-box-pack: center; */
      -webkit-justify-content: center;
      justify-content: center; } }

  .layout-column, .layout-row {
    -webkit-box-direction: normal; }

  .layout-column {
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    flex-direction: column; }

  .layout, .layout-column, .layout-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex; }

  .znr-toolbar-footer .left-footer-margin {
    margin-left: 30px; }

  @media (min-width: 960px) {
    .layout-gt-sm-row > .flex-gt-sm-25 {
      -webkit-box-flex: 1;
      -webkit-flex: 1 1 100%;
      flex: 1 1 100%;
      max-width: 25%;
      max-height: 100%;
      box-sizing: border-box; } }

  @media (min-width: 960px) {
    .flex-gt-sm-25 {
      -webkit-box-flex: 1;
      -webkit-flex: 1 1 100%;
      flex: 1 1 100%;
      max-width: 25%;
      max-height: 100%;
      box-sizing: border-box; } }

  @media (min-width: 960px) {
    .layout-align-gt-sm-start-stretch,
    .layout-align-gt-sm-center-stretch,
    .layout-align-gt-sm-end-stretch,
    .layout-align-gt-sm-space-between-stretch,
    .layout-align-gt-sm-space-around-stretch {
      -webkit-box-align: stretch;
      -webkit-align-items: stretch;
      align-items: stretch;
      -webkit-align-content: stretch;
      align-content: stretch; } }

  @media (min-width: 960px) {
    .layout-align-gt-sm-start,
    .layout-align-gt-sm-start-start,
    .layout-align-gt-sm-start-center,
    .layout-align-gt-sm-start-end,
    .layout-align-gt-sm-start-stretch {
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
      justify-content: flex-start; } }

  @media (min-width: 960px) {
    .layout-align-gt-sm,
    .layout-align-gt-sm-start-stretch {
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
      justify-content: flex-start;
      -webkit-align-content: stretch;
      align-content: stretch;
      -webkit-box-align: stretch;
      -webkit-align-items: stretch;
      align-items: stretch; }
    .layout-row {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      flex-direction: row; }
    .layout,
    .layout-column,
    .layout-row {
      box-sizing: border-box;
      display: -webkit-box;
      display: -webkit-flex;
      display: flex; } }

  @media (min-width: 960px) {
    .layout-gt-sm-row > .flex-gt-sm-50 {
      -webkit-box-flex: 1;
      -webkit-flex: 1 1 100%;
      flex: 1 1 100%;
      max-width: 50%;
      max-height: 100%;
      box-sizing: border-box; } }

  @media (max-width: 600px) {
    .login-header {
      margin-bottom: 20px; } }

  @media (min-width: 960px) {
    .flex-gt-sm-50 {
      -webkit-box-flex: 1;
      -webkit-flex: 1 1 100%;
      flex: 1 1 100%;
      max-width: 50%;
      max-height: 100%;
      box-sizing: border-box; }
    }

  @media (min-width:801px) and (max-width:959px) {

    .flex-gt-sm-25 {
      -webkit-box-flex: 1;
      -webkit-flex: 1 1 100%;
      flex: 1 1 100%;
      max-width: 25%;
      max-height: 100%;
      box-sizing: border-box; }
      .znr-toolbar-footer {
        display: -webkit-box;
      }

  }

  .znr-toolbar-footer .footer-links-section {
    margin-bottom: 5px;
    line-height: 1.5em; }

  .znr-toolbar-footer .footer-links-section .footer-link {
    margin-left: 15px;
    margin-right: 15px; }

  .znr-font-link-light,
  .znr-toolbar-footer .footer-link {
    font-weight: 400;
    font-size: 14px;
    color: #F3F4F5;
    text-decoration: none; }

  .znr-font-primary-slate,
  .ag-material.znr-material .ag-paging-container zonar-grid-page-refresh .updated-text {
    font-weight: 400;
    font-size: 14px;
    color: #A2AAAF; }

  .znr-toolbar-footer .right-footer-margin {
    margin-right: 30px; }

  @media (max-width: 959px) {
    .znr-toolbar-footer {
      height: 245px; } }
