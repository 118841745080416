@use '@angular/material' as mat;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$DD-app-primary: mat.define-palette(mat.$indigo-palette);
$DD-app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$DD-app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$DD-app-theme: mat.define-light-theme((
  color: (
    primary: $DD-app-primary,
    accent: $DD-app-accent,
    warn: $DD-app-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($DD-app-theme);

// @import "~@zonar-ui/progress-spinner/zonar-ui-progress-spinner.theme";
@import '../node_modules/@zonar-ui/material/zonar-ui-material.theme';
@import '../node_modules/@zonar-ui/core/zonar-ui-core.theme';
@import '../node_modules/@zonar-ui/hos-components/zonar-ui-hos-components.theme';

@mixin zonar-ui-progress-spinner-theme($theme) {
  $primary:       map-get($theme, primary);
  $accent:        map-get($theme, accent);
  $warn:          map-get($theme, warn);
  $background:    map-get($theme, background);
  $foreground:    map-get($theme, foreground);

  .progress-spinner-container {
    .radar-fill {
        fill: #0075B4;

        &.accent {
            fill: #0075B4;
        }

        &.warn {
            fill: #0075B4;
        }
    }
  }
}

@mixin apply-zonar-theme($theme) {
  @include mat.all-component-themes($theme);
  // Custom component themes added here...
  @include zonar-ui-core-theme($theme);
  @include zonar-ui-hos-components-theme($theme);
  @include zonar-ui-progress-spinner-theme($theme);
}


.zonar-default-theme {
  @include apply-zonar-theme($zonar-default-theme);
  background-color: white;
}

.zonar-dark-theme {
  @include apply-zonar-theme($zonar-dark-theme);
  background-color: #292929;
}

// Some styles are adding extra padding and margin to the mat button wrapper
.mat-button-base .mat-button-wrapper {
  padding: unset;
  margin: unset;
}